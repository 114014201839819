import { CardUserInterface } from "../../../types/models";
import { httpGet } from "../../utilities";
import { AnalyticsHttpFilters } from "./types";

export interface MechanicsEfficencyHttpResponse {
  [mechanicId: string]: {
    amount_of_resolved_issues: number;
    data: CardUserInterface;
    seconds_spent: number;
  };
}

export const mechanicsEfficencyHttp = async (
  filters: AnalyticsHttpFilters = {}
) =>
  httpGet<MechanicsEfficencyHttpResponse>("/admin/analytics/mechanics", {...filters,});
