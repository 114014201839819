import { CardUserInterface } from "../../types/models";
import { httpPost } from "../utilities";


export const cardReaderHttp = async (accessCode: string, base64: string) =>
  httpPost<CardUserInterface>(
    "/interface/reader/",
    { base64: base64 },
    undefined,
    { "Access-Key": accessCode }
  );
