import { httpPost } from "../utilities";

export const startDeviceHttp = async (
  interfaceAccessCode: string,
  cardCode: string,
  deviceId: number,
  issueId: number
) =>
  httpPost(
    `/interface/devices/${deviceId}/`,
    { issue_id: issueId },
    {},
    { "Access-Key": interfaceAccessCode, "Card-Code": cardCode }
  );
