import { httpGet } from "../../utilities";

export interface DatekeysInRangeHttpFilters {
  date__gte: string;
  date__lte: string;
}

export const datekeysInRangeHttp = async (
  filters: DatekeysInRangeHttpFilters
) => httpGet<string[]>("/admin/utility/datekeys", {...filters});
