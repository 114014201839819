import { FieldValues, useForm } from "react-hook-form";
import { ReactNode, useCallback } from "react";

import { FormLayout } from "./types/formLayout";
import { renderLayout } from "./renderLayout";
import { Button, ButtonTypes, FlexRow, FlexRowProps } from "../../styled";
import { FormStyle, FormStyleProps } from "./style";

export interface FormProps<DataSchema> {
  initialData?: DataSchema;
  onSubmit: (data: DataSchema) => void | Promise<void>;
  layout: FormLayout[];
  buttons?: {
    hasSubmit?: boolean;
    submitDisabled?: boolean;
    hasReset?: boolean;
    resetDisabled?: boolean;
    submitLabel?: ReactNode;
    resetLabel?: ReactNode;
    wrapperStyling?: Omit<FlexRowProps, "children">;
  };
  style?: FormStyleProps;
}

export default function Form<DataSchema extends FieldValues>({
  layout,
  initialData,
  onSubmit,
  buttons,
  style,
}: FormProps<DataSchema>) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataSchema>({ values: initialData });

  const formChildren = useCallback(
    () => renderLayout<DataSchema>(layout, register, errors ?? {}, initialData),
    [layout, register, errors, initialData]
  );

  return (
    <FormStyle {...style} onSubmit={handleSubmit(onSubmit)}>
      {formChildren()}
      <FlexRow
        gap="10px"
        justifyContent="flex-end"
        alignItems="center"
        dimensions={{ width: "100%" }}
        {...buttons?.wrapperStyling}
      >
        {buttons?.hasReset && (
          <Button
            disabled={buttons?.resetDisabled}
            styleType={ButtonTypes?.primaryReverse}
            type="reset"
          >
            {buttons?.resetLabel ?? <>Cancel</>}
          </Button>
        )}
        {buttons?.hasSubmit && (
          <Button disabled={buttons?.submitDisabled} type="submit">
            {buttons?.submitLabel ?? <>Submit</>}
          </Button>
        )}
      </FlexRow>
    </FormStyle>
  );
}
