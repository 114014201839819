const white = {
  wildSand: "#F4F4F4",
  alto: "#e0e0e0",
  clean: "#fff",
  gallery: "#f0f0f0"
};

const purple = {
  studio: "#744EAA",
};

const black = {
  codGray: "#070707",
  shipGray: "#353238",
  abbey: "#414245"
}

const grey = {
  tundora: "#494949",
  silver: "#C0C0C0",
  silverChalice: "#ADADAD",
  geyser: "#CBD5E0",
  geyserLight: "#d5dde4",
  noble: "#B6B6B6",
  gallery: "#EBEBEB",
  nevada: "#6D6E72",
  manatee: "#92949C"
}

const blue = {
  java: "#16c2d5",
  dodger: "#397FFE",
  cornflower: "#63B3ED",
  frenchPass: "#bde2fd",
  onahau: "#C7E7FF",
  light: "#F5F8FF"
}

const orange = {
  flamingo: "#F26522",
  casablanca: "#F6AD55",
  frangipani: "#FFDCB2",
  sunshade: "#FE9E2A"
}

const red = {
  alizarinCrimson: "#E32636",
  cinnabar: "#E83535"
};

const green = {
  amazon: "#3B7A57",
  emerald: "#68D391",
  aeroBlue: "#d4ffe5",
  shamrock: "#2CD06E",
  malachite: "#35E83C"
};

const pink = {
  lavander: "#FBB6CE",
  paleRose: "#ffe1ec"
}

const yellow = {
  sweetCorn: "#FAF089",
  givry: "#f7f2c1"
}

export const colors = {
  white,
  purple,
  black,
  grey,
  blue,
  orange,
  red,
  green,
  pink,
  yellow
};
