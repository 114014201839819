import { httpPost } from "../utilities";

export const groupLeadStopDeviceHttp = async (
  interfaceAccessCode: string,
  cardCode: string,
  deviceId: number,
  cardUserId: number,
  shouldStop?: boolean
) =>
  httpPost(
    "/interface/devices/lead/manage/",
    { card_user_id: cardUserId, device_id: deviceId, should_stop: shouldStop },
    {},
    { "Access-Key": interfaceAccessCode, "Card-Code": cardCode }
  );
