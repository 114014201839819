import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Header } from "../../../../shared/components/Header";
import {
  Button,
  FlexColumn,
  FlexRow,
  TitleXL,
  TitleXS,
  TitleXXL,
} from "../../../../shared/styled";
import { listLocationsHttp } from "../../../../apis/admin/locations";
import { LocationInterface } from "../../../../types/models";
import { TablePagination } from "../../../../shared/components/Table";
import Table from "../../../../shared/components/Table/Table";
import { theme } from "../../../../constants";
import Select from "../../../../shared/components/Select/Select";
import { Filters } from "../../../../shared/genericScreens/Filters";

export const LocationList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<LocationInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await listLocationsHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 50,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        console.log("LocationList.fetchData", err);
      }
      setLoading(false);
    },
    [isLoading, pagination, filters, ordering]
  );

  const updatePagination = useCallback(
    (updatedPagination: typeof pagination) => {
      setPagination(updatedPagination);
      fetchData(updatedPagination);
    },
    [fetchData]
  );

  const updateOrdering = useCallback(
    (updatedOrdering: typeof ordering) => {
      setOrdering(updatedOrdering);
      fetchData(pagination, filters, updatedOrdering);
    },
    [fetchData, pagination, filters]
  );

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(pagination, updatedFilters);
    },
    [pagination, fetchData]
  );

  const handleOptionSelect = (itemId?: number | string) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value;
      if (value === "update") {
        navigate(`/admin/location/${itemId}`);
        return;
      }
    };
  };

  /*Inital Fetch*/
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>LOKACIJE</TitleXXL>
      <Filters
        isLoading={isLoading}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            label: <TitleXS>Pretraga</TitleXS>,
            placeholder: "Pretrazi...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<LocationInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>
              Lista lokacija ({pagination?.count})
            </TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button onClick={() => navigate("/admin/location/create")}>
                Kreiraj lokaciju
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "city",
            order: "city",
            label: "GRAD",
            width: "200px",
          },
          {
            attribute: "name",
            order: "name",
            label: "NAZIV",
            width: "200px",
          },
          {
            attribute: "created_at",
            order: "created_at",
            label: "KREIRANO NA",
            width: "200px",
            mapper: (item) =>
              item?.created_at
                ? new Date(item?.created_at)?.toLocaleString()
                : "NEDOSTUPAN",
          },
          {
            label: "AKCIJE",
            width: "200px",
            mapper: (item) => (
              <Select
                onChange={handleOptionSelect(item?.id)}
                value="none"
                options={[
                  {
                    label: "Odaberite akciju",
                    value: "none",
                    disabled: true,
                  },
                  { label: "Uredi lokaciju", value: "update" },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
