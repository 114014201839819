import { InputHTMLAttributes, forwardRef } from "react";
import { InputStyle, InputStyleProps } from "./style/InputStyle";
import { DimensionStylingProps } from "../../styling";

export interface InputPropStyle extends InputStyleProps {
  style?: InputHTMLAttributes<HTMLInputElement>["style"];
  dimensions?: DimensionStylingProps;
}

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "style"> {
  style?: InputPropStyle;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ style, ...props }, ref) => {
    return <InputStyle ref={ref} {...props} {...style} />;
  }
);

Input.displayName = "Input"; // Setting a display name is a good practice for debugging purposes

export default Input;
