import { useNavigate, useParams } from "react-router";
import {
  Button,
  FlexColumn,
  FlexItem,
  FlexRow,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../../shared/styled";
import { Header } from "../../../../../shared/components/Header";
import { useCallback, useEffect, useState } from "react";
import {
  CreateCardUserHttpData,
  createCardUserHttp,
  retriveCardUserHttp,
  updateCardUserHttp,
} from "../../../../../apis/admin/cardUsers";
import { adminCardCodeGeneratorHttp } from "../../../../../apis/admin/utilities";
import Form from "../../../../../shared/components/Form/Form";
import { Loading } from "../../../../../shared/components/Loading";
import { CardUserTypesEnum, GroupInterface, LocationInterface } from "../../../../../types/models";
import { listSelectGroupsHttp } from "../../../../../apis/admin/groups";
import { ScannerModal } from "../../../../../modals/ScannerModal";
import { useModalProvider } from "../../../../../contexts/ModalProviderContext";
import { listSelectLocationsHttp } from "../../../../../apis/admin/locations";

interface SupportDataType {
  groups?: GroupInterface[];
  locations?: LocationInterface[];
}

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const CardUserManage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const screenType = id ? "update" : "create";

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateCardUserHttpData>({
    group_id: 0,
    type: 0,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: groupData } = await listSelectGroupsHttp();
      updatedSupportData.groups = groupData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveCardUserHttp(id);
      setInitialData({
        first_name: responseData?.first_name,
        last_name: responseData?.last_name,
        type: responseData?.type,
        group_id: responseData?.group_id,
        card_code: responseData?.card_code,
        is_mobile_access_active: Boolean(responseData?.is_mobile_access_active)
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateCardUserHttpData) => {
    setSubmitting(true);

    if (!data?.type) {
      // TODO Change this to toast notification
      alert("Karticni korisnik mora imati sekeltovan tip!");
      return;
    }

    if(!data?.location_id) {
      alert(`Lokacija mora biti selektovana!`);
      return;
    }

    if ([CardUserTypesEnum.Employee, CardUserTypesEnum.GroupLead].includes(+data?.type)) {
      if (!data?.group_id) {
        // TODO Change this to toast notification
        alert(`Selektovani tip korisnika mora imati selektovanu grupu!`);
        return;
      }

      data.location_id = supportData?.groups?.find(group => group.id === data?.group_id)?.location?.id ?? data?.location_id;
    }

    try {
      if (screenType === "update")
        await updateCardUserHttp(id as string, { ...data, group_id: data?.group_id, location_id: data?.location_id });
      else await createCardUserHttp({ ...data, group_id: data?.group_id, location_id: data?.location_id });
      window.history.back();
    } catch (err) {
      //TODO Handle error
      console.log(err);
    }
    setSubmitting(false);
  };

  const handleGenerateCardCode = async () => {
    try {
      const { data: cardCode } = await adminCardCodeGeneratorHttp();
      return cardCode;
    } catch (err) {
      //TODO Handle error
      console.log(err);
    }
  }

  // Initialization
  useEffect(() => {
    fetchSupportData();
    fetchDefaultData();
  }, [fetchSupportData, fetchDefaultData]);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>KARTICNI KORISNICI</TitleXXL>
        <TitleXL>{Translations[screenType].toUpperCase()} KARTICNI KORISNIK</TitleXL>
      </FlexColumn>
      <FlexColumn alignItems="center">
        {!isLoading && !isLoadingSupportData && (
          <Form<CreateCardUserHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>Ime</Title>,
                type: "column",
                field: {
                  name: "first_name",
                  type: "text",
                  placeholder: "Unesite ime korisnika...",
                  options: {
                    required: "Ime je potrebno!",
                  },
                },
              },
              {
                label: <Title>Prezime</Title>,
                type: "column",
                field: {
                  name: "last_name",
                  type: "text",
                  placeholder: "Unesite prezime korisnika...",
                  options: {
                    required: "Prezime je potrebno!",
                  },
                },
              },
              {
                label: <Title>Tip korisnika</Title>,
                type: "column",
                field: {
                  name: "type",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Odaberite tip korisnika", disabled: true },
                    {
                      value: CardUserTypesEnum.Employee,
                      label: "Radnik",
                    },
                    {
                      value: CardUserTypesEnum.Mechanic,
                      label: "Mehanicar",
                    },
                    {
                      value: CardUserTypesEnum.GroupLead,
                      label: "Grupovodja",
                    },
                    {
                      value: CardUserTypesEnum.Manager,
                      label: "Manadzer",
                    },
                  ],
                  placeholder: "Odaberite tip korisnika",
                  options: {
                    required: "Tip korisnika mora biti selektovan!",
                  },
                },
              },
              {
                label: (
                  <FlexRow
                    dimensions={{ width: "100%" }}
                    alignItems="flex-end"
                    justifyContent="space-between"
                  >
                    <Title>Grupa</Title>
                    <Button onClick={() => navigate("/admin/group/create")}>
                      Kreiraj grupu
                    </Button>
                  </FlexRow>
                ),
                type: "column",
                field: {
                  name: "group_id",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Nije selektovana" },
                    ...(supportData?.groups ?? [])?.map((group) => ({
                      value: group?.id,
                      label: `${group?.name}, ${group?.location?.name}`,
                    })),
                  ],
                  placeholder: "Odaberi grupu",
                },
              },
              {
                label: (
                  <FlexRow
                    dimensions={{ width: "100%" }}
                    alignItems="flex-end"
                    justifyContent="space-between"
                  >
                    <Title>Lokacija</Title>
                    <Button onClick={() => navigate("/admin/location/create")}>
                      Kreiraj lokaciju
                    </Button>
                  </FlexRow>
                ),
                type: "column",
                field: {
                  name: "location_id",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Nije selektovana" },
                    ...(supportData?.locations ?? [])?.map((location) => ({
                      value: location?.id,
                      label: `${location?.name}, ${location?.city}`,
                    })),
                  ],
                  placeholder: "Odaberite lokaciju",
                },
              },
              {
                label: (
                  <FlexColumn dimensions={{ width: "100%" }}>
                    <FlexRow alignItems="center" justifyContent="space-between">
                      <Title>Karticni kod</Title>
                      <FlexRow gap="10px">
                        <Button
                          onClick={(event) => {
                            openModal("scan-card-modal", ScannerModal, {
                              title: "Skeniraj karticni kod",
                              close: () => {
                                closeModal("scan-card-modal");
                              },
                              httpType: "admin",
                              onSuccess: (data: string) => {
                                const inputNode: HTMLInputElement | null =
                                  document.body.querySelector('input[name="card_code"]');

                                if (!inputNode) return;

                                inputNode.focus();
                                inputNode.value = data ?? "";
                                inputNode.blur();
                                event.preventDefault();

                                closeModal("scan-card-modal");
                              },
                            })
                          }}
                        >Skeniraj karticu
                        </Button>
                        <Button
                          onClick={async (event) => {
                            const inputNode: HTMLInputElement | null =
                              document.body.querySelector('input[name="card_code"]');

                            if (!inputNode) return;

                            await handleGenerateCardCode().then((cardCode) => {
                              inputNode.focus();
                              inputNode.value = cardCode?.card_code ?? "";
                            });
                            event.preventDefault();
                          }}
                        >
                          Generisi novi
                        </Button>
                      </FlexRow>
                    </FlexRow>
                  </FlexColumn>
                ),
                type: "column",
                field: {
                  name: "card_code",
                  type: "text",
                  placeholder: "Unesi karticni kod...",
                  options: {
                    required: "Karicni kod je potreban!",
                  },
                },
              },
              {
                label: <Title>Dozvoli mobilni pristup?</Title>,
                type: "column",
                field: {
                  name: "is_mobile_access_active",
                  type: "checkbox",
                },
              },
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potvrdi</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {(isLoading || isLoadingSupportData) && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
