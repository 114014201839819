import type CSS from "csstype";
import styled from "styled-components";

export interface IconStyleProps {
  color?: CSS.Property.Color;
  width?: CSS.Property.Width;
  height?: CSS.Property.Height;
  src?: string;
}

export const IconStyle = styled.i<IconStyleProps>`
  display: block;
  width: ${({ width }) => width || '32px'};
  height: ${({ height }) => height || '32px'};
  user-select: none;

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: ${({ color }) => color || "black"};
    -webkit-mask-position-x: 0;
    -webkit-mask-position-y: 0;
    -webkit-mask-image: ${({ src }) => `url("${src}")`};
    mask-image: ${({ src }) => `url("${src}")`};
    mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    transition: all 0.5s;
  }
`;