import { httpGet } from "../../utilities";
import { AnalyticsHttpFilters } from "./types";

export interface MachineEfficencyHttpResponse {
  [datekey: string]: {
    [issueKey: string]: number;
  };
}

export const machineEfficencyHttp = async (
  filters: AnalyticsHttpFilters = {}
) =>
  httpGet<MachineEfficencyHttpResponse>("/admin/analytics", {
    ...filters,
  });
