import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Form from "../../../../shared/components/Form/Form";
import { Header } from "../../../../shared/components/Header";
import {
  FlexColumn,
  FlexItem,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../shared/styled";
import {
  createDeviceHttp,
  updateDeviceHttp,
  retriveDeviceHttp,
  CreateDeviceHttpData,
} from "../../../../apis/admin/devices";
import { Loading } from "../../../../shared/components/Loading";
import { DeviceStatusEnum, LocationInterface } from "../../../../types/models";
import { listSelectLocationsHttp } from "../../../../apis/admin/locations";

interface SupportDataType {
  locations?: LocationInterface[];
}

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const ManageMachines = () => {
  const { id } = useParams();

  const screenType = id ? "update" : "create";

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateDeviceHttpData>({
    location_id: 1,
    group_id: 1,
    mac_address: "virtual",
    code: "",
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);
    const updatedSupportData: typeof supportData = {};
    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveDeviceHttp(id);
      setInitialData({
        status: responseData.status,
        group_id: responseData.group_id ? responseData.group_id : undefined,
        code: responseData.code,
        card_user_id: responseData?.card_user_id
          ? responseData?.card_user_id
          : undefined,
        inventure_number: responseData?.inventure_number,
        name: responseData?.name
      });
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateDeviceHttpData) => {
    setSubmitting(true);

    const locationId = data?.location_id
      ? +data?.location_id === 0
        ? null
        : +data?.location_id
      : null;

    const cardUserId = data?.card_user_id
      ? +data?.card_user_id === 0
        ? null
        : +data?.card_user_id
      : null;

    try {
      if (screenType === "create") {
        await createDeviceHttp({
          ...data,
          card_user_id: cardUserId,
          location_id: locationId,
          mac_address: "VIRTUAL",
        });
      } else {
        await updateDeviceHttp(id as string, {
          ...data,
          card_user_id: cardUserId,
          location_id: locationId,
        });
      }
      window.history.back();
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    fetchSupportData();
    fetchDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>MASINE</TitleXXL>
        <TitleXL>{Translations[screenType]?.toUpperCase()} MASINU</TitleXL>
      </FlexColumn>
      <FlexColumn alignItems="center">
        {!isLoading && !isLoadingSupportData && (
          <Form<CreateDeviceHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>Tip</Title>,
                type: "column",
                field: {
                  name: "code",
                  type: "text",
                  placeholder: "Unesite tip masine...",
                  options: {
                    required: "Tip masine je potreban!",
                  },
                },
              },
              {
                label: <Title>Naziv</Title>,
                type: "column",
                field: {
                  name: "name",
                  type: "text",
                  placeholder: "Unesite naziv masine...",
                  options: {
                    required: "Naziv masine je potreban!",
                  },
                },
              },
              {
                label: <Title>Inventorni broj</Title>,
                type: "column",
                field: {
                  name: "inventure_number",
                  type: "text",
                  placeholder: "Unesite inventurni broj...",
                  options: {
                    required: "inventurni broj je potreban!",
                  },
                },
              },
              {
                label: <Title>Proizvodjac</Title>,
                type: "column",
                field: {
                  name: "code",
                  type: "text",
                  placeholder: "Unesite proizvodjaca...",
                },
              },
              {
                label: <Title>Status</Title>,
                type: "column",
                field: {
                  name: "status",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Odaberite inicijalni status", disabled: true },
                    { value: DeviceStatusEnum.Free, label: "Slobodna" },
                    { value: DeviceStatusEnum.Active, label: "Aktivna" },
                    { value: DeviceStatusEnum.Inactive, label: "Neaktivna" },
                  ],
                  options: {
                    required: "Status mora biti selektovan!",
                  },
                },
              },
              {
                label: <Title>Lokacija</Title>,
                type: "column",
                field: {
                  name: "location_id",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Lokacija nije selektovana" },
                    ...(supportData?.locations || [])?.map((location) => ({
                      value: location.id,
                      label: `${location?.name}, ${location?.city}`,
                    })),
                  ],
                },
              }
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potvrdi</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {(isLoading || isLoadingSupportData) && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
