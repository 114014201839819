import type CSS from "csstype";
import styled from "styled-components";

import { BackgroundStyling, BackgroundStylingProps } from "../../../styling";

export interface NavbarItemStyleProps {
  background?: BackgroundStylingProps | CSS.Property.BackgroundColor;
}

export const NavbarItemStyle = styled.div<NavbarItemStyleProps>`
  display: flex;
  flex-flow: row;

  justify-content: flex-start;
  gap: 10px;
  align-items: flex-end;

  width: 100%;

  cursor: pointer;

  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;

  user-select: none;

  ${({ background }) => BackgroundStyling(background)}

  transition: all 0.5s;
  * {
    transition: all 0.5s;
  }
`;
