import { colors } from "./colors";

export const theme = {
  colors: {
    warning: colors.orange.sunshade,
    primary: colors.orange.flamingo,
    secondary: colors.black.shipGray,
    success: colors.green.malachite,
    danger: colors.red.cinnabar,
    clean: colors.white.clean,
    disabled: colors.grey.manatee
  },
  font: {
    placeholder: colors.grey.manatee,
    color: colors.black.abbey,
    size: {
      sx: 14,
      x: 16,
      xl: 20,
      xxl: 32
    },
    weight: {
      normal: 500,
      bold: 600
    },
  },
  border: {
    color: colors.grey.nevada,
    invalid: colors.red.cinnabar,
    focus: colors.orange.flamingo
  },
  shadow: {
    color:  colors.grey.noble
  }
}