import styled from "styled-components";
import { theme } from "../../../../constants";
import { DimensionStyling, DimensionStylingProps } from "../../../styling";

export interface TextAreaStyleProps {
  dimensions?: DimensionStylingProps;
}

export const TextAreaStyle = styled.textarea<TextAreaStyleProps>`
  ${({dimensions}) => DimensionStyling(dimensions)};

  resize: none;
  outline: none;
  border: solid 1px ${theme.border.color};
  background: ${theme.colors.clean};
  border-radius: 4px;
  
  font-size: ${theme.font.size.x}px;
  font-weight: ${theme.font.weight.normal};

  padding: 5px;

  &::placeholder {
    color: ${theme.font.placeholder};
  }

  &:focus {
    outline: none;
    border-color: ${theme.border.focus};
  }

  &:invalid {

  }
`;
