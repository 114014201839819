import { FlexRow } from "../../shared/styled";
import { Preview } from "../../shared/components/Preview";

export const SandboxPreview = () => {
  const data = [
    {
      name: "User1",
      password: "Password123",
      color: "#FF0000",
    },
    {
      name: "JohnDoe",
      password: "SecurePass456",
    },
    {
      name: "AliceSmith",
      password: "Secret123",
      color: "#FF0000",
    },
    {
      name: "BobJohnson",
      password: "StrongPassword789",
      color: "#30B700",
    },
    {
      name: "EveHacker",
      password: "H4ck3rPass",
    },
  ];


  return (
    <FlexRow
      padding="32px"
      border={{ radius: "24px" }}
      justifyContent='center'
      alignItems='center'
      gap="20px"
    >
      {data.map((item) => (
        <Preview name={item.name} password={item.password} color={item.color} />
      ))}
    </FlexRow>
  );
};