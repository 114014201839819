import { prepareTime } from "./prepareTime";

export const timeToUTC = (time: string) => {
  const [hours, minutes] = time.split(":").map(value => +value);

  const currentDate = new Date();
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0);

  const utcHours = currentDate.getUTCHours();
  const utcMinutes = currentDate.getUTCMinutes();
  return prepareTime(`${utcHours}:${utcMinutes}`);
}