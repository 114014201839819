
import { FlexColumn, FlexRow, Title, TitleXXL } from "../../styled";
import Icon from "../../components/Icon/Icon";
import { IconTypes } from "../../components/Icon"; 
import { colors } from "../../../constants";

interface PreviewProps {
  name?: string;
  password?: string;
  color?: string;
}

export const Preview = ({ name, password, color }: PreviewProps) => {
  return (
    <FlexRow
      background={color || colors.orange?.flamingo}
      dimensions={{ flex: 1 }}
      padding="24px 16px"
      gap="16px"
    >
      <FlexColumn justifyContent='center'>
        <Icon type={IconTypes.machine} size={"82px"} color={colors?.white?.clean} />
      </FlexColumn>
      <FlexColumn
        gap="20px"
        justifyContent='left'
        alignItems='left'
      >
        <FlexColumn>
          <Title color={colors?.white?.clean}>UPOSLENIK</Title>
          <TitleXXL color={colors?.white?.clean}>{name}</TitleXXL>
        </FlexColumn>
        <FlexColumn>
          <Title color={colors?.white?.clean}>SIFRA</Title>
          <TitleXXL color={colors?.white?.clean}>{password}</TitleXXL>
        </FlexColumn>
      </FlexColumn>
    </FlexRow >
  );
};