import { useRef, useState } from "react";
import Barcode from "react-barcode";
import { FlexColumn, Button, FlexRow, ButtonTypes } from "../shared/styled";
import { colors, theme } from "../constants";
import Select from "../shared/components/Select/v1/Select";
import * as htmlToImage from "html-to-image";
import Icon from "../shared/components/Icon/Icon";
import { IconTypes } from "../shared/components/Icon";

interface BarcodeModalProps {
  close: () => void;
  cardCode: string;
}

type BarcodeType =
  | "CODE39"
  | "CODE128"
  | "EAN13"
  | "ITF14"
  | "MSI"
  | "pharmacode"
  | "codabar"
  | "upc";

export const BarcodeModal = ({ close, cardCode }: BarcodeModalProps) => {
  const barcodeRef = useRef<HTMLDivElement>(null);

  const [format, setFormat] = useState<BarcodeType>("CODE128");

  const options = [
    { value: "CODE39", label: "CODE39" },
    { value: "CODE128", label: "CODE128" },
    { value: "EAN13", label: "EAN13" },
    { value: "ITF14", label: "ITF14" },
    { value: "MSI", label: "MSI" },
    { value: "pharmacode", label: "pharmacode" },
    { value: "codabar", label: "codabar" },
    { value: "upc", label: "upc" },
  ];

  const downloadBarcode = async () => {
    if (barcodeRef.current) {
      const dataUrl = await htmlToImage.toPng(barcodeRef.current);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "barcode.png";
      link.click();
    }
  };

  return (
    <FlexColumn
      background={colors?.white?.clean}
      dimensions={{ width: "fit-content", minWidth: "300px", height: "300px" }}
      padding="32px"
      border={{ radius: "24px" }}
      justifyContent="center"
      alignItems="center"
      gap="20px"
    >
      <FlexRow>
        <Button
          border={{ radius: "50%" }}
          padding="10px"
          styleType={ButtonTypes.primary}
          onClick={() => close && close()}
        >
          <Icon type={IconTypes.close} size="32px" color={theme.colors.clean} />
        </Button>
        <Select
          options={options}
          onChange={(option) => setFormat(option.value as BarcodeType)}
          defaultValue={format}
          placeholder="Select barcode type"
        />
      </FlexRow>
      <div ref={barcodeRef}>
        <Barcode value={cardCode} format={format} width={4} />
      </div>
      <Button onClick={downloadBarcode}>Download Barcode</Button>
    </FlexColumn>
  );
};
