import { Routes, Route } from "react-router-dom";

import { AppRoute } from "../types/route";
import { AuthRoute } from "../contexts/Auth";

function _routesRenderer(routes: AppRoute[]) {
  return (
    <>
      {routes.map((route) => {
        return (
          <Route
            key={route?.id}
            path={route?.path}
            element={
              route?.authRequired ? (
                <AuthRoute>{route?.component}</AuthRoute>
              ) : (
                route?.component
              )
            }
          >
            {route?.children && _routesRenderer(route?.children)}
          </Route>
        );
      })}
    </>
  );
}

export default function routesRenderer(routes: AppRoute[]) {
  return <Routes>{_routesRenderer(routes)}</Routes>;
}
