export enum IconTypes {
  access = "access",
  back = "back",
  calendar = "calendar",
  cardUsers = "card-users",
  carrot = "carrot",
  close = "close",
  color = "color",
  edit = "edit",
  eye = "eye",
  graph = "graph",
  interface = "interface",
  issue = "issue",
  location = "location",
  logout = "logout",
  machine = "machine",
  network = "network",
  station = "station",
  time = "time",
  trash = "trash",
  users = "users",
  arrowLeft = "arrow-left",
  arrowRight = "arrow-right",
  barcodeScan = "barcode",
}
