import Table from "../../shared/components/Table/Table";
import { CHARSET_LLETTERS, randomRange, randomString } from "../../utilities/random";

interface DummyDataInterface {
  number?: number;
  string?: string;
  boolean?: boolean;
  date?: Date;
}
const dummyData = new Array(20).fill(null).map(_=>({
  number: Math.round(randomRange(0,1000)),
  string: randomString(CHARSET_LLETTERS,16),
  boolean: randomRange(0,100) < 50,
  date: new Date() 
}));

export default function TableDev() {
  return (
    <>
      <Table<DummyDataInterface>
        title="Table"
        data={dummyData}
        schema={[
          {
            attribute: "number",
            order: "number",
            label: "Number",
            width: "100px",
          },
          {
            attribute: "string",
            order: "string",
            label: "String",
            width: "300px",
          },
          {
            attribute: "boolean",
            order: "boolean",
            label: "Boolean",
            width: "100px",
          },
          {
            attribute: "date",
            order: "date",
            label: "Date",
            width: "200px",
          },
        ]}
      />
    </>
  );
}
