import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Header } from "../../../../shared/components/Header";
import {
  Button,
  FlexColumn,
  FlexRow,
  TitleXL,
  TitleXS,
  TitleXXL,
} from "../../../../shared/styled";
import { listGroupsHttp } from "../../../../apis/admin/groups";
import { GroupInterface, LocationInterface } from "../../../../types/models";
import { TablePagination } from "../../../../shared/components/Table";
import Table from "../../../../shared/components/Table/Table";
import { theme } from "../../../../constants";
import Select from "../../../../shared/components/Select/Select";
import { Filters } from "../../../../shared/genericScreens/Filters";
import { listSelectLocationsHttp } from "../../../../apis/admin/locations";

interface SupportDataType {
  locations?: LocationInterface[];
}

export const GroupList = () => {
  const navigate = useNavigate();

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<GroupInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await listGroupsHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 50,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        console.log("GroupList.fetchData", err);
      }
      setLoading(false);
    },
    [isLoading, pagination, filters, ordering]
  );

  const updatePagination = useCallback(
    (updatedPagination: typeof pagination) => {
      setPagination(updatedPagination);
      fetchData(updatedPagination);
    },
    [fetchData]
  );

  const updateOrdering = useCallback(
    (updatedOrdering: typeof ordering) => {
      setOrdering(updatedOrdering);
      fetchData(pagination, filters, updatedOrdering);
    },
    [fetchData, pagination, filters]
  );

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(pagination, updatedFilters);
    },
    [pagination, fetchData]
  );

  const handleOptionSelect = (itemId?: number | string) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value;
      if (value === "update") {
        navigate(`/admin/groupe/${itemId}`);
        return;
      }
    };
  };

  /*Inital Fetch*/
  useEffect(() => {
    fetchSupportData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>GRUPE</TitleXXL>
      <Filters
        isLoading={isLoading || isLoadingSupportData}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            label: <TitleXS>Lokacija</TitleXS>,
            placeholder: "Lokacije",
            inputType: "select",
            type: "number",
            attribute: "location_id",
            name: "location_id",
            options: [
              { value: -1, label: "Nije selektovano" },
              ...(supportData?.locations ?? [])?.map((location) => ({
                value: location?.id,
                label: `${location?.name}, ${location?.city}`,
              })),
            ],
          },
          {
            label: <TitleXS>Pretraga</TitleXS>,
            placeholder: "Pretrazi...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<GroupInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>
              Grupe lista ({pagination?.count})
            </TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button onClick={() => navigate("/admin/group/create")}>
                Kreiraj grupu
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "name",
            order: "name",
            label: "NAZIV",
            width: "200px",
          },
          {
            attribute: "location",
            order: "location_id",
            label: "LOKACIJA",
            width: "200px",
            mapper: (item) => {
              const location = item?.location?.name;
              const city = item?.location?.city;

              return `${location}, ${city}`;
            },
          },
          {
            attribute: "card_users",
            label: "KOLICINA KARTICNIH KORISNIKA",
            width: "200px",
            mapper: (item) => (item?.card_users ?? [])?.length,
          },
          {
            attribute: "created_at",
            order: "created_at",
            label: "KREIRANO NA",
            width: "200px",
            mapper: (item) =>
              item?.created_at
                ? new Date(item?.created_at)?.toLocaleString()
                : "NEDOSTUPAN DATUM",
          },
          {
            label: "AKCIJE",
            width: "200px",
            flex: 1,
            mapper: (item) => (
              <Select
                onChange={handleOptionSelect(item?.id)}
                value="none"
                options={[
                  {
                    label: "Odaberite jednu od akcija",
                    value: "none",
                    disabled: true,
                  },
                  { label: "Uredi grupu", value: "update" },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
