import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Button, FlexColumn, TitleXL } from "../../shared/styled";
import { Loading } from "../../shared/components/Loading";
import Label from "../../shared/components/Label/Label";
import Input from "../../shared/components/Input/Input";
import { useCacheProvider } from "../../contexts/Caching";
import { stationAccessKey } from "./cacheKeys";
import { getAccessRequestHttp, stationHealthHttp } from "../../apis/station";

export default function PrevieweWrapper() {
  const navigate = useNavigate();
  const { setKey } = useCacheProvider();

  const intervalRef = useRef<NodeJS.Timer>();

  const [accessKey, setAccessKey] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [accessKeyValid, setAccessKeyValid] = useState<boolean>(false);

  const [ipAddress, setIpAddress] = useState<string>();

  const checkStationHealth = useCallback(async (checkAccessKey?: string) => {
    if(accessKeyValid) return;
    const cachedAccessKey =
      checkAccessKey ?? localStorage.getItem(stationAccessKey);
    if (!cachedAccessKey) return;
    setAccessKey(cachedAccessKey);

    setLoading(true);
    try {
      await stationHealthHttp(cachedAccessKey);
      setAccessKeyValid(true);
      localStorage.setItem(stationAccessKey, cachedAccessKey);
      setKey(stationAccessKey, cachedAccessKey);
      navigate(`/station/location`);
    } catch (err) {
      setAccessKey(undefined);
      setAccessKeyValid(false);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkStationHealth();

    intervalRef.current = setInterval(() => {
      getAccessRequestHttp().then((res) => {
        const {data} = res;
        setIpAddress(data.ip_address)
        data?.access_key && checkStationHealth(data?.access_key);
      });
    }, 10000);

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [checkStationHealth]);

  return (
    <FlexColumn
      dimensions={{ width: "100vw", height: "100vh" }}
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading && <Loading />}
      {!isLoading && accessKeyValid && <Outlet />}
      {!isLoading && !accessKeyValid && (
        <FlexColumn
          dimensions={{ maxWidth: "500px", width: "100%" }}
          gap="20px"
        >
          {ipAddress && (
            <Label label={<TitleXL>IP Adressa uredaja</TitleXL>} direction="row">
              {ipAddress}
            </Label>
          )}
          <Label label={<TitleXL>Unesite pristupni kljuc</TitleXL>}></Label>
          <Input onChange={(event) => setAccessKey(event?.target?.value)} />
          <Button onClick={() => checkStationHealth(accessKey)}>
            Validiraj kljuc
          </Button>
        </FlexColumn>
      )}
    </FlexColumn>
  );
}
