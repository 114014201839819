import type CSS from "csstype";
import { styled } from "styled-components";

import {
  BackgroundStyling,
  BackgroundStylingProps,
  BorderStyling,
  BorderStylingProps,
  DimensionStyling,
  DimensionStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../styling";
import { colors, theme } from "../../../constants";

interface BadgeProps {
  dimensions?: DimensionStylingProps;
  border?: BorderStylingProps;
  background?: BackgroundStylingProps | CSS.Property.Background;
  color?: CSS.Property.Color;
  padding?: PaddingStylingProps | CSS.Property.Padding;
}

export const Badge = styled.span<BadgeProps>`
  padding: 4px;

  text-align: center;
  font-size: ${theme.font.size.x}px;
  border-radius: 4px;

  color: ${({ color }) => color || colors.white.clean};

  ${({ dimensions }) => DimensionStyling({ ...dimensions })};
  ${({ border }) => BorderStyling({ ...border })};
  ${({ background }) =>
    BackgroundStyling(
      typeof background === "string"
        ? background
        : { color: theme.colors.primary, ...background }
    )};
  ${({ padding }) => PaddingStyling(padding)};
`;
