import { createContext } from "react";
import { Nullable } from "../../types/utils";
import { AuthUser } from "./AuthUserType";

export interface AuthContextType {
  isLoading: boolean;
  authUser?: Nullable<AuthUser>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  getCurrentUser: () => Promise<void>;
  hasAccess: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  isLoading: false,
  authUser: undefined,
  login: async (username: string, password: string) => {},
  logout: () => {},
  getCurrentUser: async () => {},
  hasAccess: false
});
