import styled from "styled-components"
import { theme } from "../../../../constants"

export const NavbarStyle = styled.div`
  box-sizing: border-box;
  background: ${theme.colors.clean};
  border-right: solid 1px ${theme.border.color};

  width: 256px;
  height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;

  padding: 20px;
  gap: 50px;
`