import { useNavigate } from "react-router";
import { useCacheProvider } from "../../contexts/Caching";
import { useEffect, useState } from "react";
import {
  CardUserInterface,
  DeviceInterface,
  DeviceStatusEnum,
  IssueInterface,
} from "../../types/models";
import { interfaceAccessKey, interfaceCardUserKey } from "./cacheKeys";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexRow,
  Image,
  Paragraph,
  Text,
  Title,
  TitleXXL,
} from "../../shared/styled";
import { theme } from "../../constants";
import Icon from "../../shared/components/Icon/Icon";
import { IconTypes } from "../../shared/components/Icon";
import {
  listIssuesHttp,
  listMechanicDevicesHttp,
  startDeviceHttp,
} from "../../apis/interface";
import { DeviceBox } from "../components";
import { useModalProvider } from "../../contexts/ModalProviderContext";
import { ConfirmationModal } from "../../modals";
import Label from "../../shared/components/Label/Label";

export default function Mechanic() {
  const { openModal, closeModal } = useModalProvider();
  const navigate = useNavigate();

  const { getKey, deleteKey } = useCacheProvider();

  const [accessKey, setAccessKey] = useState<string>();
  const [cardCodeUser, setCardCodeUser] = useState<CardUserInterface>();

  const [isLoadingIssues, setLoadingIssues] = useState<boolean>(false);
  const [issues, setIssues] = useState<IssueInterface[]>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [devices, setDevices] = useState<DeviceInterface[]>();

  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const [deviceId, setDeviceId] = useState<number | undefined>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const backToInput = () => {
    deleteKey(interfaceCardUserKey);
    navigate("/interface/input");
  };

  const loadCachedData = () => {
    const cachedAccessKey = getKey(interfaceAccessKey, undefined);
    const cardUser = getKey(interfaceCardUserKey, undefined);

    if (!cachedAccessKey) backToInput();
    setAccessKey(cachedAccessKey as string);

    if (!cardUser) backToInput();
    setCardCodeUser(cardUser as CardUserInterface);
  };

  const fetchIssues = async () => {
    if (!accessKey || !cardCodeUser) return;

    setLoadingIssues(true);
    try {
      const { data: issuesData } = await listIssuesHttp(
        accessKey,
        cardCodeUser?.card_code
      );
      setIssues(issuesData);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setError("Permission is missing for this action!");
        return;
      }

      setError("Unknown Error");
    }
    setLoadingIssues(false);
  };

  const fetchDevices = async () => {
    if (!accessKey || !cardCodeUser) return;

    setLoading(true);
    try {
      const { data: deviceData } = await listMechanicDevicesHttp(
        accessKey,
        cardCodeUser?.card_code
      );
      setDevices(deviceData);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setError("Permission is missing for this action!");
        return;
      }

      setError("Unknown Error");
    }
    setLoading(false);
  };

  const updateDeviceStatus = async (issueId: number) => {
    if (isSubmitting || !accessKey || !cardCodeUser || !deviceId) return;

    setSubmitting(true);
    try {
      await startDeviceHttp(
        accessKey,
        cardCodeUser?.card_code,
        deviceId,
        issueId
      );
      setSuccess(true);
      setDeviceId(undefined);
      closeModal("confirmationModal")
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setError("Permission is missing for this action!");
        return;
      }

      if (err?.response?.status === 404) {
        setError("Device does not exists!");
        return;
      }

      if (err?.response?.status === 400) {
        setError("Device is already active!");
        return;
      }

      setError("Unknown Error");
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (!devices && !isLoading) fetchDevices();
    if (!issues && !isLoadingIssues) fetchIssues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardCodeUser]);

  useEffect(() => {
    loadCachedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inactiveDevices = (devices ?? [])?.filter(
    (device) => device?.status === DeviceStatusEnum.Inactive
  );
  const stoppingDevices = (devices ?? [])?.filter(
    (device) => device?.status === DeviceStatusEnum.Stopping
  );
  const checkupDevices = devices?.filter(
    (device) => device?.status === DeviceStatusEnum["Need Checkup"]
  );


  return (
    <>
      <FlexColumn dimensions={{ width: "100vw", height: "100vh" }}>
        <FlexRow
          dimensions={{ width: "100%" }}
          justifyContent="space-between"
          padding="8px"
          border={{ bottom: `solid 1px ${theme.border.color}` }}
        >
          <FlexRow alignItems="center" gap="10px">
            <Icon
              type={IconTypes.close}
              color={theme.font.color}
              size="48px"
              onClick={() =>
                !deviceId ? backToInput() : setDeviceId(undefined)
              }
            />

            <TitleXXL size="32px">LOCATION</TitleXXL>
          </FlexRow>
          <Image src="/assets/images/logo.png" />
        </FlexRow>
        <FlexColumn dimensions={{ flex: 1, width: "100%" }}>
          {deviceId && (
            <FlexRow
              dimensions={{ width: "100%" }}
              gap="10px"
              padding="10px"
              flexWrap="wrap"
            >
              {(issues || []).map((issue) => (
                <Button
                  padding="10px"
                  styleType={ButtonTypes.primary}
                  onClick={() =>
                    openModal("confirmationModal", ConfirmationModal, {
                      style: {
                        dimensions: {
                          maxWidth: "500px",
                        },
                      },
                      body: (
                        <FlexColumn gap="20px" padding="20px 0">
                          <Label style={{wrapper: {gap: "5px"}}}>
                            <Title>Opis</Title>
                            <Paragraph>{issue?.description}</Paragraph>
                          </Label>
                          <Label style={{wrapper: {gap: "5px"}}}>
                            <Title>Ime</Title>
                            <Paragraph>{issue?.name}</Paragraph>
                          </Label>

                          <Text>
                            Jeste li sigurni da zelite izabrati {issue?.name}?
                          </Text>
                        </FlexColumn>
                      ),
                      title: <TitleXXL>Jeste li sigurni?</TitleXXL>,
                      close: () => closeModal("confirmationModal"),
                      submit: () => updateDeviceStatus(issue?.id),
                      cancel: () => closeModal("confirmationModal"),
                    })
                  }
                >
                  <TitleXXL>{issue?.name}</TitleXXL>
                </Button>
              ))}
            </FlexRow>
          )}
          {devices && !isSuccess && !error && !deviceId && (
            <FlexColumn
              dimensions={{ width: "100%", height: "100%" }}
              gap="10px"
              padding="10px"
              overflow={{ y: "auto" }}
            >
              <FlexRow gap="10px" flexWrap="wrap">
                {inactiveDevices?.map((device) => (
                  <DeviceBox
                    data={device}
                    onClick={() => setDeviceId(device?.id)}
                  />
                ))}
              </FlexRow>
              <FlexRow gap="10px" flexWrap="wrap">
                {stoppingDevices?.map((device) => (
                  <DeviceBox
                    data={device}
                    onClick={() => setDeviceId(device?.id)}
                  />
                ))}
              </FlexRow>
              <FlexRow gap="10px" flexWrap="wrap">
                {checkupDevices?.map((device) => (
                  <DeviceBox
                    data={device}
                    onClick={() => setDeviceId(device?.id)}
                  />
                ))}
              </FlexRow>
            </FlexColumn>
          )}
          {error && (
            <FlexColumn
              dimensions={{ width: "100%", height: "100%" }}
              gap="20px"
              justifyContent="center"
              alignItems="center"
              onClick={() => backToInput()}
            >
              <TitleXXL color={theme.colors.danger}>Pritisni za izlaz!</TitleXXL>
              <Image  dimensions={{maxWidth: '300px', width: '100%', height: 'auto'}} src="/assets/images/click.png" />
              <Text color={theme.colors.danger}>{error}</Text>
            </FlexColumn>
          )}
          {isSuccess && (
            <FlexColumn
              dimensions={{ width: "100%", height: "100%" }}
              gap="20px"
              justifyContent="center"
              alignItems="center"
              onClick={() => backToInput()}
            >
              <TitleXXL>Masina je ponovno u stanju rada!</TitleXXL>
              <Image dimensions={{maxWidth: '300px', width: '100%', height: 'auto'}} src="/assets/images/click.png" />
              <Text color={theme.colors.success}>Pritisni za izlaz!</Text>
            </FlexColumn>
          )}
        </FlexColumn>
      </FlexColumn>
    </>
  );
}
