const _getAtPath = (object: { [key: string]: any }, path: string[]): unknown => {
  if (path?.length === 0) return undefined;
  if (path?.length === 1) {
    if (!(path[0] in object)) return undefined;
    return object[path[0]];
  }

  return _getAtPath(object[path[0]], path.slice(1));
};

export const getAtPath = (object: { [key: string]: any } | undefined, path: string): unknown => {
  if (!object) return undefined;
  const pathList = path.split(".");

  if (pathList?.length === 0) return null;
  if (pathList?.length === 1) {
    if (!(pathList[0] in object)) return undefined;
    return object[pathList[0]];
  }

  return _getAtPath(object[pathList[0]], pathList.slice(1));
};
