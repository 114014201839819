import type CSS from "csstype";

import React from "react";

import {
  BackgroundStylingProps,
  DimensionStylingProps,
  MarginStylingProps,
  PaddingStylingProps,
  OverflowStylingProps,
  BorderStylingProps,
} from "../../styling";

import { FlexContainer } from "./FlexContainer";

export interface FlexColumnProps {
  children?: React.ReactNode;
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  alignContent?: CSS.Property.AlignContent;
  gap?: CSS.Property.Gap;
  dimensions?: DimensionStylingProps;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  margin?: MarginStylingProps;
  overflow?: OverflowStylingProps | CSS.Property.Overflow;
  background?: BackgroundStylingProps | CSS.Property.Background;
  border?: BorderStylingProps;
  style?: React.CSSProperties;
  grow?: CSS.Property.FlexGrow;
  shrink?: CSS.Property.FlexShrink;
  position?: CSS.Property.Position;
  flexWrap?: CSS.Property.FlexWrap;
  onClick?: () => void;
}

export const FlexColumn = ({
  children,
  justifyContent,
  alignItems,
  alignContent,
  gap,
  dimensions,
  flexWrap,
  padding,
  margin,
  overflow,
  background,
  position,
  style,
  border,
  grow,
  shrink,
  onClick,
}: FlexColumnProps) => (
  <FlexContainer
    onClick={onClick}
    flexDirection="column"
    style={style}
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignContent={alignContent}
    gap={gap}
    dimensions={dimensions}
    padding={padding}
    margin={margin}
    position={position}
    overflow={overflow}
    background={background}
    flexWrap={flexWrap}
    border={border}
    grow={grow}
    shrink={shrink}
  >
    {children}
  </FlexContainer>
);
