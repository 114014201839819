import { ReactNode } from "react";
import { theme } from "../constants";
import {
  FlexColumn,
  FlexColumnProps,
  FlexRow,
  TitleXL,
} from "../shared/styled";
import Icon from "../shared/components/Icon/Icon";
import { IconTypes } from "../shared/components/Icon";

interface InfoModalInterface {
  style?: FlexColumnProps;
  body?: ReactNode;
  title: string;
  close?: () => void;
}

export const InfoModal = ({
  style,
  close,
  title,
  body,
}: InfoModalInterface) => {
  return (
    <FlexColumn
      background={theme.colors.clean}
      padding="10px"
      gap="20px"
      border={{ radius: "8px" }}
      {...style}
    >
      <FlexColumn dimensions={{ width: "100%" }} gap="5px">
        {close && (
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Icon
              type={IconTypes.close}
              size="32px"
              color={theme.font.color}
              onClick={() => close()}
            />
          </FlexRow>
        )}
        {title && (
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="flex-start"
            alignItems="center"
          >
            <TitleXL>{title}</TitleXL>
          </FlexRow>
        )}
      </FlexColumn>

      {body}
    </FlexColumn>
  );
};
