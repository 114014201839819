import { ENVS } from "../../../envs";

export const DefaultRequestSettings = {
  BaseUrl: ENVS.BACKEND_API,
  Headers: {
    "Content-Type": "application/json",
    "Authorization": localStorage.getItem("jwt-access") ? `Bearer ${localStorage.getItem("jwt-access")}` : undefined,
  },
  queryParams: {},
};
