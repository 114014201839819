import { useAuthProvider } from "../contexts/Auth";
import Form from "../shared/components/Form/Form";
import { FormLayout } from "../shared/components/Form/types/formLayout";
import { FlexColumn, FlexItem, Image } from "../shared/styled";

interface LoginForm {
  username: string;
  password: string;
}

const LoginFormLayout: FormLayout[] = [
  {
    label: "Korisnicko ime",
    type: "column",
    field: {
      name: "username",
      type: "text",
      placeholder: "Unesite koristicno ime...",
      options: {
        required: "Potrebno je korisnicko ime!",
      },
    },
  },
  {
    label: "Lozinka",
    type: "column",
    field: {
      name: "password",
      type: "password",
      placeholder: "Unesite lozinku...",
      options: {
        required: "Potrebna je lozinka!",
      },
    },
  },
];

export default function Login() {
  const { login } = useAuthProvider();

  const submitHandler = async (data: LoginForm) => login(data.username, data.password);

  return (
    <FlexColumn
      dimensions={{ width: "100vw", height: "100vh" }}
      gap="20px"
      alignItems="center"
      padding="50px"
    >
      <Image
        dimensions={{ maxWidth: "800px" }}
        src="/assets/images/logoBig.png"
      />
      <Form<LoginForm>
        onSubmit={submitHandler}
        layout={LoginFormLayout}
        style={{
          dimensions: {
            maxWidth: "500px",
            width: "100%",
            flex: 1,
          },
          gap: "20px",
          justifyContent: "center",
          direction:"column"
        }}
        buttons={{
          hasSubmit: true,
          submitLabel: <FlexItem padding="5px 50px">Prijava</FlexItem>,
          wrapperStyling: {
            margin: {
              top: "50px"
            },
          },
        }}
      />
    </FlexColumn>
  );
}
