import { TextareaHTMLAttributes, forwardRef } from "react";
import { DimensionStylingProps } from "../../styling";
import { TextAreaStyle, TextAreaStyleProps } from "./style";

export interface TextAreaPropStyle extends TextAreaStyleProps {
  style?: TextareaHTMLAttributes<HTMLTextAreaElement>["style"];
  dimensions?: DimensionStylingProps;
}

export interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "style"> {
  style?: TextAreaPropStyle;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ style, ...props }, ref) => {
    return <TextAreaStyle ref={ref} {...props} {...style} />;
  }
);

TextArea.displayName = "TextArea"; 

export default TextArea;
