import {
  FEBRUARY_INDEX,
  MONTH_NAMES,
  MONTH_NUMBER_OF_DAYS,
  MonthIndex,
} from "../constants";

export const getMonthInfo = (monthIndex: MonthIndex, year?: number) => {
  const tmpDate = new Date();
  year && tmpDate.setFullYear(year);
  tmpDate.setDate(1);
  tmpDate.setMonth(monthIndex);

  const startDay = tmpDate.getDay();

  const adjustedStartDay = startDay === 0 ? 7 : startDay - 1;

  return {
    startDay: adjustedStartDay,
    numberOfDays:
      MONTH_NUMBER_OF_DAYS[monthIndex] +
      (monthIndex === FEBRUARY_INDEX && tmpDate?.getFullYear() % 4 === 0
        ? 1
        : 0),
    name: MONTH_NAMES[monthIndex],
  };
};
