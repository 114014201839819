import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Header } from "../../../../shared/components/Header";
import {
  Button,
  Badge,
  FlexColumn,
  FlexRow,
  Text,
  TitleXL,
  TitleXXL,
  TitleXS,
} from "../../../../shared/styled";
import { listDevicesHttp } from "../../../../apis/admin/devices";
import {
  DeviceInterface,
  DeviceStatusEnum,
  DeviceStatusEnumTranslations,
  LocationInterface,
} from "../../../../types/models";
import { TablePagination } from "../../../../shared/components/Table";
import Table from "../../../../shared/components/Table/Table";
import { theme } from "../../../../constants";
import Select from "../../../../shared/components/Select/Select";
import { Filters } from "../../../../shared/genericScreens/Filters";
import { listSelectLocationsHttp } from "../../../../apis/admin/locations";
import { DeviceStylingOnStatus } from "../../../components";

interface SupportDataType {
  locations?: LocationInterface[];
}

export const MachineList = () => {
  const navigate = useNavigate();

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<DeviceInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await listDevicesHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 10,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        console.log("MachineList.fetchData", err);
      }
      setLoading(false);
    },
    [isLoading, pagination, filters, ordering]
  );

  const updatePagination = useCallback(
    (updatedPagination: typeof pagination) => {
      setPagination(updatedPagination);
      fetchData(updatedPagination);
    },
    [fetchData]
  );

  const updateOrdering = useCallback(
    (updatedOrdering: typeof ordering) => {
      setOrdering(updatedOrdering);
      fetchData(pagination, filters, updatedOrdering);
    },
    [fetchData, pagination, filters]
  );

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(pagination, updatedFilters);
    },
    [pagination, fetchData]
  );

  const handleOptionSelect = (itemId?: number | string) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value;
      if (value === "update") {
        navigate(`/admin/machine/${itemId}`);
      }
    };
  };

  /*Inital Fetch*/
  useEffect(() => {
    fetchSupportData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>MASINE</TitleXXL>
      <Filters
        isLoading={isLoading || isLoadingSupportData}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            label: <TitleXS>Status</TitleXS>,
            inputType: "select",
            type: "number",
            attribute: "status",
            name: "status",
            options: [
              { value: -1, label: "Nije selektovan" },
              { value: DeviceStatusEnum.Free, label: "Slobodna" },
              { value: DeviceStatusEnum.Active, label: "Aktivna" },
              { value: DeviceStatusEnum["Need Checkup"], label: "Provjera" },
              { value: DeviceStatusEnum.Stopping, label: "Prestaje sa radom" },
              { value: DeviceStatusEnum.Inactive, label: "Neaktivna" },
            ],
          },
          {
            label: <TitleXS>Lokacija</TitleXS>,
            inputType: "select",
            type: "number",
            attribute: "location_id",
            name: "location_id",
            options: [
              { value: -1, label: "Nije selektovana" },
              ...(supportData?.locations ?? [])?.map((location) => ({
                value: location?.id,
                label: `${location?.name}, ${location?.city}`,
              })),
            ],
          },
          {
            label: <TitleXS>Pretraga</TitleXS>,
            placeholder: "Pretgazi...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<DeviceInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>
              Lista masina ({pagination?.count})
            </TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button onClick={() => navigate("/admin/machine/create")}>
                Kreiraj masinu
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "name",
            order: "name",
            label: "NAZIV",
            width: "200px",
          },
          {
            attribute: "code",
            order: "code",
            label: "TIP",
            width: "200px",
          },
          {
            attribute: "location_id",
            order: "location_id",
            label: "Lokacija",
            width: "250px",
            mapper: (item) => {
              if (!item?.location)
                return (
                  <Badge background={theme.colors.danger}>
                    <Text
                      weight={theme.font.weight.bold}
                      color={theme.colors.clean}
                    >
                      NEMA LOKACIJE
                    </Text>
                  </Badge>
                );

              return (
                <Text weight={theme.font.weight.bold} color={theme.font.color}>
                  {item?.location?.name}, {item?.location?.city}
                </Text>
              );
            },
          },

          {
            attribute: "status",
            order: "status",
            label: "STATUS",
            width: "100px",
            mapper: (item) => {
              const currentStatus: keyof typeof DeviceStylingOnStatus =
                item?.status ?? 0;

              return (
                <Badge
                  background={DeviceStylingOnStatus[currentStatus]?.background}
                >
                  <Text
                    weight={theme.font.weight.bold}
                    color={DeviceStylingOnStatus[currentStatus]?.font}
                  >
                    {
                      //@ts-ignore
                      DeviceStatusEnumTranslations[
                        //@ts-ignore
                        DeviceStatusEnum[currentStatus]
                      ]
                    }
                  </Text>
                </Badge>
              );
            },
          },
          {
            attribute: "inventure_number",
            order: "inventure_number",
            label: "INVENTURNI BROJ",
            width: "250px",
            mapper: (item) =>
              item?.inventure_number ? (
                item?.inventure_number
              ) : (
                <Badge background={theme.colors.danger}>
                  <Text
                    weight={theme.font.weight.bold}
                    color={theme.colors.clean}
                  >
                    NEDOSTUPAN
                  </Text>
                </Badge>
              ),
          },
          /* {
            attribute: "mac_address",
            order: "mac_address",
            label: "MAC ADDRESS",
            width: "200px",
            mapper: (item) =>
              item?.mac_address.toUpperCase() === "VIRTUAL" ? (
                <Badge background={theme.colors.primary}>
                  <Text
                    weight={theme.font.weight.bold}
                    color={theme.colors.clean}
                  >
                    VIRTUAL
                  </Text>
                </Badge>
              ) : (
                item?.mac_address
              ),
          }, */
          {
            label: "AKCIJE",
            flex: 1,
            mapper: (item) => (
              <Select
                onChange={handleOptionSelect(item?.id)}
                value="none"
                options={[
                  {
                    label: "Odaberite akciju",
                    value: "none",
                    disabled: true,
                  },
                  { label: "Uredite masinu", value: "update" },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
