import { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexRow,
  Image,
  Text,
} from "../../styled";
import { NavbarItem } from "./NavbarItem";
import { NavbarStyle } from "./style";
import { NavbarRoute } from "./types";
import { useLocation, useNavigate } from "react-router";
import { theme } from "../../../constants";
import { getActiveRouteByPathname } from "./utilities";
import Icon from "../Icon/Icon";
import { IconTypes } from "../Icon";
import { useAuthProvider } from "../../../contexts/Auth";

export interface NavbarProps {
  routes: NavbarRoute[];
}

export default function Navbar({ routes }: NavbarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useAuthProvider();

  const isInternalChange = useRef<boolean>(false);

  const [selectedRouteIds, setSelectedRouteIds] = useState<string[]>([]);
  const [renderRoutes, setRenderRoutes] = useState<NavbarRoute[]>(routes);
  const [routeParent, setRouteParent] = useState<NavbarRoute | undefined>();
  const [routesStack, setRoutesStack] = useState<NavbarRoute[]>([]);

  const selectRoute = (route: NavbarRoute) => {
    isInternalChange.current = true;
    route?.path &&
      setSelectedRouteIds([
        ...routesStack?.map((stackedRoute) => stackedRoute?.id),
        ...(routeParent ? [routeParent?.id] : []),
        route?.id,
      ]);
    route?.path && navigate(route?.path);

    if (route?.nestedRoutes?.length) {
      setRenderRoutes(route?.nestedRoutes);

      if (routeParent) setRoutesStack([...routesStack, routeParent]);
      setRouteParent(route);
    }
  };

  const back = () => {
    const parentRoute = routesStack.at(-1);
    setRenderRoutes(parentRoute ? parentRoute?.nestedRoutes ?? [] : routes);
    setRouteParent(parentRoute);
    setRoutesStack(routesStack?.slice(0, routesStack?.length - 1));
  };

  const adjustInternalValues = useCallback(() => {
    const activeRoute = getActiveRouteByPathname(routes, location?.pathname);
    if (!activeRoute) return;

    // Track nd construct routesStack
    let constructedRoutesStack: NavbarRoute[] = [
      {
        id: activeRoute.id,
        path: activeRoute?.path,
        icon: activeRoute.icon,
        label: activeRoute?.label,
        nestedRoutes: activeRoute?.nestedRoutes,
      },
    ];

    let iterator = activeRoute?.parent;
    while (iterator) {
      constructedRoutesStack = [
        {
          id: iterator.id,
          path: iterator?.path,
          icon: iterator.icon,
          label: iterator?.label,
          nestedRoutes: iterator?.nestedRoutes,
        },
        ...constructedRoutesStack,
      ];
      iterator = iterator?.parent;
    }

    setRoutesStack(
      constructedRoutesStack
        ?.filter((route) => route?.nestedRoutes?.length)
        .slice(0, -1)
    );
    setSelectedRouteIds(constructedRoutesStack?.map((route) => route?.id));
    setRouteParent(activeRoute?.parent);
    setRenderRoutes(activeRoute?.parent?.nestedRoutes ?? routes);
  }, [location?.pathname, routes]);

  useEffect(() => {
    if (!isInternalChange?.current) adjustInternalValues();
    isInternalChange.current = false;
  }, [adjustInternalValues, location?.pathname]);

  return (
    <NavbarStyle>
      <Image src="/assets/images/logo.png" />
      <FlexColumn dimensions={{ width: "100%" }}>
        {
          <FlexColumn dimensions={{ width: "100%", minHeight: "61px" }}>
            {routeParent && (
              <>
                <Button
                  styleType={ButtonTypes.naked}
                  dimensions={{ width: "100%" }}
                  onClick={back}
                >
                  <FlexRow
                    gap="10px"
                    alignItems="center"
                    dimensions={{ width: "100%" }}
                  >
                    <Icon
                      type={IconTypes.back}
                      color={theme.colors.primary}
                      size="24px"
                    />
                    <Text color={theme.colors.primary}>NAZAD</Text>
                  </FlexRow>
                </Button>
                <FlexRow
                  dimensions={{ width: "100%" }}
                  border={{ bottom: `solid 1px ${theme.border.color}` }}
                  padding="4px"
                  alignItems="flex-end"
                  gap="5px"
                >
                  <Icon
                    type={routeParent?.icon}
                    color={theme.font.color}
                    size="20px"
                  />
                  <Text
                    color={theme.font.color}
                    size="12px"
                    weight={theme.font.weight.bold}
                  >
                    {routeParent?.label}
                  </Text>
                </FlexRow>
              </>
            )}
          </FlexColumn>
        }

        <FlexColumn dimensions={{ width: "100%" }}>
          {renderRoutes?.map((route) => (
            <NavbarItem
              key={route?.id}
              isSelected={selectedRouteIds.includes(route?.id)}
              selectRoute={() => selectRoute(route)}
              {...route}
            >
              {route?.label}
            </NavbarItem>
          ))}
        </FlexColumn>
      </FlexColumn>
      <FlexColumn dimensions={{ width: "100%" }}>
        <Button
          styleType={ButtonTypes.primaryReverse}
          onClick={() => {
            logout();
            navigate("/login");
          }}
        >
          Odjava
        </Button>
      </FlexColumn>
    </NavbarStyle>
  );
}
