import { Outlet, useNavigate } from "react-router";
import Select from "react-select";

import { FlexColumn, FlexRow, TitleXL } from "../../shared/styled";
import { colors } from "../../constants";
import { SANDBOX_ROUTS } from "./routes";

export default function Sandbox() {
  const navigate = useNavigate();

  return (
    <FlexColumn
      gap="20px"
      dimensions={{ width: "100vw", height: "100vh" }}
      padding="32px"
    >
      <FlexRow
        dimensions={{ width: "100%" }}
        background={colors.grey.gallery}
        padding="10px"
        justifyContent="space-between"
      >
        <TitleXL>SANDBOX</TitleXL>
        <Select
          options={SANDBOX_ROUTS.map((route) => ({
            label: route?.id?.replaceAll("-", " "),
            value: route?.path,
          }))}
          onChange={(option) => navigate(option?.value as string)}
        />
      </FlexRow>

      <Outlet />
    </FlexColumn>
  );
}
