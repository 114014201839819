import { NavbarRoute } from "../types";

interface ActiveNavbarRoute extends NavbarRoute {
  parent?: ActiveNavbarRoute;
}

export const getActiveRouteByPathname = (
  routes: NavbarRoute[],
  pathname: string
): ActiveNavbarRoute | undefined => {
  for (let i = 0; i < routes?.length; i++) {
    const route = routes[i];
    if (route?.path && route?.path === pathname) return route;

    if (route?.nestedRoutes?.length) {
      const foundPath = getActiveRouteByPathname(
        route?.nestedRoutes?.map((nestedRoute) => ({
          ...nestedRoute,
          parent: route,
        })),
        pathname
      );

      if (foundPath) return foundPath;
    }
  }

  return undefined;
};
