import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexRow,
  Image,
  TitleXL,
  TitleXXL,
} from "../shared/styled";
import { useStateRef } from "../hooks";
import {
  CameraImageCapture,
  useMediaDevice,
} from "../shared/components/CameraImageCapture";
import { inputDeviceKey } from "../screens/interface/cacheKeys";
import Select from "../shared/components/Select/Select";
import { theme } from "../constants";
import { basicCardReaderHttp } from "../apis/interface/basicCardReaderHttp";
import { adminCardReaderHttp } from "../apis/admin/utilities";
import Icon from "../shared/components/Icon/Icon";
import { IconTypes } from "../shared/components/Icon";

export interface ScannerModalProps {
  title?: ReactNode;
  onSuccess: (data: string) => void;
  close: () => void;
  httpType: "admin" | "interface";
  accessKey?: string;
}

export const ScannerModal = ({
  httpType,
  onSuccess,
  close,
  accessKey,
}: ScannerModalProps) => {
  const { hasPermission, devices } = useMediaDevice();
  const {
    set: setLoading,
    refValue: isLoadingRef,
    stateValue: isLoadingState,
  } = useStateRef<boolean>(false);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const [currentDevice, setCurrentDevice] = useState<string | undefined>(
    localStorage.getItem(inputDeviceKey) ?? undefined
  );

  const [isInputing, setInputing] = useState<boolean>(false);

  const cardReader = useCallback(
    async (base64Data: string) => {
      if (isLoadingRef) return;

      setLoading(true);
      try {
        const { data } = await (httpType === "admin"
          ? adminCardReaderHttp(base64Data)
          : basicCardReaderHttp(accessKey as string, base64Data));

        if (Array.isArray(data) && data.length > 0) {
          const cardData = data[0]?.data;
          onSuccess(cardData);
        } else {
          onSuccess(data?.data);
        }
      } catch (err) {
        //TODO Handle error
        // console.log(err);
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingState]
  );

  const getCameraBase64 = (base64Data: string | null) => {
    if (!base64Data) return;
    cardReader(base64Data);
  };

  useEffect(() => {
    return () => {
      setLoading(false);
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn
      background={theme.colors.clean}
      position="fixed"
      style={{ top: 0, left: 0 }}
      dimensions={{ width: "100vw", height: "100vh" }}
      padding="10px"
    >
      <FlexRow dimensions={{ width: "100%" }} justifyContent="space-between">
        {close && (
          <Button
            border={{ radius: "50%" }}
            padding="10px"
            styleType={ButtonTypes.primary}
            onClick={() => close()}
          >
            <Icon
              type={IconTypes.close}
              size="32px"
              color={theme.colors.clean}
            />
          </Button>
        )}

        {devices && (
          <Select
            options={[
              { value: -1, label: "Select device" },
              ...devices?.map((device) => ({
                value: device.deviceId,
                label: device.label,
              })),
            ]}
            onChange={(event) => {
              const useDevice =
                event?.target?.value === "-1"
                  ? undefined
                  : event?.target?.value;
              useDevice && localStorage.setItem(inputDeviceKey, useDevice);
              setCurrentDevice(useDevice);
            }}
          />
        )}
      </FlexRow>

      {hasPermission && (
        <>
          {devices && (
            <FlexColumn
              position="fixed"
              style={{ right: "5px", top: " 5px", zIndex: 2 }}
              dimensions={{ width: "max-content", height: "max-content" }}
            ></FlexColumn>
          )}
          {!currentDevice && (
            <FlexColumn
              dimensions={{ width: "100%", height: "100%" }}
              justifyContent="center"
              alignItems="center"
            >
              <TitleXL>Please select device for code scanning!</TitleXL>
            </FlexColumn>
          )}
          <FlexColumn
            dimensions={{ width: "100%", height: "100%" }}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setInputing(true);
              timeoutRef.current = setTimeout(() => {
                setInputing(false);
                timeoutRef.current = undefined;
              }, 20 * 1000);
            }}
          >
            {currentDevice && !isInputing && (
              <>
                <FlexColumn
                  dimensions={{ width: "100%", height: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Image
                    dimensions={{
                      maxWidth: "300px",
                      width: "100%",
                      height: "auto",
                    }}
                    src="/assets/images/click.png"
                  />
                  <TitleXXL>Click to scane</TitleXXL>
                </FlexColumn>
              </>
            )}
            {currentDevice && isInputing && (
              <CameraImageCapture
                key={currentDevice}
                captureTimeout={1000}
                onCapture={getCameraBase64}
                dimensions={{ width: "100%", maxHeight: "100%" }}
                constrains={{ deviceId: currentDevice }}
              />
            )}
          </FlexColumn>
        </>
      )}
      {!hasPermission && (
        <FlexColumn
          dimensions={{ width: "100%", height: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <TitleXL color={theme.colors.danger}>
            Software does not have permission to access camera device
          </TitleXL>
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
