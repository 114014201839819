import { httpPost } from "../../utilities";

export interface CreateDeviceHttpData {
  location_id?: number | null;
  group_id?: number | null;
  mac_address?: string;
  code?: string;
  status?: number;
  card_user_id?: number | null;
  manufacturer?: string;
  name?: string;
  inventure_number?: string;
}

export const createDeviceHttp = async (data: CreateDeviceHttpData) =>
  httpPost("/admin/devices", data);