import { Nullable } from "../utils";
import { GroupInterface } from "./group";
import { LocationInterface } from "./location";

export enum CardUserTypesEnum {
  "No Role",
  "Employee",
  "Mechanic",
  "GroupLead",
  "Manager"
}

export const CardUserTypesEnumTranslated = {
  "No Role": "Nema uloge",
  "Employee": "Radnik",
  "Mechanic": "Mehanicar",
  "GroupLead": "Grupovodja",
  "Manager": "Menadzer"
}

export interface CardUserInterface {
  id: number;
  created_at: Date;

  group_id?: Nullable<number>;
  group?: Nullable<GroupInterface>;

  locatoin_id?: Nullable<number>;
  location?: Nullable<LocationInterface>;

  first_name: string;
  last_name: string;

  card_code: string;
  type: CardUserTypesEnum;
  is_active?: boolean;

  is_mobile_access_active?: Nullable<boolean>;
}