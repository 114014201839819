import { IconTypes } from "../../shared/components/Icon";
import Icon from "../../shared/components/Icon/Icon";
import Label from "../../shared/components/Label/Label";
import { FlexColumn, FlexRow, Text, Title } from "../../shared/styled";
import { DeviceInterface, DeviceStatusEnum } from "../../types/models";
import { DeviceStylingOnStatus } from "./StatusStyling";

interface DeviceBoxProps {
  data?: DeviceInterface;
  onClick?: () => void;
  showIcon?: boolean;
}

export const DeviceBox = ({
  data,
  onClick,
  showIcon = true,
}: DeviceBoxProps) => {
  const statusAsNumber = data?.status as DeviceStatusEnum;
  const background = DeviceStylingOnStatus?.[statusAsNumber].background;
  const font = DeviceStylingOnStatus?.[statusAsNumber].font;
  const border = DeviceStylingOnStatus?.[statusAsNumber].border;

  return (
    <FlexRow
      dimensions={{ width: "max-content", height: "max-content" }}
      onClick={onClick}
      padding="10px"
      background={background}
      border={`solid 1px ${border}`}
      alignItems="flex-start"
      gap="10px"
      cursor="pointer"
    >
      {showIcon && <Icon type={IconTypes.machine} size="48px" color={font} />}
      <FlexColumn gap="10px">
        {data?.group && (
          <Label
            style={{ wrapper: { gap: "2px" } }}
            label={<Text color={font}>GRUPA</Text>}
          >
            <Title color={font}>
              {data?.group?.name}, {data?.group?.location?.name}
            </Title>
          </Label>
        )}
        {data?.card_user && (
          <Label
            style={{ wrapper: { gap: "2px" } }}
            label={<Text color={font}>RADNIK</Text>}
          >
            <Title color={font}>
              {data?.card_user?.first_name} {data?.card_user?.last_name}
            </Title>
          </Label>
        )}
        {data?.inventure_number && (
          <Label
            style={{ wrapper: { gap: "2px" } }}
            label={<Text color={font}>INVENTURNI BROJ</Text>}
          >
            <Title color={font}>{data?.inventure_number}</Title>
          </Label>
        )}
        <Label
          style={{ wrapper: { gap: "2px" } }}
          label={<Text color={font}>KOD</Text>}
        >
          <Title color={font}>{data?.code}</Title>
        </Label>
      </FlexColumn>
    </FlexRow>
  );
};
