import { PaginationResponse } from "../../../types";
import { DeviceInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export interface ListDevicesHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  mac_address__in?: string[];
  code__in?: string[];
  tags__icontains?: string[];
  location_id__exact?: string;
  location_id?: string;
}

export const listDevicesHttp = async (
  offset: number,
  limit: number,
  filters: ListDevicesHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<DeviceInterface>>("/admin/devices", {
    ...filters,
    offset,
    limit,
    ordering,
  });