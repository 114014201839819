import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../../shared/components/Header";
import { Loading } from "../../../../shared/components/Loading";
import { Scroll } from "../../../../shared/components/Scroll";
import Table from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/genericScreens/Filters";
import { FlexRow, TitleXXL, TitleXS, FlexColumn, Text} from "../../../../shared/styled";

import {CardUserInterface, LocationInterface} from "../../../../types/models";

import { listSelectLocationsHttp } from "../../../../apis/admin/locations";
import { mechanicsEfficencyHttp } from "../../../../apis/admin/analytics/mechanicsAnalyticsHttp";


interface TransformedResponseData {
  amount: number;
  mechanic: CardUserInterface;
  seconds: number;
}

interface SupportDataType {
  locations?: LocationInterface[];
}

const getDefaultcreatedAtFilter = () => {
  const currentDate = new Date();
  currentDate.setDate(1);

  const endDate = new Date();
  endDate.setDate(1);
  if (endDate.getMonth() + 1 < 12) {
    endDate.setMonth(endDate.getMonth() + 1);
  } else {
    endDate.setMonth(0);
    endDate.setFullYear(endDate.getFullYear() + 1);
  }

  return {
    start: currentDate,
    end: endDate,
  };
};

export const MechanicsStatistics = () => {
  const [data, setData] = useState<TransformedResponseData[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isReadyToFetch, setReadyToFetch] = useState<boolean>(false);

  const [filters, setFilters] = useState<{ [key: string]: any }>({
    created_at: getDefaultcreatedAtFilter(),
  });

  const fetchData = useCallback(
    async (_filters: typeof filters = filters) => {
      if (!_filters?.created_at) return;
      if (isLoading) return;

      setIsLoading(true);
      try {
        const { created_at, ...restFilters } = _filters;

        const created_at__gte = (created_at.start as Date)
          ?.toISOString()
          .split("T")[0];
        const created_at__lte = (created_at.end as Date)
          ?.toISOString()
          .split("T")[0];

        const updatedFilters = {
          ...restFilters,
          created_at__gte,
          created_at__lte,
        };

        const { data: responseDate } = await mechanicsEfficencyHttp(updatedFilters);
        const transformedData: typeof data = [];
        Object.keys(responseDate).forEach(idKey => {
          transformedData.push({
            amount: responseDate[idKey].amount_of_resolved_issues,
            mechanic: responseDate[idKey].data,
            seconds: responseDate[idKey].seconds_spent
          })
        });
        setData(transformedData.sort((x,y) => y.amount-x.amount));

      } catch (err) {
        console.log("Analytics.fetchData", err);
      }
      setIsLoading(false);
    },
    [isLoading, filters]
  );

  const fetchSupportData = useCallback(async () => {
    const updatedSupportData: typeof supportData = {};


    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
  }, []);


  useEffect(() => {
    fetchSupportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (supportData && !isReadyToFetch) setReadyToFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportData]);

  useEffect(() => {
    if (isReadyToFetch) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToFetch]);

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(updatedFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData]
  );


  return (
    <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
      <FlexColumn dimensions={{ width: "100%" }} gap="20px" padding="16px">
        <Header />
        <TitleXXL>ANALITIKA MEHANICARA</TitleXXL>
        <Filters
          isLoading={isLoading}
          values={filters}
          setValues={updateFilters}
          items={[
            {
              label: <TitleXS>Lokacija</TitleXS>,
              placeholder: "Lokacije",
              inputType: "select",
              type: "number",
              attribute: "location_id",
              name: "location_id",
              options: [
                { value: -1, label: "Nije selektovano" },
                ...(supportData?.locations ?? [])?.map((location) => ({
                  value: location?.id,
                  label: `${location?.name}, ${location?.city}`,
                })),
              ],
            },
            {
              label: <TitleXS>Zabiljezeno na</TitleXS>,
              inputType: "dateRange",
              attribute: "created_at",
              name: "created_at",
              placeholder: "Created At",
            },
          ]}
        />

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Table<TransformedResponseData>
              title={
                <FlexRow
                  dimensions={{ width: "100%" }}
                  justifyContent="space-between"
                  alignItems="center"
                  padding={"5px 0"}
                >
                  <TitleXXL>TABELA INTERVENCIJA MEHANICARA</TitleXXL>
                </FlexRow>
              }
              isLoading={isLoading}
              schema={[
                {
                  label: "Prezime i Ime",
                  mapper: (item) => <Text>{item?.mechanic.last_name} {item?.mechanic.first_name}</Text>,
                  flex: 1,
                },
                {
                  label: "Broj uspijesnih intervencija",
                  attribute: "amount",
                  flex: 1
                },
                {
                  label: "Provedenih minuta u radu",
                  mapper: (item) => <Text>
                    { item?.seconds && 0 < item?.seconds && <>{Math.round(item?.seconds/60*100)/100}</>}
                    { !(item?.seconds && 0 < item?.seconds) && <>0</>}
                    </Text>,
                  flex: 1
                },
              ]}
              data={data ?? []}
            />
          </>
        )}
      </FlexColumn>
    </Scroll>
  );
};
