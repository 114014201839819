import type CSS from "csstype";
import { FC, ReactNode, UIEvent } from "react";
import { styled } from "styled-components";

import {
  DimensionStyling,
  DimensionStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../styling";
import { colors, theme } from "../../../constants";

interface ScrollStylingProps {
  dimensions?: DimensionStylingProps;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  scrollBar?: {
    width?: CSS.Property.Width;
  }
}

const ScrollStyling = styled.div<ScrollStylingProps>`
  overflow: auto;

  ${({ dimensions }) => DimensionStyling({ ...dimensions })};
  ${({ padding }) => PaddingStyling(padding || "16px")}

  &::-webkit-scrollbar {
    width: ${({ scrollBar }) => scrollBar?.width || "8px"};
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.primary}; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: ${colors.white.gallery};
  }
`;

interface ScrollProps {
  loadNext?: () => Promise<void>;
  isLoading?: boolean;
  LoadingComponent?: FC;
  EmptyComponent?: FC;

  wrapperStyle?: ScrollStylingProps;
  children?: ReactNode;

  onScrollHandler?: (event?: UIEvent<HTMLDivElement, globalThis.UIEvent>) => void;
}

export const Scroll = ({
  loadNext,
  wrapperStyle,
  isLoading,
  LoadingComponent,
  EmptyComponent,
  children,
  onScrollHandler
}: ScrollProps) => {
  const onScroll = (event: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
    onScrollHandler && onScrollHandler(event);

    const target = event.target as HTMLDivElement;

    // Calculate if the user has scrolled to the bottom
    const isAtBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;

    // If at the bottom and loadNext prop exists, call the loadNext function
    if (isAtBottom && loadNext) {
      loadNext();
    }
  };

  return (
    // onScroll
    <ScrollStyling {...wrapperStyle} onScroll={onScroll}>
      {!children && EmptyComponent && <EmptyComponent />}
      {!!children && children}
      {isLoading && LoadingComponent && <LoadingComponent />}
    </ScrollStyling>
  );
};
