import { useNavigate } from "react-router";
import { useCacheProvider } from "../../contexts/Caching";
import { useEffect, useRef, useState } from "react";
import {
  DeviceInterface,
  DeviceStatusEnum,
  StationAuthInterface,
} from "../../types/models";
import { stationAccessKey, stationCacheKey } from "./cacheKeys";
import { devicePreviewHttp, httpCurrentStation, httpCurrentStationStatus } from "../../apis/station";
import {
  Badge,
  FlexColumn,
  FlexRow,
  Image,
  Text,
  TitleXXL,
} from "../../shared/styled";
import { theme } from "../../constants";
import Icon from "../../shared/components/Icon/Icon";
import { IconTypes } from "../../shared/components/Icon";
import { DeviceBox } from "../components";
import { Loading } from "../../shared/components/Loading";

export default function LocationPreview() {
  const navigate = useNavigate();
  const { getKey, deleteKey } = useCacheProvider();

  const [accessKey, setAccessKey] = useState<string>();

  const [devices, setDevices] = useState<DeviceInterface[]>();
  const [station, setStation] = useState<StationAuthInterface>();
  const [isWorking, setWorking] = useState<boolean>(false);

  const dataUpdateInterval = useRef<NodeJS.Timer>();

  const [error, setError] = useState<string | undefined>();

  const backToInput = () => {
    deleteKey(stationCacheKey);
    localStorage.removeItem(stationAccessKey);
    navigate("/station");
  };

  const loadCachedData = () => {
    const cachedAccessKey = getKey(stationAccessKey, undefined);

    if (!cachedAccessKey) backToInput();
    setAccessKey(cachedAccessKey as string);
  };

  const fetchGroups = async () => {
    if (!accessKey) return;
    try {
      const { data: groupsData } = await devicePreviewHttp(accessKey);
      setDevices(groupsData);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setError("Permission is missing for this action!");
        return;
      }

      setError("Unknown Error");
    }
  };

  const fetchStationStatus = async () => {
    if (!accessKey) return;

    try {
      const { data: statusData } = await httpCurrentStationStatus(accessKey);
      setWorking(statusData.status);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setError("Permission is missing for this action!");
        return;
      }

      setError("Unknown Error");
    }

  }

  const intervalTask = () => {
    fetchGroups();
    fetchStationStatus();
  }

  const startFetchUpdates = () => {
    if (dataUpdateInterval.current) return;
    dataUpdateInterval.current = setInterval(intervalTask, 10000);
  };

  const loadStation = async () => {
    if (!accessKey || station) return;

    try {
      const { data: stationData } = await httpCurrentStation(accessKey);
      
      if(dataUpdateInterval.current) {
        clearInterval(dataUpdateInterval.current);
      }
      setStation(stationData);
    }
    catch (err: any) {
      err?.message || setError("Unknown Error");
    }
  }

  useEffect(() => {

  }, [accessKey])

  // Start Intervals
  useEffect(() => {
    if (!station) return;
    if (!dataUpdateInterval.current) startFetchUpdates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  useEffect(() => {
    if (accessKey) loadStation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessKey]);

  useEffect(() => {
    loadCachedData();
  
    return () => {
      if (dataUpdateInterval.current) clearInterval(dataUpdateInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inactiveDevices = devices?.filter(
    (device) => device?.status === DeviceStatusEnum.Inactive
  );
  const stoppingDevices = devices?.filter(
    (device) => device?.status === DeviceStatusEnum.Stopping
  );
  const checkupDevices = devices?.filter(
    (device) => device?.status === DeviceStatusEnum["Need Checkup"]
  );

  return (
    <FlexColumn dimensions={{ width: "100vw", height: "100vh" }}>
      <FlexRow
        dimensions={{ width: "100%" }}
        justifyContent="space-between"
        padding="8px"
        border={{ bottom: `solid 1px ${theme.border.color}` }}
      >
        <FlexRow alignItems="center" gap="10px">
          <Icon
            type={IconTypes.close}
            color={theme.font.color}
            size="48px"
            onClick={() => backToInput()}
          />

          <TitleXXL size="32px">
            {station ? (
              `${station?.location?.name}, ${station?.location?.city}`
            ) : (
              <Loading size="32px" />
            )}
          </TitleXXL>

          <Badge background={isWorking ? theme.colors.success : theme.colors.danger}>
            {isWorking ? <>AKTIVNA</> : <>NE AKTIVNA</>}
          </Badge>
        </FlexRow>
        <Image src="/assets/images/logo.png" />
      </FlexRow>
      <FlexColumn dimensions={{ flex: 1, width: "100%" }}>
        {devices && !error && (
          <FlexColumn
            dimensions={{ width: "100%", height: "100%" }}
            gap="10px"
            padding="10px"
            overflow={{ y: "auto" }}
          >
            <FlexRow gap="10px" flexWrap="wrap">
              {inactiveDevices?.map((device) => (
                <DeviceBox showIcon={false} data={device} />
              ))}
            </FlexRow>
            <FlexRow gap="10px" flexWrap="wrap">
              {stoppingDevices?.map((device) => (
                <DeviceBox showIcon={false} data={device} />
              ))}
            </FlexRow>
            <FlexRow gap="10px" flexWrap="wrap">
              {checkupDevices?.map((device) => (
                <DeviceBox showIcon={false} data={device} />
              ))}
            </FlexRow>
          </FlexColumn>
        )}
        {error && (
          <FlexColumn
            dimensions={{ width: "100%", height: "100%" }}
            gap="20px"
            justifyContent="center"
            alignItems="center"
            onClick={() => fetchGroups()}
          >
            <Text color={theme.colors.danger}>{error}</Text>
          </FlexColumn>
        )}
      </FlexColumn>
    </FlexColumn>
  );
}
