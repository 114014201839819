import { PaginationResponse } from "../../../types";
import { UserAuthInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export const USERS_LIST_API = "/admin/users";

export interface UsersListHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  username__in?: string[];
}

export const usersListHttp = async (
  offset: number,
  limit: number,
  filters: UsersListHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<UserAuthInterface>>(USERS_LIST_API, {
    ...filters,
    offset,
    limit,
    ordering,
  });
