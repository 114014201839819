import CSS from "csstype";
import styled from "styled-components";

import {
  DimensionStyling,
  DimensionStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../styling";

export interface FormStyleProps {
  dimensions?: DimensionStylingProps;
  padding?: PaddingStylingProps | CSS.Property.Padding;

  gap?: CSS.Property.JustifyContent;
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  direction?: CSS.Property.FlexDirection;
}

export const FormStyle = styled.form<FormStyleProps>`
  display: flex;
  flex-direction: ${({direction}) => direction};
  box-sizing: border-box;

  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};

  ${({ dimensions }) => DimensionStyling(dimensions)};
  ${({ padding }) => PaddingStyling(padding)};
`;
