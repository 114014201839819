import { httpPost } from "../utilities";

interface BasicCardReaderHttpResponse {
  data: string;
  type: string;
}

export const basicCardReaderHttp = async (accessCode: string, base64: string) =>
  httpPost<BasicCardReaderHttpResponse>(
    "/interface/reader/basic/",
    { base64: base64 },
    undefined,
    { "Access-Key": accessCode }
  );


