import { ReactNode, useState } from "react";
import { CacheContext, CacheContextType } from "./Context";
import { getAtPath, setAtPath } from "./json";

interface Props {
  children?: ReactNode;
}

export const CacheProvider = ({ children }: Props) => {
  const [cache, setCache] = useState<CacheContextType["cache"]>({});

  const getKey = (key: string, defaultValue?: unknown) => {
    const returnValue = getAtPath(cache, key);
    return returnValue ?? defaultValue;
  };

  const setKey = (key: string, value: unknown) => {
    setCache(setAtPath(cache, value, key));
  };
  const deleteKey = (key: string) => {
    setCache(setAtPath(cache, undefined, key));
  };

  return (
    <CacheContext.Provider value={{ cache, getKey, setKey, deleteKey }}>
      {children}
    </CacheContext.Provider>
  );
};
