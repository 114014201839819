import { ReactNode } from "react";
import ReactSelect, { SingleValue } from "react-select";

export interface SelectOption<ValueType> {
  value?: ValueType;
  label?: ReactNode;
}

export interface SelectProps<ValueType> {
  name?: string;
  options?: SelectOption<ValueType>[];
  defaultValue?: any;
  onChange?: (option: SelectOption<ValueType>) => void;
  placeholder?: ReactNode;
}

export default function Select<ValueType>({
  options,
  defaultValue,
  onChange,
  placeholder,
  name,
}: SelectProps<ValueType>) {
  const onUpdate = (option: SingleValue<SelectOption<ValueType>>) =>
    onChange && onChange(option as SelectOption<ValueType>);

  return (
    <ReactSelect
      name={name}
      options={options}
      defaultValue={options?.find((option) => option.value === defaultValue)}
      placeholder={placeholder}
      onChange={onUpdate}
    />
  );
}
