import { createGlobalStyle } from "styled-components";
import { colors, theme } from "../constants";

const GlobalStyle = createGlobalStyle`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.primary}; /* Color of the thumb */
    border-radius: 6px; /* Rounded corners */ 
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: ${colors.white.gallery};
  }
`;

export default GlobalStyle;
