import { Nullable } from "../../../types/utils";
import { httpPost } from "../../utilities";

export interface CreateInterfaceHttpData {
  station_device_id?: Nullable<number>;
  location_id?: Nullable<number>;
  name?: string;
  description?: string;
  is_active?: boolean;
}

export const createInterfaceHttp = async (data: CreateInterfaceHttpData) =>
  httpPost("/admin/interfaces", data);
