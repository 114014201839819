import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Form from "../../../../shared/components/Form/Form";
import { Header } from "../../../../shared/components/Header";
import {
  FlexColumn,
  FlexItem,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../shared/styled";
import {
  createLocationHttp,
  updateLocationHttp,
  retriveLocationHttp,
  CreateLocationHttpData,
} from "../../../../apis/admin/locations";
import { Loading } from "../../../../shared/components/Loading";

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const LocationManage = () => {
  const { id } = useParams();

  const screenType = id ? "update" : "create";

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateLocationHttpData>({
    name: "",
    city: "",
    description: "",
    tags: "",
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveLocationHttp(id);
      setInitialData({
        name: responseData.name,
        city: responseData.city,
        description: responseData.description,
        tags: responseData.tags,
      });
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateLocationHttpData) => {
    setSubmitting(true);
    try {
      if (screenType === "update") {
        await updateLocationHttp(id as string, data);
      } else {
        await createLocationHttp(data);
      }
      window.history.back();
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    fetchDefaultData();
  }, [fetchDefaultData]);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>LOKACIJE</TitleXXL>
        <TitleXL>{Translations[screenType]?.toUpperCase()} LOKACIJU</TitleXL>
      </FlexColumn>
      <FlexColumn alignItems="center">
        {!isLoading && (
          <Form<CreateLocationHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>Naziv</Title>,
                type: "column",
                field: {
                  name: "name",
                  type: "text",
                  placeholder: "Unesite naziv...",
                  options: {
                    required: "Naziv je potreban!",
                  },
                },
              },
              {
                label: <Title>Grad</Title>,
                type: "column",
                field: {
                  name: "city",
                  type: "text",
                  placeholder: "Unesite grad...",
                  options: {
                    required: "Grad je potreban!",
                  },
                },
              },
              {
                label: <Title>Opis</Title>,
                type: "column",
                field: {
                  name: "description",
                  type: "textarea",
                  placeholder: "Unesite opis...",
                },
                style: {
                  input: {
                    dimensions: { height: "128px" },
                  },
                },
              },
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potvrdi</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {isLoading && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
