import { useCallback, useEffect, useState } from "react";

export const useMediaDevice = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>();
  const [hasAccess, setAccess] = useState<boolean>(false);

  const getPermission = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((res) => setAccess(true))
      .catch((err) => setAccess(false));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    },
    [setDevices]
  );

  useEffect(() => {
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasAccess)
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices, hasAccess]);

  return { hasPermission: hasAccess, devices };
};
