import { theme } from "../../../constants";
import { Text } from "../../styled";
import Icon from "../Icon/Icon";

import { NavbarItemStyle } from "./style";
import { NavbarRoute } from "./types";

export interface NavbarItemProps extends Omit<NavbarRoute, "label" | "path"> {
  children?: NavbarRoute["label"];
  isSelected: boolean;
  selectRoute: () => void;
}

export const NavbarItem = ({
  id,
  icon,
  children,
  isSelected,
  selectRoute,
}: NavbarItemProps) => {
  const color = isSelected ? theme.colors.clean : theme.font.color;
  const background = isSelected ? theme.colors.primary : theme.colors.clean;

  return (
    <NavbarItemStyle background={background} onClick={selectRoute}>
      <Icon type={icon} size="24px" color={color} />
      <Text color={color} weight={theme.font.weight.bold}>
        {children}
      </Text>
    </NavbarItemStyle>
  );
};
