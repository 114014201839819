import { Nullable } from "../utils";
import { CardUserInterface } from "./cardUser";
import { GroupInterface } from "./group";
import { LocationInterface } from "./location";

export enum DeviceStatusEnum {
  "No status",
  "Free",
  "Active",
  "Stopping",
  "Inactive",
  "Need Checkup"
}

export const DeviceStatusEnumTranslations = {
  "No status": "Nema statusa",
  "Free": "Slobodna",
  "Active": "Aktivna",
  "Stopping": "Prestaje sa radom",
  "Inactive": "Neaktivna",
  "Need Checkup": "Provjera"
}

export interface DeviceInterface {
  id: number;
  location?: Nullable<LocationInterface>;
  location_id: Nullable<number>;
  card_user?: Nullable<CardUserInterface>;
  card_user_id?: Nullable<number>;
  group_id?: Nullable<number>;
  group?: Nullable<GroupInterface>;
  created_at: string;
  code: string;
  mac_address: string;
  status: number;
  tags: string;
  last_status_change_id: Nullable<number>;
  is_deleted: boolean;
  name?: string;
  inventure_number?: string;
  manufacturer?: string;
}