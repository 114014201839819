import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Form from "../../../../shared/components/Form/Form";
import { Header } from "../../../../shared/components/Header";
import {
  FlexColumn,
  FlexItem,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../shared/styled";
import {
  CreateIssueHttpData,
  createIssueHttp,
  updateIssueHttp,
  retriveIssueHttp
} from "../../../../apis/admin/issues";
import { Loading } from "../../../../shared/components/Loading";

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const ManageIssues = () => {
  const { id } = useParams();

  const screenType = id ? "update" : "create";

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateIssueHttpData>({
    name: "",
    description: "",
    tags: "",
    is_active: false,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveIssueHttp(id);
      setInitialData({
        name: responseData.name,
        description: responseData.description,
        tags: responseData.tags,
        is_active: responseData.is_active,
      });
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateIssueHttpData) => {
    setSubmitting(true);
    try {
      if (screenType === "create") {
        await createIssueHttp({ ...data, is_active: true });
      } else {
        await updateIssueHttp(id as string, data);
      }
      window.history.back();
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    fetchDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>TIPOVI PROBLEMA</TitleXXL>
        <TitleXL>{Translations[screenType]?.toUpperCase()} TIP PROBLEMA</TitleXL>
      </FlexColumn>
      <FlexColumn alignItems="center">
        {!isLoading && (
          <Form<CreateIssueHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>Naziv</Title>,
                type: "column",
                field: {
                  name: "name",
                  type: "text",
                  placeholder: "Unesite naziv...",
                  options: {
                    required: "Naziv je potreban!",
                  },
                },
              },
              {
                label: <Title>Opis</Title>,
                type: "column",
                field: {
                  name: "description",
                  type: "textarea",
                  placeholder: "Unesite opis...",
                },
                style: {
                  input: {
                    dimensions: { height: "128px" },
                  },
                },
              },
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potvrdi</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {(isLoading) && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
