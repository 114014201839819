import Input from "../../shared/components/Input/Input";
import Label from "../../shared/components/Label/Label";
import { Title } from "../../shared/styled";

export default function InputLabelDev() {
  return (
    <>
      <Label label={<Title>Input with label</Title>}>
        <Input placeholder="This is labeled input..." />
      </Label>
    </>
  );
}
