import {
  CardUserInterface,
  DeviceInterface,
  GroupInterface,
} from "../../types/models";
import { httpGet } from "../utilities";

export interface GetGroupLeadInterfaceHttpResponse {
  card_user: CardUserInterface;
  group: GroupInterface;
  devices: DeviceInterface[];
  card_users: CardUserInterface[];
}

export const getGroupLeadInterfaceHttp = async (
  interfaceAccessCode: string,
  cardCode: string
) =>
  httpGet<GetGroupLeadInterfaceHttpResponse>(
    "/interface/devices/lead/manage/",
    {},
    { "Access-Key": interfaceAccessCode, "Card-Code": cardCode }
  );
