import { AppRoute } from "../../types/route";
import { CardUserManage, CardUsersList } from "./screens/access/cardUsers";
import { InterfaceManage, InterfacesList } from "./screens/access/interfaces";
import { StationList, StationManage } from "./screens/access/stations";
import { UserCreate, UsersList } from "./screens/access/users";
import { LocationList, LocationManage } from "./screens/locations";
import { GroupList, ManageGroups } from "./screens/groups";
import { MachineList, ManageMachines } from "./screens/machines";
import { IssuesList, ManageIssues } from "./screens/issues";
import { DeviceStatistics } from "./screens/analytics";
import { MechanicsStatistics } from "./screens/analytics/MechanicsStatistics";

export const ADMIN_PANEL_ROUTES: AppRoute[] = [
  //Users
  {
    id: "admin-access-users",
    path: "/admin/access/users/list",
    component: <UsersList />,
  },
  {
    id: "admin-access-users",
    path: "/admin/access/users/create",
    component: <UserCreate />,
  },
  // Card Usrs
  {
    id: 'admin-access-card-users',
    path: "/admin/access/card-users/list",
    component: <CardUsersList />
  },
  {
    id: 'admin-access-card-users',
    path: "/admin/access/card-users/create",
    component: <CardUserManage />
  },
  {
    id: 'admin-access-card-users',
    path: "/admin/access/card-users/:id",
    component: <CardUserManage />
  },
  // Stations
  {
    id: 'admin-access-stations',
    path: "/admin/access/stations/list",
    component: <StationList />
  },
  {
    id: 'admin-access-stations',
    path: "/admin/access/stations/create",
    component: <StationManage />
  },
  {
    id: 'admin-access-stations',
    path: "/admin/access/stations/:id",
    component: <StationManage />
  },
  // Interfaces 
  {
    id: 'admin-access-interfaces',
    path: "/admin/access/interfaces/list",
    component: <InterfacesList />
  },
  {
    id: 'admin-access-interfaces',
    path: "/admin/access/interfaces/create",
    component: <InterfaceManage />
  },
  {
    id: 'admin-access-interfaces',
    path: "/admin/access/interfaces/:id",
    component: <InterfaceManage />
  },
  // Locations
  {
    id: 'admin-locations',
    path: "/admin/locations",
    component: <LocationList />
  },
  {
    id: 'admin-locations',
    path: "/admin/location/create",
    component: <LocationManage />
  },
  {
    id: 'admin-locations',
    path: "/admin/location/:id",
    component: <LocationManage />
  },
  //Groups
  {
    id: "admin-access-groups",
    path: "/admin/groups",
    component: <GroupList />,
  },
  {
    id: "admin-access-groups",
    path: "/admin/group/create",
    component: <ManageGroups />,
  },
  {
    id: "admin-access-groups",
    path: "/admin/groupe/:id",
    component: <ManageGroups />,
  },
  //Machines
  {
    id: "admin-machines",
    path: "/admin/machines",
    component: <MachineList />,
  },
  {
    id: "admin-machines",
    path: "/admin/machine/create",
    component: <ManageMachines />,
  },
  {
    id: "admin-machines",
    path: "/admin/machine/:id",
    component: <ManageMachines />,
  },
  //Issues
  {
    id: "admin-issues",
    path: "/admin/issues",
    component: <IssuesList />,
  },
  {
    id: "admin-issues",
    path: "/admin/issue/create",
    component: <ManageIssues />,
  },
  {
    id: "admin-issues",
    path: "/admin/issue/:id",
    component: <ManageIssues />,
  },
  // Analytics
  {
    id: 'admin-analytics-machine',
    path: "/admin/analytics/machine",
    component: <DeviceStatistics />
  },
  {
    id: 'admin-analytics-emplyees',
    path: "/admin/analytics/mechanics",
    component: <MechanicsStatistics/>
  },
  // 404
  {
    id: "admin-not-found",
    path: "/admin/*",
    component: <>Not Found</>,
  },
];
