import { Nullable } from "../../../types/utils";
import { httpPost } from "../../utilities";

export interface CreateCardUserHttpData {
  first_name?: string;
  last_name?: string;
  card_code?: string;
  type?: number;

  group_id?: Nullable<number>;
  location_id?: Nullable<number>;

  is_active?: boolean;
  is_mobile_access_active?: boolean;
}

export const createCardUserHttp = async (data: CreateCardUserHttpData) =>
  httpPost("/admin/card-users", data);
