import { useEffect, useState } from "react";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexRow,
  Paragraph,
  Text,
  Title,
  TitleXL,
} from "../../../../../../shared/styled";
import {
  giveStationAccessHttp,
  listStationAccessRequestsHttp,
} from "../../../../../../apis/admin/stations";
import { useModalProvider } from "../../../../../../contexts/ModalProviderContext";
import Label from "../../../../../../shared/components/Label/Label";
import { ConfirmationModal } from "../../../../../../modals";
import { Loading } from "../../../../../../shared/components/Loading";
import { theme } from "../../../../../../constants";

interface ApproveAccessForIpModalProps {
  close?: () => void;
  stationId: number;
}

export const ApproveAccessForIpModal = ({
  close,
  stationId,
}: ApproveAccessForIpModalProps) => {
  const { openModal, closeModal } = useModalProvider();

  const [ipAddresses, setIpAddresses] = useState<string[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchIpAddresses = async () => {
    setLoading(true);
    try {
      const { data } = await listStationAccessRequestsHttp();
      setIpAddresses(data.ip_addresses);
    } catch (err) {
      //TODO Handle error
    }
    setLoading(false);
  };

  const approveAccess = async (ipAddress: string) => {
    if (isSubmitting) return;

    setSubmitting(true);
    try {
      await giveStationAccessHttp(stationId, ipAddress);
      close && close();
    } catch (err) {
      //TODO Handle Error
    }

    setSubmitting(false);
  };

  /* Initiate load */
  useEffect(() => {
    fetchIpAddresses();
  }, []);

  return (
    <FlexColumn
      dimensions={{ minWidth: "256px" }}
      background={theme.colors.clean}
      gap="20px"
      padding="10px"
      border={{ radius: "8px" }}
    >
      <TitleXL>Give access</TitleXL>
      <Paragraph>
        This action will selected ip access as station automatically
      </Paragraph>
      <Label label={<Title>Select ip address</Title>}>
        {!isLoading && (
          <FlexColumn overflow={{ y: "auto" }}>
            {0 < (ipAddresses ?? [])?.length &&
              ipAddresses?.map((ipAddress) => (
                <FlexRow alignItems="center" justifyContent="space-between">
                  <Text>{ipAddress}</Text>
                  <Button
                    disabled={isSubmitting}
                    styleType={ButtonTypes.primary}
                    onClick={() =>
                      openModal("ConfirmStationAccess", ConfirmationModal, {
                        close: () => closeModal("ConfirmStationAccess"),
                        body: (
                          <Paragraph>
                            Are you sure you want to give {ipAddress} access to
                            station?
                          </Paragraph>
                        ),
                        title: <TitleXL>Are you sure?</TitleXL>,
                        submit: () => {
                          approveAccess(ipAddress);
                          closeModal("ConfirmStationAccess");
                        },
                        cancel: () => closeModal("ConfirmStationAccess"),
                      })
                    }
                  >
                    Give access
                  </Button>
                </FlexRow>
              ))}
            {(ipAddresses ?? [])?.length === 0 && (
              <Title>No request found</Title>
            )}
          </FlexColumn>
        )}
        {isLoading && <Loading />}
      </Label>

      <FlexRow justifyContent="flex-end">
        <Button
          styleType={ButtonTypes.primaryReverse}
          onClick={() => close && close()}
        >
          Close
        </Button>
      </FlexRow>
    </FlexColumn>
  );
};
