import { ReactNode, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "./Context";
import { authHealthHttp, loginHttp } from "../../apis/auth";
import { Loading } from "../../shared/components/Loading";
import { FlexColumn } from "../../shared/styled";
import { useNavigate } from "react-router";

interface Props {
  children?: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<AuthContextType["isLoading"]>(true);
  const [user, setUser] = useState<AuthContextType["authUser"]>();
  const [hasAccess, setAccess] = useState<boolean>(false);

  const login = async (
    username: string,
    password: string,
    shouldSave = true
  ) => {
    setLoading(true);
    try {
      const { data } = await loginHttp(username, password);
      if (shouldSave) localStorage.setItem("jwt-access", data.access);
      setAccess(true);
      navigate("/admin/");
    } catch (err) {
      //TODO Handle this better
      console.log("loginHttp", err);
    }
    setLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("jwt-access");
    setAccess(false);
    setUser(null);
  };

  const initAuth = async () => {
    setLoading(true);
    const accessToken = localStorage.getItem("jwt-access");
    if (!accessToken) {
      setAccess(false);
      setLoading(false);
      return;
    }

    try {
      await authHealthHttp();
      setAccess(true);
    } catch (err) {
      setAccess(false);
    }
    setLoading(false);
  };

  // TODO Save current user
  const getCurrentUser = async () => { };

  useEffect(() => {
    initAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        hasAccess,
        authUser: user,
        login,
        logout,
        getCurrentUser,
        isLoading,
      }}
    >
      {isLoading && <>
        <FlexColumn
          dimensions={{ width: "100%", height: "100vh" }}
          alignItems="center"
          justifyContent="center" >
          <Loading loading={true} color="#F26522" size="60px" />
        </FlexColumn>
      </>}
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
