import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CHARSET_CLETTERS,
  CHARSET_LLETTERS,
  randomString,
} from "../utilities/random";

type KeyPressHandlerType = (keyPressed?: { [key: string]: boolean }) => void;

interface GlobalEventContextInterface {
  mouseClick: number;
  keyPressed: { [key: string]: boolean };
  setKeyPressHandler?: (handler: KeyPressHandlerType | undefined) => void;
}

const GlobalEventContext = createContext<GlobalEventContextInterface>({
  mouseClick: 0,
  keyPressed: {},
});

interface GlobalEventProviderInterface {
  children?: React.ReactNode;
}

export function GlobalEventProvider({
  children,
}: GlobalEventProviderInterface) {
  const [mouseClick, setMouseClick] =
    useState<GlobalEventContextInterface["mouseClick"]>(0);

  const [keyUpdateId, setKeyUpdateId] = useState<string>("");
  const [keyPressed, setKeyPressed] = useState<
    GlobalEventContextInterface["keyPressed"]
  >({});

  const keyPressHandler = useRef<KeyPressHandlerType | undefined>();

  useEffect(() => {
    keyPressHandler?.current && keyPressHandler?.current(keyPressed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyUpdateId]);

  useEffect(() => {
    // Events for key triggers
    let initialKeyPressed = keyPressed;
    const pressKeyHandler = (event: globalThis.KeyboardEvent) => {
      initialKeyPressed[event?.key] = true;
      setKeyPressed(initialKeyPressed);

      setKeyUpdateId(randomString(`${CHARSET_CLETTERS}${CHARSET_LLETTERS}`, 8));
    };

    const realiseKeyHandler = (event: globalThis.KeyboardEvent) => {
      initialKeyPressed[event?.key] = false;
      setKeyPressed(initialKeyPressed);

      setKeyUpdateId(randomString(`${CHARSET_CLETTERS}${CHARSET_LLETTERS}`, 8));
    };

    window.addEventListener("keydown", pressKeyHandler);
    window.addEventListener("keyup", realiseKeyHandler);

    // Events for mouse click
    let initialMouseState = mouseClick;
    const mouseClickHandler = (event?: globalThis.MouseEvent) =>
      setMouseClick(++initialMouseState);

    window.addEventListener("click", mouseClickHandler);

    return () => {
      window.removeEventListener("click", mouseClickHandler);

      window.removeEventListener("keydown", pressKeyHandler);
      window.removeEventListener("keyup", realiseKeyHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalEventContext.Provider
      value={{
        mouseClick,
        keyPressed,
        setKeyPressHandler: (keys) => (keyPressHandler.current = keys),
      }}
    >
      {children}
    </GlobalEventContext.Provider>
  );
}

export function useGlobalEvents() {
  return useContext(GlobalEventContext);
}
