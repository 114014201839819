import { httpPut } from "../../utilities";

export interface UpdateMachineHttpData {
  location_id?: number | null;
  group_id?: number | null;
  mac_address?: string;
  code?: string;
  card_user_id?: number | null;
  manufacturer?: string;
  name?: string;
  inventure_number?: string;
};

export const updateDeviceHttp = async (id: number | string, data: UpdateMachineHttpData) =>
  httpPut(`/admin/devices/${id}`, data);