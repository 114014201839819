import { PaginationResponse } from "../../../types";
import { LocationInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export interface ListLocationsHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  city__in?: string[];
  name__in?: string[];
  tags__icontains?: string[]
}

export const listLocationsHttp = async (
  offset: number,
  limit: number,
  filters: ListLocationsHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<LocationInterface>>("/admin/locations", {
    ...filters,
    offset,
    limit,
    ordering,
  });
