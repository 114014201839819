import CSS from "csstype";
import { styled } from "styled-components";

import {
  BackgroundStyling,
  BackgroundStylingProps,
  BorderStyling,
  BorderStylingProps,
  DimensionStyling,
  DimensionStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../styling";

interface ImageProps {
  dimensions?: DimensionStylingProps;
  border?: BorderStylingProps | CSS.Property.Border;
  background?: BackgroundStylingProps | CSS.Property.Background;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  cursor?: CSS.Property.Cursor;
}

export const Image = styled.img<ImageProps>`
  ${({ dimensions }) => DimensionStyling(dimensions)};
  ${({ border }) => BorderStyling(border)};
  ${({ background }) => BackgroundStyling(background)};
  ${({ padding }) => PaddingStyling(padding)};

  cursor: ${({ cursor }) => cursor};
`;
