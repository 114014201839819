import { ReactNode } from "react";
import { theme } from "../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexColumnProps,
  FlexRow,
  TitleXL,
} from "../shared/styled";
import Icon from "../shared/components/Icon/Icon";
import { IconTypes } from "../shared/components/Icon";

interface ConfirmationModalProps {
  style?: FlexColumnProps;
  body?: ReactNode;
  title: string;
  submit?: () => void;
  cancel?: () => void;
  close?: () => void;
}

export const ConfirmationModal = ({
  style,
  close,
  title,
  body,
  submit,
  cancel,
}: ConfirmationModalProps) => {
  return (
    <FlexColumn
      background={theme.colors.clean}
      padding="10px"
      gap="20px"
      border={{ radius: "8px" }}
      {...style}
    >
      <FlexColumn dimensions={{ width: "100%" }} gap="5px">
        {close && (
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Icon
              type={IconTypes.close}
              size="32px"
              color={theme.font.color}
              onClick={() => close()}
            />
          </FlexRow>
        )}
        {title &&
          (typeof title === "string" ? (
            <FlexRow
              dimensions={{ width: "100%" }}
              justifyContent="flex-start"
              alignItems="center"
            >
              <TitleXL>{title}</TitleXL>
            </FlexRow>
          ) : (
            title
          ))}

        {body}
        <FlexRow
          dimensions={{ width: "100%" }}
          justifyContent="flex-end"
          gap="10px"
          alignItems="center"
        >
          {cancel && (
            <Button onClick={cancel} styleType={ButtonTypes.primaryReverse}>
              <TitleXL color={theme.colors.primary}>Otkazi</TitleXL>
            </Button>
          )}
          {submit && (
            <Button onClick={submit} styleType={ButtonTypes.primary}>
              <TitleXL color={theme.colors.clean}>Potvrdi</TitleXL>
            </Button>
          )}
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};
