import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";

import { FormLayout } from "./types/formLayout";

import { FlexColumn, FlexRow, Text } from "../../styled";
import Label from "../Label/Label";
import { theme } from "../../../constants";
import { InputComponents } from "./inputTypes";

export function renderLayout<DataSchema extends FieldValues>(
  layoutItems: FormLayout[],
  register: UseFormRegister<DataSchema>,
  errors: FieldErrors<DataSchema>,
  defaultValues?: DataSchema,
  interator: number = 0
) {
  return layoutItems.map((layoutItem, index) => {
    const RenderComponent = layoutItem.type === "row" ? FlexRow : FlexColumn;
    const InputComponent = layoutItem?.field
      ? InputComponents[layoutItem?.field?.type]?.component
      : undefined;
    const PropsToInclude = layoutItem?.field
      ? InputComponents[layoutItem?.field?.type]?.props
      : [];
    const PropsToExclude = layoutItem?.field
      ? InputComponents[layoutItem?.field?.type]?.excludeProps
      : [];

    const OriginalInputProps: Record<string, unknown> = layoutItem?.field
      ? {
          type: layoutItem?.field?.type,
          style: layoutItem?.style?.input,
          defaultChecked: defaultValues?.[layoutItem.field.name],
          defaultValue: defaultValues?.[layoutItem.field.name],
          options: layoutItem?.field?.selectOptions || [],
          placeholder: layoutItem?.field?.placeholder,
          ...register(
            layoutItem?.field?.name as Path<DataSchema>,
            layoutItem?.field?.options
          ),
        }
      : {};

    const InputProps = Object.keys(OriginalInputProps)
      .filter(
        (key) =>
          (PropsToInclude.includes(key) || PropsToInclude === "*") &&
          !PropsToExclude?.includes(key)
      )
      .reduce((obj, key) => {
        obj[key] = OriginalInputProps[key];
        return obj;
      }, {} as Record<string, unknown>);

    return (
      <RenderComponent
        key={`renderComponentWrapper${interator},${index}`}
        flexWrap="wrap"
        {...layoutItem.style?.cell}
      >
        {!layoutItem?.field && layoutItem.label}
        {layoutItem?.field && InputComponent && (
          <Label
            key={`renderComponentLabel${interator},${index}`}
            style={layoutItem?.style?.label}
            label={layoutItem.label}
            direction={layoutItem?.type}
          >
            <InputComponent
              key={`renderComponentInput${interator},${index}`}
              {...InputProps}
            />
            {errors?.[layoutItem.field.name] && (
              <Text
                key={`renderComponentError${interator},${index}-${layoutItem.field.name}`}
                color={theme.colors.danger}
              >
                {errors?.[layoutItem.field.name]?.message?.toString()}
              </Text>
            )}
          </Label>
        )}
        {layoutItem.children &&
          renderLayout(
            layoutItem.children,
            register,
            errors,
            defaultValues,
            interator + 1
          )}
      </RenderComponent>
    );
  });
}
