import {
  ReactNode,
  SelectHTMLAttributes,
  OptionHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  OptionStyle,
  OptionStyleProps,
  SelectStyle,
  SelectStyleProps,
} from "./style";
import { Loading } from "../Loading";

export interface SelectOption {
  value?: OptionHTMLAttributes<HTMLOptionElement>["value"];
  label?: ReactNode;
  disabled?: boolean;
}

export interface SelectPropsStyle extends SelectStyleProps {
  style?: SelectHTMLAttributes<HTMLSelectElement>["style"];
}

export interface OptionPropsStyle extends OptionStyleProps {
  style?: OptionHTMLAttributes<HTMLOptionElement>["style"];
}

export interface SelectProps
  extends Omit<
    SelectHTMLAttributes<HTMLSelectElement>,
    "style" | "placeholder"
  > {
  placeholder?: any;
  options?: SelectOption[];
  style?: {
    select?: SelectPropsStyle;
    option?: OptionPropsStyle;
  };
  fetch?: () => SelectOption[] | Promise<SelectOption[]>;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, style, fetch,placeholder, ...props }, ref) => {
    const [renderOptions, setRenderOptions] = useState<SelectOption[]>(
      options ?? []
    );
    const [isLoading, setLoading] = useState<boolean>(true);

    const fetchOptions = useCallback(async () => {
      setLoading(true);
      if (fetch) {
        try {
          setRenderOptions(await fetch());
        } catch (err) {
          console.log("Select.fetch", err);
        }
      }
      setLoading(false);
    }, [fetch]);

    useEffect(() => {
      fetchOptions();
    }, [fetchOptions]);

    return (
      <SelectStyle {...style?.select} {...props} ref={ref}>
        {isLoading && <Loading />}

        {!isLoading &&
          renderOptions?.map((option, index) => (
            <OptionStyle
              key={index}
              value={option?.value}
              disabled={option?.disabled}
            >
              {option?.label}
            </OptionStyle>
          ))}
      </SelectStyle>
    );
  }
);

Select.displayName = "Select";
export default Select;
