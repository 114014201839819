import { Loading } from "../../shared/components/Loading";
import { FlexColumn } from "../../shared/styled";


export const LoadingComp = () => {
  return (
    <FlexColumn
      dimensions={{ width: "100%", height: "100vh" }}
      alignItems="center"
      justifyContent="center" >
      <Loading loading={true} color="#F26522" size="60px" />
    </FlexColumn>
  )
}