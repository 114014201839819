import { ReactNode } from "react";

import {
  FlexContainer,
  FlexContainerProps,
  FlexRow,
  FlexRowProps,
} from "../../styled";

export interface LabelInputStylingProps {
  wrapper?: FlexContainerProps;
  label?: FlexRowProps;
}

export interface LabaledInputProps {
  label?: ReactNode;
  style?: LabelInputStylingProps;
  children?: ReactNode;
  direction?: "row" | "column";
}

export default function Label({
  label,
  style,
  direction = "column",
  children,
}: LabaledInputProps) {
  return (
    <FlexContainer
      flexDirection={direction}
      gap="10px"
      {...style?.wrapper}
    >
      <FlexRow {...style?.label}>{label}</FlexRow>
      {children}
    </FlexContainer>
  );
}
