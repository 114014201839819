import type CSS from "csstype";
import { ReactNode, useCallback } from "react";
import { Button, FlexColumn, FlexRow, Text, Title } from "../../styled";
import { theme } from "../../../constants";
import { IconTypes } from "../Icon/types";
import { valueTypes } from "./valueTypes";
import Icon from "../Icon/Icon";
import { TablePagination, TableSchemaItem } from "./types";
import { Loading } from "../Loading";
import Select from "../Select/Select";
import { PaginationLimitOptions } from "./paginationLimitOptions";

export interface TableStyling {
  cellGap?: CSS.Property.Gap;
}

export interface TableProps<DataType> {
  data: DataType[];

  schema: TableSchemaItem<DataType>[];
  title?: ReactNode;

  ordering?: string[];
  setOrdering?: (updatedOrdering: string[]) => void;

  pagination?: TablePagination;
  setPagination?: (updatedPagination: TablePagination) => void;

  isLoading?: boolean;
}

export default function Table<DataType>({
  data,
  schema,
  title,
  isLoading,
  setOrdering,
  setPagination,
  ordering,
  pagination,
}: TableProps<DataType>) {
  const addOrder = useCallback(
    (orderItem: string) => {
      if (!ordering || !setOrdering) return;
      const newOrdering = (() => {
        const includesOrderItem =
          ordering?.includes(`-${orderItem}`) || ordering?.includes(orderItem);

        if (!includesOrderItem) return [...ordering, orderItem];

        return [
          ...ordering?.filter(
            (item) => item !== orderItem && item !== `-${orderItem}`
          ),
          ...(ordering?.includes(orderItem) ? [`-${orderItem}`] : []),
        ];
      })();

      setOrdering(newOrdering);
    },
    [ordering, setOrdering]
  );

  const cells = schema?.map((item) => ({
    label: item?.label,
    attribute: item?.attribute,
    orderAttribute: item?.order,
    width: item?.width,
    flex: item?.flex,
  }));

  const hasTitle = title ? true : false;

  const totalPages =
    Math.round((pagination?.count || 0) / (pagination?.limit || 1)) +
    ((pagination?.count || 0) % (pagination?.limit || 1) === 0 ? 0 : 1);
  const currentPage = Math.round(
    (pagination?.offset || 0) / (pagination?.limit || 1)
  ) + 1;

  const previusDisabled = 0 >= currentPage - 1;
  const nextDisabled = currentPage + 1 > totalPages;

  return (
    <FlexColumn>
      {hasTitle && typeof title === "string" && (
        <FlexRow>
          <Title size={`${(theme.font.size.xl + theme.font.size.xxl) / 2}px`}>
            {title}
          </Title>
        </FlexRow>
      )}
      {hasTitle && typeof title !== "string" && title}

      <FlexColumn overflow={{ x: "auto" }}>
        <FlexRow
          background={theme.colors.clean}
          style={{ top: "0", left: "0" }}
          dimensions={{ width: "max-content", minWidth: "100%" }}
          border={{
            top: `solid 0.5px ${theme.font.color}`,
            bottom: `solid 0.5px ${theme.font.color}`,
          }}
        >
          {cells?.map((cellItem) => {
            const hasOrder = cellItem?.orderAttribute ? true : false;
            const shouldRenderCarrot =
              hasOrder &&
              (ordering?.includes(`-${cellItem?.orderAttribute}`) ||
                ordering?.includes(`${cellItem?.orderAttribute}`));

            const isLabelSimple = typeof cellItem?.label === "string";

            return (
              <FlexRow
                dimensions={{
                  height: "45px",
                  width: cellItem?.width,
                  flex: cellItem?.flex,
                }}
                justifyContent="flex-start"
                alignItems="center"
                gap="10px"
                onClick={() =>
                  !isLoading &&
                  hasOrder &&
                  addOrder(`${cellItem?.orderAttribute}`)
                }
                cursor={hasOrder ? "pointer" : undefined}
                shrink={0}
                grow={0}
              >
                {/* Label Rendering */}
                {isLabelSimple && (
                  <Text
                    userSelect="none"
                    weight={theme.font.weight.bold}
                    color={theme.font.color}
                    size={`${theme.font.size.x}px`}
                  >
                    {cellItem?.label}
                  </Text>
                )}
                {!isLabelSimple && cellItem?.label}

                {/* Order carrot rendering */}
                {shouldRenderCarrot && (
                  <Icon
                    style={
                      ordering?.includes(`-${cellItem?.orderAttribute}`)
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                    type={IconTypes.carrot}
                    size="16px"
                    color={theme.font.color}
                  />
                )}
              </FlexRow>
            );
          })}
        </FlexRow>
        {/* Data Renderer */}

        {/* Render data when there is data to render */}
        {!isLoading && 0 < data?.length && (
          <FlexColumn>
            {data?.map((item, index) => {
              return (
                <FlexRow
                  dimensions={{ width: "max-content", minWidth: "100%" }}
                  border={
                    index !== data?.length - 1
                      ? {
                        bottom: `solid 0.5px ${theme.font.color}`,
                      }
                      : {}
                  }
                >
                  {schema?.map((schemaItem) => {
                    const value = (() => {
                      if (!schemaItem?.attribute && schemaItem?.mapper)
                        return schemaItem?.mapper(item);
                      if (schemaItem?.attribute) {
                        return schemaItem?.mapper
                          ? schemaItem?.mapper(item)
                          : valueTypes(item?.[schemaItem?.attribute] as any);
                      }

                      return "ATTRIBUTE MISSING";
                    })();
                    return (
                      <FlexColumn
                        dimensions={{ width: schemaItem?.width, height: "56px", flex: schemaItem?.flex }}
                        justifyContent="center"
                        alignItems="flex-start"
                        grow={0}
                        shrink={0}
                      >
                        {schemaItem?.prefix}
                        {typeof value === "string" ? (
                          <Text
                            size={`${theme.font.size.x}px`}
                            color={theme.font.color}
                          >
                            {value}
                          </Text>
                        ) : (
                          value
                        )}
                        {schemaItem?.sufix}
                      </FlexColumn>
                    );
                  })}
                </FlexRow>
              );
            })}
          </FlexColumn>
        )}
      </FlexColumn>
      {/* Render empty table message */}
      {!isLoading && data?.length === 0 && (
        <FlexColumn
          dimensions={{ height: "128px" }}
          justifyContent="center"
          alignItems="center"
        >
          <Text>Table is empty</Text>
        </FlexColumn>
      )}

      {/* Render Loading component in loading state */}
      {isLoading && (
        <FlexColumn
          dimensions={{ height: "128px" }}
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </FlexColumn>
      )}

      {/* Pagination controle */}
      {pagination && setPagination && (
        <FlexRow
          dimensions={{ width: "100%" }}
          padding="15px"
          justifyContent="space-between"
          alignItems="center"
          position="sticky"
          style={{ bottom: "0", left: "0" }}
          background={theme.colors.clean}
          border={{ top: `solid 0.5px ${theme.font.color}` }}
        >
          <Select
            defaultValue={pagination?.limit}
            options={PaginationLimitOptions}
            disabled={isLoading}
            onChange={(event) =>
              setPagination({ ...pagination, limit: +event?.target?.value })
            }
          />
          <FlexRow alignItems="center" gap="10px">
            {pagination?.count && <Text>Total: {pagination?.count}</Text>}
            <Button
              disabled={isLoading || previusDisabled}
              onClick={() =>
                setPagination({
                  ...pagination,
                  offset: (pagination?.offset || 0) - (pagination?.limit || 0),
                })
              }
            >
              <Icon
                style={{ transform: "rotate(-90deg)" }}
                type={IconTypes.carrot}
                size="24px"
                color={theme.font.color}
              />
            </Button>
            <Text>
              {currentPage} / {totalPages}
            </Text>
            <Button
              disabled={isLoading || nextDisabled}
              onClick={() =>
                setPagination({
                  ...pagination,
                  offset: (pagination?.offset || 0) + (pagination?.limit || 0),
                })
              }
            >
              <Icon
                /* { transform: "rotate(180deg)" } */
                style={{ transform: "rotate(90deg)" }}
                type={IconTypes.carrot}
                size="24px"
                color={theme.font.color}
              />
            </Button>
          </FlexRow>
        </FlexRow>
      )}
    </FlexColumn>
  );
}
