import { Nullable } from "../../../types/utils";
import { httpPut } from "../../utilities";

export interface UpdateInterfaceHttpData {
  station_device_id?: Nullable<number>;
  location_id?: Nullable<number>;
  name?: string;
  description?: string;
  is_active?: boolean;
}

export const updateInterfaceHttp = async (id: number|string, data: UpdateInterfaceHttpData) =>
  httpPut(`/admin/interfaces/${id}`, data);
