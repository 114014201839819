import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

const MODAL_START_Z_INDEX = 1000;

type ModalPostionType = "top" | "left" | "right" | "bottom" | "center";

const ModalContent = styled.div<{ position?: ModalPostionType }>`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: ${({ position }) =>
    !position || ["left", "right"].includes(position)
      ? "center"
      : position === "top"
        ? "flex-start"
        : "flex-end"};
  align-items: ${({ position }) =>
    !position || ["top", "bottom"].includes(position)
      ? "center"
      : position === "left"
        ? "flex-start"
        : "flex-end"};

  width: 100%;
  height: 100%;

  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  left: 0;
  top: 0;
`;

const ModalWrapper = styled.div<{ zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  z-index: ${({ zIndex }) => MODAL_START_Z_INDEX + (zIndex ?? 0)};
`;

type ContextType = {
  openModal: (
    key: string,
    modalComponent: React.FC<any>,
    props: any,
    config?: ActiveModalConfig
  ) => void;
  updateProps: (key: string, props: any) => void;
  closeModal: (key: string) => void;
};

const initialState: ContextType = {
  openModal: () => { },
  updateProps: () => { },
  closeModal: () => { },
};

const ModalPropviderContext = React.createContext(initialState);
export const useModalProvider = () => React.useContext(ModalPropviderContext);

interface ActiveModalConfig {
  position: ModalPostionType;
}

interface ActiveModals {
  [key: string]:
  | { component: React.FC; props: any; config?: ActiveModalConfig }
  | undefined;
}

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeModals, setActiveModals] = React.useState<ActiveModals>({});

  const openModal = (
    key: string,
    modalComponent: React.FC,
    props: any,
    config?: ActiveModalConfig
  ) => {
    setActiveModals({
      ...activeModals,
      [key]: { component: modalComponent, props: props, config: config },
    });
  };

  const updateProps = (key: string, props: any, config?: ActiveModalConfig) => {
    if (!activeModals?.[key]) return;

    setActiveModals({
      ...activeModals,
      [key]: {
        component: activeModals?.[key]?.component as React.FC,
        props: {
          ...activeModals?.[key]?.props,
          ...props,
        },
        config: config,
      },
    });
  };

  const closeModal = useCallback(
    (key: string) => {
      setActiveModals((prevActiveModals) => {
        const updatedActiveModals = { ...prevActiveModals };
        delete updatedActiveModals[key];
        return updatedActiveModals;
      });
    },
    [setActiveModals]
  );

  // adding event listener to close the topmost modal on ESC button
  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape' && Object.keys(activeModals).length > 0) {
        const modalsArray = Object.keys(activeModals);
        closeModal(modalsArray[modalsArray.length - 1]);
      }
    };

    document.addEventListener('keydown', close);

    return () => {
      document.removeEventListener('keydown', close);
    };
  }, [activeModals, closeModal]);

  return (
    <ModalPropviderContext.Provider
      value={{ openModal, updateProps, closeModal }}
    >
      {children}
      {activeModals &&
        Object.keys(activeModals).map((key: string, index: number) => {
          return activeModals[key] ? (
            <ModalWrapper key={key} zIndex={index}>
              <ModalBackground onClick={() => closeModal(key)} />
              <ModalContent position={activeModals?.[key]?.config?.position}>
                {React.createElement(
                  activeModals?.[key]?.component as React.FC,
                  {
                    ...activeModals?.[key]?.props,
                    close: () => closeModal(key),
                  }
                )}
              </ModalContent>
            </ModalWrapper>
          ) : (
            <></>
          );
        })}
    </ModalPropviderContext.Provider>
  );
};

export interface ModalParentInterface {
  close(): void;
}
