import type CSS from "csstype";
import styled from "styled-components";

import { theme } from "../../../constants";
import {
  BorderStyling,
  BorderStylingProps,
  DimensionStyling,
  DimensionStylingProps,
  MarginStyling,
  MarginStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../styling";


interface BoxProps {
  dimensions?: DimensionStylingProps;
  padding?: CSS.Property.Padding | PaddingStylingProps;
  margin?: CSS.Property.Margin | MarginStylingProps;
  border?: CSS.Property.Border | BorderStylingProps;
}


export const Box = styled.div<BoxProps>`
  ${({ dimensions }) => DimensionStyling({ ...dimensions })};
  ${({ padding }) => PaddingStyling(padding)};
  ${({ margin }) => MarginStyling(margin)};
  ${({ border }) => BorderStyling(border)}
  box-shadow: 0 0 6px ${theme.shadow.color};
`;
