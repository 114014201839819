import { PaginationResponse } from "../../../types";
import { CardUserInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export const CARD_USERS_LIST_API = "/admin/card-users";

export interface ListCardUsersHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  type__exact?: number;
  type__in?: number[];
  group_id__in?: number[];
  group_id__exact?: number;
  location_id__in?: number[];
  location_id__exact?: number;
  group_id?: number;
  location_id?: number;
}

export const listCardUsersHttp = async (
  offset: number,
  limit: number,
  filters: ListCardUsersHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<CardUserInterface>>("/admin/card-users", {
    ...filters,
    offset,
    limit,
    ordering,
  });
