import { useCallback, useEffect, useRef, useState } from "react";
import { useCacheProvider } from "../../contexts/Caching";
import { FlexColumn, Image, TitleXL, TitleXXL } from "../../shared/styled";
import { useNavigate } from "react-router";
import {
  inputDeviceKey,
  interfaceAccessKey,
  interfaceCardUserKey,
} from "./cacheKeys";
import {
  CameraImageCapture,
  useMediaDevice,
} from "../../shared/components/CameraImageCapture";
import { useStateRef } from "../../hooks";
import Select from "../../shared/components/Select/Select";
import { cardReaderHttp } from "../../apis/interface/cardReaderHttp";
import { theme } from "../../constants";
import { CardUserTypesEnum } from "../../types/models";

export default function InterfaceInput() {
  const navigate = useNavigate();
  const { setKey, getKey, deleteKey } = useCacheProvider();

  const { hasPermission, devices } = useMediaDevice();
  const {
    set: setLoading,
    refValue: isLoadingRef,
    stateValue: isLoadingState,
  } = useStateRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const [currentDevice, setCurrentDevice] = useState<string | undefined>(
    localStorage.getItem(inputDeviceKey) ?? undefined
  );
  const [accessKey, setAccessKey] = useState<string>();

  const [isInputing, setInputing] = useState<boolean>(false);

  const [counter, setCounter] = useState<number>(0);

  const getCameraBase64 = (base64Data: string | null) => {
    if (!base64Data) return;
    getCardUser(base64Data);
  };

  const getCardUser = useCallback(
    async (base64Data: string) => {
      if (!accessKey || isLoadingRef) return;

      setLoading(true);
      try {
        const { data: cardUser } = await cardReaderHttp(accessKey, base64Data);
        console.log("cardUser", cardUser);

        timeoutRef.current && clearTimeout(timeoutRef.current);

        setKey(interfaceCardUserKey, cardUser);

        if (cardUser?.type === CardUserTypesEnum.Employee) {
          navigate("/interface/employee");
          return;
        }

        if (cardUser?.type === CardUserTypesEnum.GroupLead) {
          navigate("/interface/group-lead");
          return;
        }

        if (cardUser?.type === CardUserTypesEnum.Mechanic) {
          navigate("/interface/mechanic");
          return;
        }

        //TODO Hande action based on cardUserType
      } catch (err) {
        //TODO Handle error
        // console.log(err);
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingState, accessKey]
  );

  const getAccessKey = () => {
    const cachedAccessKey = getKey(interfaceAccessKey, undefined);
    if (!cachedAccessKey) navigate("/interface");

    setAccessKey(cachedAccessKey as string);
  };

  const deauth = useCallback(() => {
    localStorage.removeItem("interfaceAccessKey");
    deleteKey(interfaceAccessKey);
    navigate('/interface')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    getAccessKey();

    return () => {
      setLoading(false);
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (0 < counter && counter % 10 === 0) deauth();
  }, [counter, deauth]);

  return (
    <>
      {hasPermission && (
        <>
          {devices && (
            <FlexColumn
              position="fixed"
              style={{ right: "5px", top: " 5px", zIndex: 2 }}
              dimensions={{ width: "max-content", height: "max-content" }}
            >
              <Select
                options={[
                  { value: -1, label: "Select device" },
                  ...devices?.map((device) => ({
                    value: device.deviceId,
                    label: device.label,
                  })),
                ]}
                onChange={(event) => {
                  const useDevice =
                    event?.target?.value === "-1"
                      ? undefined
                      : event?.target?.value;
                  useDevice && localStorage.setItem(inputDeviceKey, useDevice);
                  setCurrentDevice(useDevice);
                }}
              />
            </FlexColumn>
          )}
          {!currentDevice && (
            <FlexColumn
              dimensions={{ width: "100%", height: "100%" }}
              justifyContent="center"
              alignItems="center"
            >
              <TitleXL>Izaberite kameru za skeniranje koda</TitleXL>
            </FlexColumn>
          )}
          <FlexColumn
            dimensions={{ width: "100%", height: "100%" }}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setInputing(true);
              timeoutRef.current = setTimeout(() => {
                setInputing(false);
                timeoutRef.current = undefined;
              }, 20 * 1000);
            }}
          >
            {currentDevice && !isInputing && (
              <>
                <FlexColumn
                  dimensions={{ width: "100%", height: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Image
                    dimensions={{
                      maxWidth: "300px",
                      width: "100%",
                      height: "auto",
                    }}
                    src="/assets/images/click.png"
                  />
                  <TitleXXL>Klikni za pocetak skeniranja</TitleXXL>
                </FlexColumn>
              </>
            )}
            {currentDevice && isInputing && (
              <CameraImageCapture
                key={currentDevice}
                captureTimeout={1000}
                onCapture={getCameraBase64}
                dimensions={{ width: "100%", maxHeight: "100%" }}
                constrains={{ deviceId: currentDevice }}
              />
            )}
          </FlexColumn>
        </>
      )}
      {!hasPermission && (
        <FlexColumn
          dimensions={{ width: "100%", height: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <TitleXL color={theme.colors.danger}>
            Softver nema pristup kameri!
          </TitleXL>
        </FlexColumn>
      )}
      <FlexColumn
        position="fixed"
        style={{ right: 0, bottom: 0, zIndex: 10 }}
        dimensions={{ width: "64px", height: "64px" }}
        onClick={() => setCounter(counter + 1)}
      />
    </>
  );
}
