import { BrowserRouter } from "react-router-dom";
import routesRenderer from "./routing/routesRenderer";
import { APP_ROUTS } from "./screens/routes";
import GlobalStyle from "./global/style";
import { AuthProvider } from "./contexts/Auth";
import { ModalProvider } from "./contexts/ModalProviderContext";
import { CacheProvider } from "./contexts/Caching";

export default function App() {
  return (
    <CacheProvider>
      <GlobalStyle />
      <BrowserRouter>
        <ModalProvider>
          <AuthProvider>{routesRenderer(APP_ROUTS)}</AuthProvider>
        </ModalProvider>
      </BrowserRouter>
    </CacheProvider>
  );
}
