import { useState, HTMLInputTypeAttribute } from "react";

import { FiltersItem } from "./type";
import {
  FlexRow,
  FlexItem,
  Button,
  ButtonTypes,
  TitleXL,
  Title,
} from "../../styled";
import { DateRangeFilterModal } from "../../../modals/DateRangeFilterModal";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import Label from "../../components/Label/Label";
import Icon from "../../components/Icon/Icon";
import { IconTypes } from "../../components/Icon/types";

import { useModalProvider } from "../../../contexts/ModalProviderContext";
import { toReadableDateOnly } from "../../../utilities/datetime";

import { colors } from "../../../constants";

export interface FiltersProps {
  items?: FiltersItem[];
  values: { [key: string]: any };
  setValues: (values: { [key: string]: any }) => void;
  isLoading?: boolean;
}

const RenderItemFilter = (
  filter: FiltersItem,
  setValue: (value: any) => void,
  isLoading: boolean,
  value: any
) => {
  const { openModal, closeModal } = useModalProvider();

  if (filter?.inputType === "input-autocomplete") {
    return (
      <>
        <Input
          disabled={isLoading}
          name={filter?.attribute}
          key={filter?.attribute}
          placeholder={filter?.placeholder}
          defaultValue={value}
          type={filter?.type as HTMLInputTypeAttribute | "dateRange"}
          onChange={(event) => {
            const value =
              event?.target?.value?.length === 0
                ? undefined
                : event?.target?.value;

            filter?.mapValueOnChange
              ? setValue(filter?.mapValueOnChange(value))
              : setValue(value);
          }}
          list={filter?.attribute}
        />
        <datalist id={filter?.attribute}>
          {filter?.options?.map((value) => (
            <option>{value}</option>
          ))}
        </datalist>
      </>
    );
  }

  if (filter?.inputType === "select") {
    return (
      <Select
        key={`${filter?.name}${filter?.options?.length}`}
        disabled={isLoading}
        placeholder={filter?.placeholder}
        options={filter?.options}
        fetch={filter?.fetch}
        name={filter?.name}
        onChange={(event) =>
          setValue(
            event?.target?.value === "-1" ? undefined : event?.target?.value
          )
        }
        defaultValue={value}
      />
    );
  }

  if (filter?.inputType === "input") {
    return (
      <Input
        disabled={isLoading}
        name={filter?.attribute}
        key={filter?.attribute}
        placeholder={filter?.placeholder}
        defaultValue={value}
        type={filter?.type as HTMLInputTypeAttribute | "dateRange"}
        onChange={(event) =>
          setValue(
            event?.target?.value?.length === 0
              ? undefined
              : event?.target?.value
          )
        }
      />
    );
  }

  if (filter?.inputType === "dateRange") {
    return (
      <Button
        type="button"
        border={{ color: colors?.grey?.nevada, radius: "4px", width: "0.5px" }}
        styleType={ButtonTypes?.clean}
        dimensions={{ height: "30px", width: "fit-content" }}
        onClick={() =>
          openModal("rangeModal", DateRangeFilterModal, {
            setValue: setValue,
            value: value,
            close: () => closeModal("rangeModal"),
            title: <TitleXL>Created at Range</TitleXL>,
          })
        }
      >
        <FlexRow justifyContent="space-between" alignItems="center" gap="8px">
          <Icon
            type={IconTypes?.calendar}
            size="20px"
            color={colors?.grey?.tundora}
          />
          <FlexItem dimensions={{ flex: 1 }}>
            <Title
              align="center"
              color={colors?.grey?.tundora}
              weight="lighter"
            >
              {(!value?.start || !value?.end) && <>Created At Range</>}
              {value?.start && value?.end && (
                <>
                  {toReadableDateOnly(value?.start)} -{" "}
                  {toReadableDateOnly(value?.end)}
                </>
              )}
            </Title>
          </FlexItem>
        </FlexRow>
      </Button>
    );
  }
};

export const Filters = ({
  items,
  values,
  setValues,
  isLoading,
}: FiltersProps) => {
  const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const updateValues = (key: string, value: unknown) => {
    if (updateTimeout) clearTimeout(updateTimeout);

    setUpdateTimeout(
      setTimeout(() => {
        setValues({ ...values, [key]: value });
        setUpdateTimeout(null);
      }, 500)
    );
  };

  return (
    <FlexRow
      gap="10px"
      flexWrap="wrap"
      dimensions={{ width: "100%" }}
      justifyContent="flex-end"
    >
      {items?.map((filter) => {
        return (
          <Label label={filter?.label}>
            {RenderItemFilter(
              filter,
              (value: any) => updateValues(filter?.attribute, value),
              isLoading ?? false,
              values[filter?.attribute]
            )}
          </Label>
        );
      })}
    </FlexRow>
  );
};
