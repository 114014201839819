import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../../shared/components/Header";
import { Loading } from "../../../../shared/components/Loading";
import { Scroll } from "../../../../shared/components/Scroll";
import Table from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/genericScreens/Filters";
import {
  FlexRow,
  TitleXXL,
  TitleXS,
  FlexColumn,
  Button,
  ButtonTypes,
  Paragraph,
} from "../../../../shared/styled";

import {
  IssueInterface,
  LocationInterface,
  GroupInterface,
  CardUserInterface,
  DeviceInterface,
} from "../../../../types/models";

import { listSelectLocationsHttp } from "../../../../apis/admin/locations";
import { listSelectGroupsHttp } from "../../../../apis/admin/groups";
import { listSelectIssuesHttp } from "../../../../apis/admin/issues";

import { Chart } from "react-google-charts";
import {
  httpMachineEfficencyBuildFile,
  machineEfficencyHttp,
} from "../../../../apis/admin/analytics";
import { datekeysInRangeHttp } from "../../../../apis/admin/utilities";
import { theme } from "../../../../constants";
import { listSelectCardUsersHttp } from "../../../../apis/admin/cardUsers";
import { listSelectDevicesHttp } from "../../../../apis/admin/devices/listSelectDevicesHttp";

const options = {
  hAxis: { title: "Groups" },
  vAxis: { title: "Minutes" },
};

interface AggregatedStatisticsData {
  datekey: string;
  data: {
    [issue: string]: number;
  };
}

interface SupportDataType {
  groups?: GroupInterface[];
  locations?: LocationInterface[];
  issues?: IssueInterface[];
  employees?: CardUserInterface[];
  devices?: DeviceInterface[];
}

const getDefaultcreatedAtFilter = () => {
  const currentDate = new Date();
  currentDate.setDate(1);

  const endDate = new Date();
  endDate.setDate(1);
  if (endDate.getMonth() + 1 < 12) {
    endDate.setMonth(endDate.getMonth() + 1);
  } else {
    endDate.setMonth(0);
    endDate.setFullYear(endDate.getFullYear() + 1);
  }

  return {
    start: currentDate,
    end: endDate,
  };
};

export const DeviceStatistics = () => {
  const [data, setData] = useState<AggregatedStatisticsData[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isReadyToFetch, setReadyToFetch] = useState<boolean>(false);

  const [filters, setFilters] = useState<{ [key: string]: any }>({
    created_at: getDefaultcreatedAtFilter(),
  });

  const [buildFileError, setBuildFileError] = useState<string | undefined>();

  const fetchData = useCallback(
    async (_filters: typeof filters = filters) => {
      if (!_filters?.created_at) return;
      if (isLoading) return;

      setIsLoading(true);
      try {
        const { created_at, ...restFilters } = _filters;

        const created_at__gte = (created_at.start as Date)
          ?.toISOString()
          .split("T")[0];
        const created_at__lte = (created_at.end as Date)
          ?.toISOString()
          .split("T")[0];

        const updatedFilters = {
          ...restFilters,
          created_at__gte,
          created_at__lte,
        };

        const { data: dateKeys } = await datekeysInRangeHttp({
          date__gte: created_at__gte,
          date__lte: created_at__lte,
        });
        const { data: responseDate } = await machineEfficencyHttp(
          updatedFilters
        );

        const aggregatedData = dateKeys?.map((key) => ({
          data: responseDate[key],
          datekey: key,
        }));
        setData(aggregatedData);
      } catch (err) {
        console.log("Analytics.fetchData", err);
      }
      setIsLoading(false);
    },
    [isLoading, filters]
  );

  const fetchSupportData = useCallback(async () => {
    const updatedSupportData: typeof supportData = {};

    try {
      const { data: groupsData } = await listSelectGroupsHttp();
      updatedSupportData.groups = groupsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    try {
      const { data: issuesData } = await listSelectIssuesHttp();
      updatedSupportData.issues = issuesData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
  }, []);

  const fetchFilteredSupportData = useCallback(
    async (localFilters: typeof filters) => {
      const freshSupportData = supportData ?? {};

      const [cardUsersResponse, devicesResponse] = await Promise.all([
        listSelectCardUsersHttp({
          ...(localFilters?.location_id
            ? { location_id: localFilters?.location_id }
            : {}),
          ...(localFilters?.group_id
            ? { group_id: localFilters?.group_id }
            : {}),
        }),
        listSelectDevicesHttp({
          ...(localFilters?.location_id
            ? { location_id: localFilters?.location_id }
            : {}),
        }),
      ]);

      freshSupportData.devices = devicesResponse.data;
      freshSupportData.employees = cardUsersResponse.data;

      setSupportData(freshSupportData);
    },
    [supportData]
  );

  const buildExcelFile = async () => {
    if (!filters.location_id) {
      setBuildFileError("Location must be selected to build file!");
      return;
    }
    setBuildFileError(undefined);

    const { created_at, ...restFilters } = filters;

    const created_at__gte = (created_at.start as Date)
      ?.toISOString()
      .split("T")[0];
    const created_at__lte = (created_at.end as Date)
      ?.toISOString()
      .split("T")[0];

    const updatedFilters = {
      ...restFilters,
      created_at__gte,
      created_at__lte,
    };

    try {
      const { data: responseData } = await httpMachineEfficencyBuildFile(
        updatedFilters
      );
      window.open(responseData.url, "_blank")?.focus();
    } catch (err) {
      console.log(err);
      //TODO Handle error
    }
  };

  useEffect(() => {
    fetchSupportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (supportData && !isReadyToFetch) setReadyToFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportData]);

  useEffect(() => {
    if (isReadyToFetch) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToFetch]);

  const gLabels = [
    ...(supportData?.issues || [])?.map((issue) => issue?.name as string),
    "Unresolved Issue",
  ];

  const flattenData = (() => {
    const pFlatenData: any = {};
    (data || []).forEach((dataItem) => {
      gLabels.forEach((label) => {
        if (!(label in pFlatenData)) {
          pFlatenData[label] = 0;
        }

        pFlatenData[label] += dataItem?.data?.[label] ?? 0;
      });
    });

    return pFlatenData;
  })();

  const created_at__gte = (
    filters.created_at.start as Date
  )?.toLocaleDateString();
  const created_at__lte = (
    filters.created_at.end as Date
  )?.toLocaleDateString();

  const graphData = [
    [`${created_at__gte} - ${created_at__lte}`, "Minutes"],
    ...gLabels.map((label) => [
      label,
      +((flattenData[label] ?? 0) / 60).toFixed(2),
    ]),
  ];

  const updateFilters = useCallback(
    async (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchFilteredSupportData(updatedFilters);
      fetchData(updatedFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData]
  );

  console.log("supportData", supportData);

  return (
    <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
      <FlexColumn dimensions={{ width: "100%" }} gap="20px" padding="16px">
        <Header />
        <TitleXXL>ANALITIKA EFIKASNOSTI</TitleXXL>
        <FlexRow dimensions={{ width: "100%" }}>
          <FlexColumn gap="10px">
            <Button styleType={ButtonTypes.primary} onClick={buildExcelFile}>
              Izdaj u obliku fajla
            </Button>
            {buildFileError && (
              <Paragraph color={theme.colors.danger}>
                {buildFileError}
              </Paragraph>
            )}
          </FlexColumn>
        </FlexRow>
        <Filters
          isLoading={isLoading}
          values={filters}
          setValues={updateFilters}
          items={[
            {
              label: <TitleXS>Lokacija</TitleXS>,
              placeholder: "Lokacije",
              inputType: "select",
              type: "number",
              attribute: "location_id",
              name: "location_id",
              options: [
                { value: -1, label: "Nije selektovano" },
                ...(supportData?.locations ?? [])?.map((location) => ({
                  value: location?.id,
                  label: `${location?.name}, ${location?.city}`,
                })),
              ],
            },
            {
              label: <TitleXS>Grupe</TitleXS>,
              placeholder: "Grupa",
              inputType: "select",
              type: "number",
              attribute: "group_id",
              name: "group_id",
              options: [
                { value: -1, label: "Nije selektovano" },
                ...(supportData?.groups ?? [])?.map((group) => ({
                  value: group?.id,
                  label: group?.name,
                })),
              ],
            },
            {
              label: <TitleXS>Zaduzenik masine</TitleXS>,
              placeholder: "Zaduzenik masine",
              inputType: "select",
              type: "number",
              attribute: "employee_id",
              name: "employee_id",
              options: [
                { value: -1, label: "Nije selektovano" },
                ...(supportData?.employees ?? [])?.map((employee) => ({
                  value: employee?.id,
                  label: `${employee.last_name} ${employee.first_name}`,
                })),
              ],
              disabled: !filters?.location_id,
            },
            {
              label: <TitleXS>Masina</TitleXS>,
              placeholder: "Masina",
              inputType: "input-autocomplete",
              type: "text",
              attribute: "device_id",
              name: "device_id",
              options: (supportData?.devices ?? [])
                ?.map((device) => device.inventure_number)
                ?.filter((value) => value !== undefined) as string[],
              mapValueOnChange: (invCode: string) => {
                const targetDevice = supportData?.devices?.find(
                  (device) => device.inventure_number === invCode
                );
                return targetDevice?.id;
              },
              disabled: !filters?.location_id,
            },
            {
              label: <TitleXS>Datum evidentiranja</TitleXS>,
              inputType: "dateRange",
              attribute: "created_at",
              name: "created_at",
              placeholder: "Datum evidentiranja",
            },
          ]}
        />

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Table<AggregatedStatisticsData>
              title={
                <FlexRow
                  dimensions={{ width: "100%" }}
                  justifyContent="space-between"
                  alignItems="center"
                  padding={"5px 0"}
                >
                  <TitleXXL>Problem po datumu</TitleXXL>
                </FlexRow>
              }
              isLoading={isLoading}
              schema={[
                {
                  attribute: "datekey",
                  label: "Date",
                  width: "200px",
                },
                ...gLabels.map((label) => ({
                  attribute: "data" as keyof AggregatedStatisticsData,
                  label: label,
                  flex: 1,
                  mapper: (item?: AggregatedStatisticsData) =>
                    ((item?.data?.[label] ?? 0) / 60).toFixed(2),
                })),
              ]}
              data={data ?? []}
            />

            <FlexRow gap="32px">
              <Chart
                chartType="Bar"
                data={graphData}
                options={options}
                legendToggle
                width="100%"
                height="600px"
              />
            </FlexRow>
          </>
        )}
      </FlexColumn>
    </Scroll>
  );
};
