import { Nullable } from "../../../types/utils";
import { httpPost } from "../../utilities";

export interface CreateStationHttpData {
  location_id?: Nullable<number>;
  name?: string;
  description?: string;
  is_active?: boolean;
}

export const createStationHttp = async (data: CreateStationHttpData) =>
  httpPost("/admin/stations", data);
