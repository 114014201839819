import { useCallback, useEffect, useState } from "react";
import { Header } from "../../../../../shared/components/Header";
import {
  Badge,
  Button,
  FlexColumn,
  FlexRow,
  Text,
  TitleXL,
  TitleXXL,
} from "../../../../../shared/styled";
import { usersListHttp } from "../../../../../apis/admin/users";
import { TablePagination } from "../../../../../shared/components/Table";
import { UserAuthInterface } from "../../../../../types/models";
import Table from "../../../../../shared/components/Table/Table";
import { theme } from "../../../../../constants";
import { useNavigate } from "react-router";
import Select from "../../../../../shared/components/Select/Select";
import { Filters } from "../../../../../shared/genericScreens/Filters";

export const UsersList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<UserAuthInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await usersListHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 50,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        console.log("UserList.fetchData", err);
      }
      setLoading(false);
    },
    [isLoading,pagination,filters,ordering]
  );

  const updatePagination = useCallback((updatedPagination: typeof pagination) => {
    setPagination(updatedPagination);
    fetchData(updatedPagination);
  },[fetchData])

  const updateOrdering = useCallback((updatedOrdering: typeof ordering)=>{
    setOrdering(updatedOrdering);
    fetchData(pagination,filters,updatedOrdering)
  },[fetchData,pagination,filters])

  const updateFilters = useCallback((updatedFilters: typeof filters)=>{
    setFilters(updatedFilters);
    fetchData(pagination,updatedFilters);
  },[pagination,fetchData])

  /* Initial Fetch */
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>KORISNICI PANELA</TitleXXL>
      <Filters
        isLoading={isLoading}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            placeholder: "Pretraga...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<UserAuthInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>Lista korisnika panela ({pagination?.count})</TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button onClick={() => navigate("/admin/access/users/create")}>
                Kreiraj korisnika panela
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "username",
            order: "username",
            label: "KORISNICKO IME",
            width: "200px",
          },
          {
            attribute: "is_active",
            order: "is_active",
            label: "STATUS",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_active ? theme.colors.success : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_active ? "Aktivan" : "Neaktivan"}
                </Text>
              </Badge>
            ),
          },
          {
            attribute: "is_admin",
            order: "is_admin",
            label: "ADMIN PRISTUP",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_admin ? theme.colors.success : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_admin ? "Prisutan" : "Odsutan"}
                </Text>
              </Badge>
            ),
          },
          {
            attribute: "is_staff",
            order: "is_staff",
            label: "OSOBLJE PRISTUP",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_staff ? theme.colors.success : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_staff ? "Prisutan" : "Odsutan"}
                </Text>
              </Badge>
            ),
          },
          {
            attribute: "is_superuser",
            order: "is_superuser",
            label: "SUPER ADMIN PRISTUP",
            width: "150px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_superuser
                    ? theme.colors.success
                    : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_superuser ? "Prisutan" : "Odsutan"}
                </Text>
              </Badge>
            ),
          },
          {
            attribute: "created_at",
            order: "created_at",
            label: "KREIRAN DATUMA",
            width: "200px",
            mapper: (item) =>
              item?.created_at
                ? new Date(item?.created_at)?.toLocaleString()
                : "NEDOSTUPAN DATUM",
          },
          {
            label: "AKCIJE",
            width: "200px",
            mapper: (item) => (
              <Select
                onChange={(event) => {}}
                value="none"
                options={[
                  {
                    label: "Odaberite akciju",
                    value: "none",
                    disabled: true,
                  },
                  // { label: "Update auth", value: "update-auth" },
                  // { label: "Update permissions", value: "update-permissions" },
                  {
                    label: item?.is_active ? "Deaktiviraj" : "Aktiviraj",
                    value: "update-status",
                  },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
