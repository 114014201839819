import { useEffect, useState } from "react";
import { useCacheProvider } from "../../contexts/Caching";
import {
  interfaceAccessKey,
  interfaceCardCodeKey,
  interfaceCardUserKey,
} from "./cacheKeys";
import { useNavigate } from "react-router";
import { CardUserInterface } from "../../types/models";
import { stopDeviceHttp } from "../../apis/interface";
import { FlexColumn, Image, Text, TitleXXL } from "../../shared/styled";
import { theme } from "../../constants";

export default function Employee() {
  const navigate = useNavigate();

  const { getKey, deleteKey } = useCacheProvider();

  const [accessKey, setAccessKey] = useState<string>();
  const [cardCodeUser, setCardCodeUser] = useState<CardUserInterface>();

  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const changeDeviceState = async () => {
    if (!accessKey || !cardCodeUser?.card_code) return;
    try {
      await stopDeviceHttp(accessKey, cardCodeUser?.card_code);
      deleteKey(interfaceCardUserKey);
      deleteKey(interfaceCardCodeKey);
      setSuccess(true);
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setError("Device not found!");
        return;
      }
      if (err?.response?.status === 400) {
        setError(err.reponse.data.message);
        return;
      }

      setError("Unknown Error");
    }
  };

  const loadCachedData = () => {
    const cachedAccessKey = getKey(interfaceAccessKey, undefined);
    const cardUser = getKey(interfaceCardUserKey, undefined);

    if (!cachedAccessKey) navigate("/interface");
    setAccessKey(cachedAccessKey as string);

    if (!cardUser) navigate("/interface/input");
    setCardCodeUser(cardUser as CardUserInterface);
  };

  useEffect(() => {
    if (cardCodeUser) changeDeviceState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardCodeUser]);

  useEffect(() => {
    loadCachedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <FlexColumn
          dimensions={{ width: "100%", height: "100%" }}
          gap="20px"
          justifyContent="center"
          alignItems="center"
          onClick={() => navigate("/interface/input")}
        >
          <TitleXXL color={theme.colors.danger}>Pritisnite za izlaz</TitleXXL>
          <Image
            dimensions={{ maxWidth: "300px", width: "100%", height: "auto" }}
            src="/assets/images/click.png"
          />
          <Text color={theme.colors.danger}>{error}</Text>
        </FlexColumn>
      )}
      {isSuccess && (
        <FlexColumn
          dimensions={{ width: "100%", height: "100%" }}
          gap="20px"
          justifyContent="center"
          alignItems="center"
          onClick={() => navigate("/interface/input")}
        >
          <TitleXXL>Stanje uredaja je promjenjeno!</TitleXXL>
          <Image
            dimensions={{ maxWidth: "300px", width: "100%", height: "auto" }}
            src="/assets/images/click.png"
          />
          <Text color={theme.colors.success}>Pritisnite za izlaz</Text>
        </FlexColumn>
      )}
    </>
  );
}
