import { httpPut } from "../../utilities";

export interface UpdateLocationHttpData {
  description: string;
  city: string;
  name: string;
  tags: string;
}

export const updateLocationHttp = async (id: number|string, data: UpdateLocationHttpData) =>
  httpPut(`/admin/locations/${id}`, data);
