import { httpPost } from "../../utilities";

interface BasicCardReaderHttpResponse {
  data: string;
  type: string;
}


export const adminCardReaderHttp = async (base64: string) =>
  httpPost<BasicCardReaderHttpResponse>("/admin/utility/scanner/", { base64: base64 });
