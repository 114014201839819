import { DeviceInterface } from "../../types/models";
import { httpGet } from "../utilities";

export const listMechanicDevicesHttp = async (
  interfaceAccessCode: string,
  cardCode: string
) =>
  httpGet<DeviceInterface[]>(
    `/interface/devices`,
    {},
    { "Access-Key": interfaceAccessCode, "Card-Code": cardCode }
  );
