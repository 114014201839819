/* const _setAtPath = (object: { [key: string]: any }, value: unknown, path: string[]) => {
  if (path?.length === 0) return object;

  return {
    ...object,
    [path[0]]: path?.length === 1 ? value : _setAtPath(object[path[0]] ?? {}, value, path.slice(1))
  }; 
};*/

export const setAtPath = (object: { [key: string]: any }, value: unknown, path: string | string[]): any => {
  const pathAsArray = Array.isArray(path) ? path : path.split(".");
  if (pathAsArray?.length === 0) return object;

  return {
    ...object,
    [pathAsArray[0]]: pathAsArray?.length === 1 ? value : setAtPath(object[pathAsArray[0]] ?? {}, value, pathAsArray.slice(1)),
  };
};
