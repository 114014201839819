import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Button, FlexColumn, TitleXL } from "../../shared/styled";
import { Loading } from "../../shared/components/Loading";
import { interfaceHealthHttp } from "../../apis/interface";
import Label from "../../shared/components/Label/Label";
import Input from "../../shared/components/Input/Input";
import { useCacheProvider } from "../../contexts/Caching";
import { interfaceAccessKey } from "./cacheKeys";

export default function InterfaceWrapper() {
  const navigate = useNavigate();
  const { setKey } = useCacheProvider();

  const [accessKey, setAccessKey] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [accessKeyValid, setAccessKeyValid] = useState<boolean>(false);

  const checkInterfaceHealth = useCallback(async (checkAccessKey?: string) => {
    const cachedAccessKey =
      checkAccessKey ?? localStorage.getItem("interfaceAccessKey");
    if (!cachedAccessKey) return;
    setAccessKey(cachedAccessKey);

    setLoading(true);
    try {
      await interfaceHealthHttp(cachedAccessKey);
      setAccessKeyValid(true);
      localStorage.setItem("interfaceAccessKey", cachedAccessKey);
      setKey(interfaceAccessKey, cachedAccessKey);
      navigate("/interface/input");
    } catch (err) {
      setAccessKey(undefined);
      setAccessKeyValid(false);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkInterfaceHealth();
  }, [checkInterfaceHealth]);

  return (
    <FlexColumn
      dimensions={{ width: "100vw", height: "100vh" }}
      overflow={{ x: "hidden" }}
      justifyContent="center"
      alignItems="center"
    >
      {isLoading && <Loading />}
      {!isLoading && accessKeyValid && <Outlet />}
      {!isLoading && !accessKeyValid && (
        <FlexColumn
          dimensions={{ maxWidth: "500px", width: "100%" }}
          gap="20px"
        >
          <Label label={<TitleXL>Unesite pristupni kljuc</TitleXL>}></Label>
          <Input onChange={(event) => setAccessKey(event?.target?.value)} />
          <Button onClick={() => checkInterfaceHealth(accessKey)}>
            Validiraj kljuc
          </Button>
        </FlexColumn>
      )}
    </FlexColumn>
  );
}
