import { ReactNode, useEffect } from "react";
import { useAuthProvider } from "./Hook";
import { useNavigate } from "react-router";

interface AuthRouteProps {
  children?: ReactNode;
}

export const AuthRoute = ({ children, ...rest }: AuthRouteProps) => {
  const navigate = useNavigate();
  const { hasAccess, isLoading } = useAuthProvider();

  useEffect(() => {
    if (!isLoading && !hasAccess) navigate("/login");
  }, [hasAccess, isLoading, navigate]);

  return <>{children}</>;
};
