import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Header } from "../../../../../shared/components/Header";
import {
  Badge,
  Button,
  FlexColumn,
  FlexRow,
  Text,
  TitleXL,
  TitleXS,
  TitleXXL,
} from "../../../../../shared/styled";
import { TablePagination } from "../../../../../shared/components/Table";
import {
  InterfaceAuthInterface,
  LocationInterface,
  StationAuthInterface,
} from "../../../../../types/models";
import Table from "../../../../../shared/components/Table/Table";
import { theme } from "../../../../../constants";
import { useNavigate } from "react-router";
import Select from "../../../../../shared/components/Select/Select";
import { Filters } from "../../../../../shared/genericScreens/Filters";
import {
  listInterfacesHttp,
  updateInterfaceHttp,
} from "../../../../../apis/admin/interfaces";
import { listSelectStationsHttp } from "../../../../../apis/admin/stations";
import { useModalProvider } from "../../../../../contexts/ModalProviderContext";
import { InfoModal } from "../../../../../modals";
import { listSelectLocationsHttp } from "../../../../../apis/admin/locations";

interface SupportDataType {
  stations?: StationAuthInterface[];
  locations?: LocationInterface[];
}

export const InterfacesList = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<InterfaceAuthInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: stationData } = await listSelectStationsHttp();
      updatedSupportData.stations = stationData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await listInterfacesHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 50,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        //TODO Handle error
        console.log(err);
      }
      setLoading(false);
    },
    [isLoading, pagination, filters, ordering]
  );

  const updatePagination = useCallback(
    (updatedPagination: typeof pagination) => {
      setPagination(updatedPagination);
      fetchData(updatedPagination);
    },
    [fetchData]
  );

  const updateOrdering = useCallback(
    (updatedOrdering: typeof ordering) => {
      setOrdering(updatedOrdering);
      fetchData(pagination, filters, updatedOrdering);
    },
    [fetchData, pagination, filters]
  );

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(pagination, updatedFilters);
    },
    [pagination, fetchData]
  );

  const handleOptionSelect = (item?: InterfaceAuthInterface) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value;
      if (value === "update") {
        navigate(`/admin/access/interfaces/${item?.id}`);
        return;
      }

      if (value === "show-access-key") {
        openModal("InfoModal", InfoModal, {
          style: {
            dimensions: {
              maxWidth: "500px",
            },
          },
          body: (
            <FlexColumn
              dimensions={{ width: "100%" }}
              justifyContent="center"
              alignItems="center"
              style={{ overflowWrap: "anywhere" }}
            >
              {item?.access_key}
            </FlexColumn>
          ),
          title: "Pristupni kljuc",
          close: () => closeModal("InfoModal"),
        });
        return;
      }

      if (value === "update-status") {
        setSubmitting(true);
        updateInterfaceHttp(`${item?.id}`, {
          is_active: !item?.is_active,
        })
          .then(() => {
            setData(
              data?.map((dataItem) =>
                dataItem?.id === item?.id
                  ? { ...dataItem, is_active: !item?.is_active }
                  : dataItem
              )
            );
          })
          .catch((err) => {
            //TODO Handle error
            console.log(err);
          })
          .finally(() => setSubmitting(false));
        //TODO Add Modal to confirm status change 'is_active'
      }
    };
  };

  /* Initial Fetch */
  useEffect(() => {
    fetchSupportData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>INTERFEJSI</TitleXXL>
      <Filters
        isLoading={isLoading || isLoadingSupportData}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            label: <TitleXS>Stanica</TitleXS>,
            placeholder: "Stanica",
            inputType: "select",
            type: "number",
            attribute: "station_device_id",
            name: "station_device_id",
            options: [
              { value: -1, label: "Nije odabrana" },
              ...(supportData?.stations ?? [])?.map((station) => ({
                value: station?.id,
                label: station?.name,
              })),
            ],
          },
          {
            label: <TitleXS>Lokacija</TitleXS>,
            placeholder: "Lokacije",
            inputType: "select",
            type: "number",
            attribute: "location_id",
            name: "location_id",
            options: [
              { value: -1, label: "Nije odabrana" },
              ...(supportData?.locations ?? [])?.map((location) => ({
                value: location?.id,
                label: `${location?.name}, ${location?.city}`,
              })),
            ],
          },
          {
            label: <TitleXS>Pretraga</TitleXS>,
            placeholder: "Pretrazi...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<InterfaceAuthInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>
              Interfejs lista ({pagination?.count ?? 0})
            </TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button
                onClick={() => navigate("/admin/access/interfaces/create")}
              >
                Kreiraj interfejs
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "name",
            order: "name",
            label: "NAZIV",
            width: "300px",
          },
          {
            attribute: "location_id",
            order: "location_id",
            label: "LOKACIJA",
            width: "300px",
            mapper: (item) => (
              <>
                {item?.location?.name}, {item?.location?.city}
              </>
            ),
          },
          {
            attribute: "station_device",
            label: "STANICA",
            width: "150px",
            mapper: (item) =>
              item?.station_device ? (
                item?.station_device?.name
              ) : (
                <Badge background={theme.colors.danger}>
                  <Text
                    color={theme.colors.clean}
                    weight={theme.font.weight.bold}
                  >
                    Nema stanice
                  </Text>
                </Badge>
              ),
          },
          {
            attribute: "created_at",
            order: "created_at",
            label: "KREIRANO DATUMA",
            width: "200px",
            mapper: (item) =>
              item?.created_at
                ? new Date(item?.created_at)?.toLocaleString()
                : "NEMA DATUMA",
          },
          {
            attribute: "is_active",
            order: "is_active",
            label: "STATUS",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_active ? theme.colors.success : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_active ? "AKTIVNA" : "NEAKTIVNA"}
                </Text>
              </Badge>
            ),
          },
          {
            label: "AKCIJE",
            width: "200px",
            mapper: (item) => (
              <Select
                key={`actionsManagement${
                  item?.is_active ? "active" : "inactive"
                }`}
                disabled={isSubmitting}
                onChange={handleOptionSelect(item)}
                value="none"
                options={[
                  {
                    label: "Odaberi akciju",
                    value: "none",
                    disabled: true,
                  },
                  { label: "Uredi interfejs", value: "update" },
                  { label: "Prikazi pristupni kljuc", value: "show-access-key" },
                  {
                    label: item?.is_active ? "Deaktiviraj" : "Aktiviraj",
                    value: "update-status",
                  },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
