import type CSS from "csstype";
import styled from "styled-components";

import {
  BackgroundStyling,
  BackgroundStylingProps,
  DimensionStyling,
  DimensionStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../../styling";
import { theme } from "../../../../constants";

export interface SelectStyleProps {
  dimensions?: DimensionStylingProps;
  background?: BackgroundStylingProps | CSS.Property.Background;
  padding?: PaddingStylingProps | CSS.Property.Padding;
}

export const SelectStyle = styled.select<SelectStyleProps>`
  outline: none;
  border: solid 1px ${theme.border.color};
  background: ${theme.colors.clean};
  border-radius: 4px;
  
  font-size: ${theme.font.size.x}px;
  font-weight: ${theme.font.weight.normal};

  padding: 5px;

  ${({ dimensions }) => DimensionStyling(dimensions)};
  ${({ background }) => BackgroundStyling(background)};
  ${({ padding }) => PaddingStyling(padding)};

  &::placeholder {
    color: ${theme.font.placeholder};
  }

  &:focus {
    outline: none;
    border-color: ${theme.border.focus};
  }

  &:invalid {

  }
`;
