import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Form from "../../../../shared/components/Form/Form";
import { Header } from "../../../../shared/components/Header";
import {
  FlexColumn,
  FlexItem,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../shared/styled";
import {
  createGroupHttp,
  updateGroupHttp,
  retriveGroupHttp,
  CreateGroupHttpData,
} from "../../../../apis/admin/groups";
import { LocationInterface } from "../../../../types/models";
import { listSelectLocationsHttp } from "../../../../apis/admin/locations";
import { Loading } from "../../../../shared/components/Loading";
import { timeToLocal, timeToUTC } from "../../../../utilities/datetime/time";

interface SupportDataType {
  locations?: LocationInterface[];
}

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const ManageGroups = () => {
  const { id } = useParams();
  const screenType = id ? "update" : "create";

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateGroupHttpData>({
    name: "",
    location_id: 1,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveGroupHttp(id);
      setInitialData({
        name: responseData.name,
        location_id: responseData.location_id as number,
        start_work_time: responseData?.start_work_time
          ? timeToLocal(responseData?.start_work_time)
          : undefined,
        end_work_time: responseData?.end_work_time
          ? timeToLocal(responseData?.end_work_time)
          : undefined,
        description: responseData?.description,
      });
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateGroupHttpData) => {
    setSubmitting(true);

    const startWorkTime = data.start_work_time
      ? timeToUTC(data.start_work_time)
      : undefined;
    const endWorkTime = data.end_work_time
      ? timeToUTC(data.end_work_time)
      : undefined;

    try {
      if (screenType === "update") {
        await updateGroupHttp(id as string, {
          ...data,
          start_work_time: startWorkTime,
          end_work_time: endWorkTime,
        });
      } else {
        await createGroupHttp({
          ...data,
          start_work_time: startWorkTime,
          end_work_time: endWorkTime,
        });
      }
      window.history.back();
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }
    setSubmitting(false);
  };

  // Initialization
  useEffect(() => {
    fetchSupportData();
    fetchDefaultData();
  }, [fetchSupportData, fetchDefaultData]);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>GRUPE</TitleXXL>
        <TitleXL>{Translations[screenType]?.toUpperCase()} GRUPU</TitleXL>
      </FlexColumn>
      <FlexColumn alignItems="center">
        {!isLoading && !isLoadingSupportData && (
          <Form<CreateGroupHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>Naziv grupe</Title>,
                type: "column",
                field: {
                  name: "name",
                  type: "text",
                  placeholder: "Unesite naziv grupe...",
                  options: {
                    required: "Naziv grupe je potreban!",
                  },
                },
              },
              {
                label: <Title>Lokacija</Title>,
                type: "column",
                field: {
                  name: "location_id",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Odaberite lokaciju", disabled: true },
                    ...(supportData?.locations ?? []).map((location) => ({
                      value: location.id,
                      label: `${location.name}, ${location.city}`,
                    })),
                  ],
                  placeholder: "Odaberite lokaciju",
                  options: {
                    required: "Lokacija mora biti selektovana!",
                  },
                },
              },
              {
                label: <Title>Opis</Title>,
                type: "column",
                field: {
                  name: "description",
                  type: "textarea",
                  placeholder: "Unesite opis...",
                },
                style: {
                  input: {
                    dimensions: { height: "128px" },
                  },
                },
              },
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potrvrdi</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {(isLoading || isLoadingSupportData) && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
