import { styled } from "styled-components";
import { colors } from "../../../../constants";
import Icon from "../../../../shared/components/Icon/Icon";
import { IconTypes } from "../../../../shared/components/Icon/types";

const ArrowButtonStyle = styled.button`
  transition: all 0.2s;
  border: none;
  background: ${colors?.white?.clean};

  font-size: 20px;
  text-align: center;

  cursor: pointer;

  &:hover {
    background: ${colors?.grey?.gallery};
  }
`;

interface ArrowButtonProps {
  arrow: "left" | "right";
  onClick?: () => void;
}

export const ArrowButton = ({ arrow, onClick }: ArrowButtonProps) => {
  return (
    <ArrowButtonStyle onClick={onClick}>
      <Icon type={arrow === "left" ? IconTypes.arrowLeft : IconTypes.arrowRight} size="20px" color={colors?.grey?.nevada} />
    </ArrowButtonStyle>
  );
};
