import { useNavigate, useParams } from "react-router";
import {
  Button,
  FlexColumn,
  FlexItem,
  FlexRow,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../../shared/styled";
import { Header } from "../../../../../shared/components/Header";
import { useCallback, useEffect, useState } from "react";
import { CreateCardUserHttpData } from "../../../../../apis/admin/cardUsers";
import Form from "../../../../../shared/components/Form/Form";
import { Loading } from "../../../../../shared/components/Loading";
import { LocationInterface } from "../../../../../types/models";
import {
  CreateStationHttpData,
  createStationHttp,
  retriveStationHttp,
  updateStationHttp,
} from "../../../../../apis/admin/stations";
import { listSelectLocationsHttp } from "../../../../../apis/admin/locations/listSelectLocationsHttp";

interface SupportDataType {
  locations?: LocationInterface[];
}

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const StationManage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const screenType = id ? "update" : "create";

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateStationHttpData>({
    location_id: 0,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: locationData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveStationHttp(id);
      setInitialData({
        location_id: responseData?.location_id,
        name: responseData?.name,
        description: responseData?.description,
        is_active: responseData?.is_active,
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateStationHttpData) => {
    setSubmitting(true);
    try {
      if (screenType === "update") await updateStationHttp(id as string, data);
      else await createStationHttp(data);
      window.history.back();
    } catch (err) {
      //TODO Handle error
      console.log(err);
    }
    setSubmitting(false);
  };

  // Initialization
  useEffect(() => {
    fetchSupportData();
    fetchDefaultData();
  }, [fetchSupportData, fetchDefaultData]);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>STANICE</TitleXXL>
        <TitleXL>{Translations[screenType].toUpperCase()} STANICU</TitleXL>
      </FlexColumn>

      <FlexColumn alignItems="center">
        {!isLoading && !isLoadingSupportData && (
          <Form<CreateCardUserHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>NAZIV</Title>,
                type: "column",
                field: {
                  name: "name",
                  type: "text",
                  placeholder: "Unesite naziv...",
                  options: {
                    required: "Naziv je potreban!",
                  },
                },
              },
              //TODO Add Tags
              {
                label: (
                  <FlexRow
                    dimensions={{ width: "100%" }}
                    alignItems="flex-end"
                    justifyContent="space-between"
                  >
                    <Title>Lokacija</Title>
                    {/* TODO Add navigation to the create location screen */}
                    <Button
                      type="button"
                      onClick={() => navigate("/admin/locations/create/")}
                    >
                      Kreiraj lokaciju
                    </Button>
                  </FlexRow>
                ),
                type: "column",
                field: {
                  name: "location_id",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Odaberi lokaciju", disabled: true },
                    ...(supportData?.locations ?? [])?.map((location) => ({
                      value: location?.id,
                      label: `${location?.name}, ${location?.city}`,
                    })),
                  ],
                  placeholder: "Odaberi lokaciju",
                  options: {
                    required: "Lokacija je potrebna!",
                  },
                },
              },
              {
                label: <Title>Opis</Title>,
                type: "column",
                field: {
                  name: "description",
                  type: "textarea",
                  placeholder: "Unesite opis...",
                },
                style: {
                  input: {
                    dimensions: { height: "128px" },
                  },
                },
              },
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potvrda</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {(isLoading || isLoadingSupportData) && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
