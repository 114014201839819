import { Outlet } from "react-router";
import Navbar from "../../shared/components/Navbar/Navbar";
import { FlexColumn, FlexRow } from "../../shared/styled";
import { AdminNavbarItems } from "./navbarItems";

export default function AdminPanel() {
  return (
    <FlexRow dimensions={{ width: "100vw", height: "100vh" }} overflow="hidden">
      <Navbar routes={AdminNavbarItems} />
      <FlexColumn dimensions={{ flex: 1, minHeight: "100%" }} overflow={"auto"}>
        <Outlet />
      </FlexColumn>
    </FlexRow>
  );
}
