import { prepareTime } from "./prepareTime";


export const timeToLocal = (time: string) => {
  const [hours, minutes] = time.split(":").map(value => +value);

  const currentDate = new Date();
  currentDate.setUTCHours(hours);
  currentDate.setUTCMinutes(minutes);
  currentDate.setUTCSeconds(0)
  currentDate.setUTCMilliseconds(0);

  const utcHours = currentDate.getHours();
  const utcMinutes = currentDate.getMinutes();

  return prepareTime(`${utcHours}:${utcMinutes}`)
}
