import { PaginationResponse } from "../../../types";
import { GroupInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export interface ListGroupsHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  location_id__in?: number[];
  location_id__exact?: number;
  name__in?: string[];
}

export const listGroupsHttp = async (
  offset?: number,
  limit?: number,
  filters: ListGroupsHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<GroupInterface>>("/admin/groups", {
    ...filters,
    offset,
    limit,
    ordering,
  });
