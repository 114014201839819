import { httpPut } from "../../utilities";

export interface UpdateGroupHttpData {
  location_id?: number;
  name?: string;
  description?: string;
  tags?: string;

  start_work_time?: string;
  end_work_time?: string;
}


export const updateGroupHttp = async (id: number|string, data: UpdateGroupHttpData) =>
  httpPut(`/admin/groups/${id}`, data);
