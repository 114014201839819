import { theme } from "../../../constants";
import { FlexRow, Text } from "../../styled";
import { IconTypes } from "../Icon";
import Icon from "../Icon/Icon";

export interface HeaderProps {
  backToRoute?: boolean;
}

export const Header = ({ backToRoute }: HeaderProps) => {

  const back = () => {
    window.history.back();
  };

  return (
    <FlexRow
      dimensions={{ width: "100%", height: "52px" }}
      background={theme.colors.primary}
      padding="15px"
      border={{ radius: "8px" }}
    >
      {backToRoute && (
        <FlexRow
          cursor="pointer"
          gap="8px"
          justifyContent="center"
          alignItems="flex-end"
          onClick={back}
        >
          <Icon type={IconTypes.back} size="24px" color={theme.colors.clean} />
          <Text
            size={`${theme.font.size.x}px`}
            color={theme.colors.clean}
            weight={theme.font.weight.bold}
          >
            NAZAD
          </Text>
        </FlexRow>
      )}
    </FlexRow>
  );
};
