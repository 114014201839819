import { useNavigate, useParams } from "react-router";
import {
  Button,
  FlexColumn,
  FlexItem,
  FlexRow,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../../shared/styled";
import { Header } from "../../../../../shared/components/Header";
import { useCallback, useEffect, useState } from "react";
import Form from "../../../../../shared/components/Form/Form";
import { Loading } from "../../../../../shared/components/Loading";
import { StationAuthInterface } from "../../../../../types/models";
import { listSelectStationsHttp } from "../../../../../apis/admin/stations";
import {
  CreateInterfaceHttpData,
  createInterfaceHttp,
  retriveInterfaceHttp,
  updateInterfaceHttp,
} from "../../../../../apis/admin/interfaces";

interface SupportDataType {
  stations?: StationAuthInterface[];
}

export const Translations = {
  update: "uredi",
  create: "novi"
}

export const InterfaceManage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const screenType = id ? "update" : "create";

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<CreateInterfaceHttpData>({
    station_device_id: 0,
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: stationData } = await listSelectStationsHttp();
      updatedSupportData.stations = stationData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const { data: responseData } = await retriveInterfaceHttp(id);
      setInitialData({
        station_device_id: responseData?.station_device_id,
        name: responseData?.name,
        description: responseData?.description,
        is_active: responseData?.is_active,
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [id]);

  const handleSubmit = async (data: CreateInterfaceHttpData) => {
    setSubmitting(true);

    const stationId = data?.station_device_id ? +data?.station_device_id : null;
    const parentStation = supportData?.stations?.find(
      (station) => station?.id === stationId
    );
    const locationId = parentStation?.location?.id;

    try {
      if (screenType === "update")
        await updateInterfaceHttp(id as string, {
          ...data,
          location_id: locationId,
        });
      else
        await createInterfaceHttp({
          ...data,
          location_id: locationId,
        });
      window.history.back();
    } catch (err) {
      //TODO Handle error
      console.log(err);
    }
    setSubmitting(false);
  };

  // Initialization
  useEffect(() => {
    fetchSupportData();
    fetchDefaultData();
  }, [fetchSupportData, fetchDefaultData]);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>INTERFEJSI</TitleXXL>
        <TitleXL>{Translations[screenType].toUpperCase()} INTERFEJS</TitleXL>
      </FlexColumn>

      <FlexColumn alignItems="center">
        {!isLoading && !isLoadingSupportData && (
          <Form<CreateInterfaceHttpData>
            onSubmit={handleSubmit}
            style={{
              dimensions: {
                maxWidth: "500px",
                width: "100%",
                flex: 1,
              },
              gap: "20px",
              justifyContent: "flex-start",
              direction: "column",
            }}
            layout={[
              {
                label: <Title>Naziv</Title>,
                type: "column",
                field: {
                  name: "name",
                  type: "text",
                  placeholder: "Unesite naziv",
                  options: {
                    required: "Naziv je potreban!",
                  },
                },
              },
              //TODO Add Tags
              {
                label: (
                  <FlexRow
                    dimensions={{ width: "100%" }}
                    alignItems="flex-end"
                    justifyContent="space-between"
                  >
                    <Title>Stanica</Title>
                    {/* TODO Add navigation to the create location screen */}
                    <Button
                      type="button"
                      onClick={() => navigate("/admin/access/stations/create")}
                    >
                      Kreiraj stanicu
                    </Button>
                  </FlexRow>
                ),
                type: "column",
                field: {
                  name: "station_device_id",
                  type: "select",
                  selectOptions: [
                    { value: 0, label: "Odaberi stanicu", disabled: true },
                    ...(supportData?.stations ?? [])?.map((station) => ({
                      value: station?.id,
                      label: `${station?.name}, ${station?.location?.name}`,
                    })),
                  ],
                  placeholder: "Odaberi stanicu",
                  options: {
                    required: "Stanica mora biti odabrana!",
                  },
                },
              },
              {
                label: <Title>Opis</Title>,
                type: "column",
                field: {
                  name: "description",
                  type: "textarea",
                  placeholder: "Unesite opis...",
                },
                style: {
                  input: {
                    dimensions: { height: "128px" },
                  },
                },
              },
            ]}
            initialData={initialData}
            buttons={{
              hasSubmit: true,
              submitDisabled: isSubmitting,
              submitLabel: <FlexItem padding="5px 50px">Potvrdi</FlexItem>,
              wrapperStyling: {
                margin: {
                  top: "50px",
                },
              },
            }}
          ></Form>
        )}
        {(isLoading || isLoadingSupportData) && <Loading />}
      </FlexColumn>
    </FlexColumn>
  );
};
