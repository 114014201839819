import { IconTypes } from "../../shared/components/Icon/types";
import { NavbarRoute } from "../../shared/components/Navbar/types";

export const AdminNavbarItems: NavbarRoute[] = [
  {
    id: "admin-access",
    icon: IconTypes.access,
    label: "Pristup",
    nestedRoutes: [
      {
        id: "admin-access-users",
        icon: IconTypes.users,
        label: "Korisnici panela",
        path: "/admin/access/users/list",
      },
      {
        id: "admin-access-card-users",
        icon: IconTypes.cardUsers,
        label: "Karticni korisnici",
        path: "/admin/access/card-users/list",
      },
      {
        id: "admin-access-stations",
        icon: IconTypes.station,
        label: "Stanice",
        path: "/admin/access/stations/list",
      },
      {
        id: "admin-access-interfaces",
        icon: IconTypes.interface,
        label: "Interfejsi",
        path: "/admin/access/interfaces/list",
      },
    ],
  },
  {
    id: "admin-locations",
    icon: IconTypes.location,
    label: "Lokacije",
    path: "/admin/locations",
  },
  {
    id: "admin-groups",
    icon: IconTypes.users,
    label: "Grupe",
    path: "/admin/groups",
  },
  {
    id: "admin-machines",
    icon: IconTypes.machine,
    label: "Masine",
    path: "/admin/machines",
  },
  {
    id: "admin-issues",
    icon: IconTypes.issue,
    label: "Problemi",
    path: "/admin/issues",
  },
  {
    id: "admin-analytics",
    icon: IconTypes.graph,
    label: "Analitika",
    nestedRoutes: [
      {
        id: "admin-analytics-machine",
        icon: IconTypes.machine,
        label: "Generalna analitika",
        path: "/admin/analytics/machine",
      },
      {
        id: "admin-analytics-emplyees",
        icon: IconTypes.cardUsers,
        label: "Mehanicari",
        path: "/admin/analytics/mechanics",
      },
    ],
  },
];
