import { AppRoute } from "../../types/route";
import FormDev from "./FormDev";
import InputDev from "./InputDev";
import InputLabelDev from "./InputLabelDev";
import NavbarDev from "./NavbarDev/NavbarDev";
import NavbarDevRoute1 from "./NavbarDev/screens/Route1";
import NavbarDevRoute2 from "./NavbarDev/screens/Route2";
import NavbarDevRoute31 from "./NavbarDev/screens/Route31";
import NavbarDevRoute32 from "./NavbarDev/screens/Route32";
import NavbarDevRoute331 from "./NavbarDev/screens/Route331";
import NavbarDevRoute332 from "./NavbarDev/screens/Route332";
import { LoadingComp } from "./Loading";
import SelectDev from "./SelectDev";
import TableDev from "./TableDev";
import { CameraImageCaptureDev } from "./CameraImageCaptureDev";
import { SandboxPreview } from "./Preview";
import TimeInputDev from "./TimeInputDev";

export const SANDBOX_ROUTS: AppRoute[] = [
  {
    id: "sandbox-input",
    path: "/sandbox/input",
    component: <InputDev />,
  },
  {
    id: "sandbox-labeled-input",
    path: "/sandbox/label/input",
    component: <InputLabelDev />,
  },
  {
    id: "sandbox-form",
    path: "/sandbox/form",
    component: <FormDev />,
  },
  {
    id: "sandbox-select",
    path: "/sandbox/select",
    component: <SelectDev />,
  },
  {
    id: "sandbox-table",
    path: "/sandbox/table",
    component: <TableDev />,
  },
  {
    id: "sandbox-navbar",
    path: "/sandbox/navbar",
    component: <NavbarDev />,
    children: [
      {
        id: "route-1",
        path: "/sandbox/navbar/route1",
        component: <NavbarDevRoute1 />,
      },
      {
        id: "route-2",
        path: "/sandbox/navbar/route2",
        component: <NavbarDevRoute2 />,
      },
      {
        id: "route-3-1",
        path: "/sandbox/navbar/route31",
        component: <NavbarDevRoute31 />,
      },
      {
        id: "route-3-2",
        path: "/sandbox/navbar/route32",
        component: <NavbarDevRoute32 />,
      },
      {
        id: "route-3-3-1",
        path: "/sandbox/navbar/route331",
        component: <NavbarDevRoute331 />,
      },
      {
        id: "route-3-3-2",
        path: "/sandbox/navbar/route332",
        component: <NavbarDevRoute332 />,
      },
    ],
  },
  {
    id: "loading",
    path: "/sandbox/route4",
    component: <LoadingComp />,
  },
  {
    id: "sandbox-cameracapture",
    path: "/sandbox/camera",
    component: <CameraImageCaptureDev />,
  },
  {
    id: "sandbox-preview",
    path: "/sandbox/preview",
    component: <SandboxPreview />,
  },
  {
    id: 'sandbox-time-input',
    path: '/sandbox/input/time',
    component: <TimeInputDev/>
  }
];
