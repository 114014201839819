import { AppRoute } from "../types/route";

import Login from "./Login";
import AdminPanel from "./admin/AdminPanel";
import { ADMIN_PANEL_ROUTES } from "./admin/routes";
import Employee from "./interface/Employee";
import GroupLeader from "./interface/GroupLeader";
import InterfaceInput from "./interface/InterfaceInput";
import InterfaceWrapper from "./interface/InterfaceWrapper";
import Mechanic from "./interface/Mechanic";
import LocationPreview from "./preview/LocationPreview";
import PreviewWrapper from "./preview/PreviewWrapper";
import Sandbox from "./sandbox/Sandbox";

import { SANDBOX_ROUTS } from "./sandbox/routes";

export const APP_ROUTS: AppRoute[] = [
  {
    id: "sandbox",
    path: "/sandbox",
    component: <Sandbox/>,
    children: SANDBOX_ROUTS
  },
  {
    id: "login",
    path: "/login",
    component: <Login/>,
  },
  {
    id: "station",
    path: "/station",
    component: <PreviewWrapper/>,
    children: [
      {
        id: "station-location-preview",
        path: "/station/location",
        component: <LocationPreview/>,
      },
    ],
  },
  {
    id: "interface",
    path: "/interface",
    component: <InterfaceWrapper/>,
    children: [
      {
        id: "interface-input",
        path: "/interface/input",
        component: <InterfaceInput/>,
      },
      {
        id: "interface-group-leader",
        path: "/interface/group-lead",
        component: <GroupLeader/>,
      },
      {
        id: "interface-mechanic",
        path: "/interface/mechanic",
        component: <Mechanic/>,
      },
      {
        id: "interface-employee",
        path: "/interface/employee",
        component: <Employee/>,
      },
    ],
  },
  {
    id: "admin-panel",
    path: "/admin",
    component: <AdminPanel/>,
    children: ADMIN_PANEL_ROUTES,
    authRequired: true,
  },
];
