export type TableRenderItemValueType =
  | number
  | boolean
  | string
  | null
  | undefined
  | Date
  | number[]
  | boolean[]
  | string[]
  | boolean[]
  | null
  | Date[];

export const valueTypes = (value: TableRenderItemValueType) => {
  if (typeof value === "object") {
    if(value === null) return "None";

    if (value instanceof Date) {
      return value.toLocaleDateString();
    }

    if (Array.isArray(value)) {
      return value?.join(",");
    }

    return JSON.stringify(value);
  }

  if(typeof value === "boolean") return value ? "True" : "False";

  return `${value}`;
};
