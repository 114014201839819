import axios from "axios";

import { json } from "../../../utilities";

import { RequestHeader, RequestQueryParamInterface } from "../types";
import { parseQueryParam } from "../parseQueryParam";

import { DefaultRequestSettings } from "./defaultRequestSettings";

export const httpBasic = <ResponseType>(
  method: string,
  url: string,
  headers: RequestHeader = {},
  queryParams: RequestQueryParamInterface = {},
  data: any = null,
  withCredentials = true
) => {
  return axios<ResponseType>({
    method: method,
    url: `${DefaultRequestSettings.BaseUrl}${url}?${parseQueryParam(
      queryParams
    )}`,
    headers: JSON.parse(
      JSON.stringify({
        ...json.clear(DefaultRequestSettings.Headers),
        ...headers,
        Authorization: localStorage.getItem("jwt-access")
          ? `Bearer ${localStorage.getItem("jwt-access")}`
          : undefined,
      })
    ),
    withCredentials: withCredentials,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "csrftoken",
    data: data,
  });
};
