import {
  ChangeEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  forwardRef,
} from "react";

import { InputStyle } from "./style/InputStyle";
import { InputPropStyle, InputProps } from "./Input";

import { theme } from "../../../constants";

import { FlexRow } from "../../styled";
import Icon from "../Icon/Icon";
import { IconTypes } from "../Icon";

export interface TimeInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "style"> {
  style?: InputPropStyle;
}

export const TimeInputRegexPattern = {
  1: /^[012]$/,
  2: /^(?:[01]?\d|2[0-3])$/,
  3: /^(?:[01]?\d|2[0-3]):$/,
  4: /^(?:[01]?\d|2[0-3]):(?:[0-5]?\d)$/,
};

export const TimeInput = forwardRef<HTMLInputElement, InputProps>(
  ({ style, onChange, ...props }, ref) => {
    const internalOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);

      if (!event.target.value) return;

      const regexExpression =
        TimeInputRegexPattern[
          event.target.value?.length <= 3
            ? (event.target.value?.length as keyof typeof TimeInputRegexPattern)
            : 4
        ];

      if (!regexExpression.test(event.target.value)) {
        event.target.value = event.target.value.slice(
          0,
          event.target.value.length - 1
        );
      }
    };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const cursorPosition = event.currentTarget.selectionStart ?? 0;

      const changeTimeValue = cursorPosition <= 2 ? "hour" : "minute";

      const splitValue = (event?.currentTarget?.value ?? "").split(":");

      const hasHour = splitValue?.[0]?.length ? true : false;
      const hasMinutes = splitValue?.[1]?.length ? true : false;

      const hourValue = hasHour ? +splitValue[0] : 0;
      const minuteValue = hasMinutes ? +splitValue[1] : 0;

      if (event.key === "ArrowUp") {
        const newHourValue =
          changeTimeValue === "hour"
            ? hourValue + 1 >= 24
              ? 0
              : hourValue + 1
            : hourValue;

        const newMinuteValue =
          changeTimeValue === "minute"
            ? minuteValue + 1 >= 59
              ? 0
              : minuteValue + 1
            : minuteValue;

        event.currentTarget.value = `${newHourValue}:${newMinuteValue}`;
        event.currentTarget.setSelectionRange(cursorPosition,cursorPosition);
        event.preventDefault()
      }
      if (event.key === "ArrowDown") {
        const newHourValue =
          changeTimeValue === "hour"
            ? hourValue - 1 < 0
              ? 23
              : hourValue - 1
            : hourValue;

        const newMinuteValue =
          changeTimeValue === "minute"
            ? minuteValue - 1 < 0
              ? 59
              : minuteValue - 1
            : minuteValue;

        event.currentTarget.value = `${newHourValue}:${newMinuteValue}`;
        event.currentTarget.setSelectionRange(cursorPosition,cursorPosition);
        event.preventDefault();
      }
    };

    return (
      <FlexRow
        dimensions={style?.dimensions}
        gap="5px"
        alignItems="center"
        position="relative"
      >
        <InputStyle
          onChange={internalOnChange}
          ref={ref}
          {...props}
          onKeyDown={onKeyDown}
          type="text"
          placeholder="--:--"
          style={style?.style}
          dimensions={{ width: "100%", height: "100%" }}
          autoComplete="off"
        />
        <span style={{ position: "absolute", right: "5px" }}>
          <Icon type={IconTypes.time} size="16px" color={theme.font.color} />
        </span>
      </FlexRow>
    );
  }
);
