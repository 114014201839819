import { httpPut } from "../../utilities";

export interface UpdateIssueHttpData {
  name?: string;
  description?: string;
  tags?: string;
  is_active?: boolean;
  is_deleted?: boolean;
}

export const updateIssueHttp = async (id: number | string, data: UpdateIssueHttpData) =>
  httpPut(`/admin/issues/${id}`, data);