import { httpPost } from "../../utilities";

export interface CreateLocationHttpData {
  description: string;
  city: string;
  name: string;
  tags: string;
}

export const createLocationHttp = async (data: CreateLocationHttpData) =>
  httpPost("/admin/locations", data);
