import { theme } from "../../constants";
import { DeviceStatusEnum } from "../../types/models";

export const DeviceStylingOnStatus = {
  [DeviceStatusEnum["No status"]]: {
    background: theme.colors.primary,
    font: theme.colors.primary,
    border: theme.colors.primary,
  },
  [DeviceStatusEnum.Free]: {
    background: theme.colors.primary,
    font: theme.colors.clean,
    border: theme.colors.primary,
  },
  [DeviceStatusEnum.Active]: {
    background: theme.colors.success,
    font: theme.colors.clean,
    border: "transparent",
  },
  [DeviceStatusEnum.Stopping]: {
    background: theme.colors.primary,
    font: theme.colors.clean,
    border: "transparent",
  },
  [DeviceStatusEnum.Inactive]: {
    background: "#B70000",
    font: theme.colors.clean,
    border: "transparent",
  },
  [DeviceStatusEnum["Need Checkup"]]: {
    background: "#00927a",
    font: theme.colors.clean,
    border: theme.colors.primary,
  }
};