import { FC } from "react";

import Input from "../Input/Input";
import Select from "../Select/Select";
import TextArea from "../TextArea/TextArea";
import { TimeInput } from "../Input";

const InputComponents: {
  [type: string]: {
    component: FC;
    props: string[] | "*";
    excludeProps?: string[];
  };
} = {
  select: {
    component: Select,
    props: [
      "options",
      "onChange",
      "placeholder",
      "name",
      "ref",
      "defaultValue",
      "forwardRef",
      "onBlur",
    ],
  },
  checkbox: {
    component: Input,
    props: [
      "defaultChecked",
      "onChange",
      "placeholder",
      "name",
      "type",
      "ref",
      "onBlur",
      "forwardRef",
    ],
  },
  textarea: {
    component: TextArea,
    props: "*",
    excludeProps: ["options", "defaultChecked"],
  },
  time: {
    component: TimeInput,
    props: "*",
    excludeProps: ["options", "defaultChecked"],
  },
};

[
  "button",
  "color",
  "date",
  "datetime-local",
  "email",
  "file",
  "hidden",
  "image",
  "month",
  "number",
  "password",
  "radio",
  "range",
  "reset",
  "search",
  "submit",
  "tel",
  "text",
  "url",
  "week",
].forEach(
  (key) =>
    (InputComponents[key] = {
      component: Input,
      props: "*",
      excludeProps: ["options", "defaultChecked"],
    })
);

export { InputComponents };
