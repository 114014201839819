import { css, styled } from "styled-components";
import { DimensionStyling, DimensionStylingProps } from "../../../styling";
import { colors, theme } from "../../../../constants";

interface DateButtonProps {
  dimensions?: DimensionStylingProps;
  backgroundType?: "before" | "in" | "after";
  isStart?: boolean;
  isEnd?: boolean;
  isSelecting?: boolean;
  isSelected?: boolean;
  color?: string;
}

export const DateButton = styled.button<DateButtonProps>`
  transition: all 0.2s;

  cursor: pointer;
  border: none;

  background: ${({ backgroundType }) =>
    backgroundType === "in" ? colors?.white?.clean : colors?.grey.gallery};

  ${({ isEnd }) =>
    isEnd
      ? css`
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          font-weight: bold;
        `
      : ""};

  ${({ isStart }) =>
    isStart
      ? css`
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          font-weight: bold;
        `
      : ""};

  ${({ dimensions }) => DimensionStyling({ ...dimensions })};

  ${({ isSelected, color }) =>
    isSelected
      ? css`
          background: ${color || theme?.colors?.primary};
        `
      : ""}

  &:hover {
    ${({ isSelected }) => {
    return css`
        background: ${isSelected
        ? colors?.blue?.java
        : colors?.grey?.silver};
      `;
  }}
  }
`;
