import { httpPost } from "../../utilities";

export interface CreateIssueHttpData {
  name: string;
  description?: string;
  tags?: string;
  is_active?: boolean;
  is_deleted?: boolean;
}

export const createIssueHttp = async (data: CreateIssueHttpData) =>
  httpPost("/admin/issues", data);