import { Nullable } from "../../types/utils";
import { httpGet } from "../utilities";

interface GetAccessRequestHttpResponse {
  ip_address: string;
  access_key?: Nullable<string>;
}

export const getAccessRequestHttp = async () =>
  httpGet<GetAccessRequestHttpResponse>("/station/request/");
