import { Outlet } from "react-router";
import { IconTypes } from "../../../shared/components/Icon/types";
import Navbar from "../../../shared/components/Navbar/Navbar";
import { NavbarRoute } from "../../../shared/components/Navbar/types";
import { FlexColumn, FlexRow } from "../../../shared/styled";
import { theme } from "../../../constants";

const NavbarDevRoutes: NavbarRoute[] = [
  {
    id: "route-1",
    path: "/sandbox/navbar/route1",
    icon: IconTypes.access,
    label: "Route 1",
  },
  {
    id: "route-2",
    path: "/sandbox/navbar/route2",
    icon: IconTypes.calendar,
    label: "Route 2",
  },
  {
    id: "route-3",
    icon: IconTypes.cardUsers,
    label: "Route 3",
    nestedRoutes: [
      {
        id: "route-3-1",
        path: "/sandbox/navbar/route31",
        icon: IconTypes.color,
        label: "Route 3.1",
      },
      {
        id: "route-3-2",
        path: "/sandbox/navbar/route32",
        icon: IconTypes.graph,
        label: "Route 3.2",
      },
      {
        id: "route-3-3",
        icon: IconTypes.graph,
        label: "Route 3.3",
        nestedRoutes: [
          {
            id: "route-3-3-1",
            path: "/sandbox/navbar/route331",
            icon: IconTypes.color,
            label: "Route 3.3.1",
          },
          {
            id: "route-3-3-2",
            path: "/sandbox/navbar/route332",
            icon: IconTypes.graph,
            label: "Route 3.3.2",
          },
        ],
      },
    ],
  },
];

export default function NavbarDev() {
  return (
    <FlexRow
      dimensions={{ width: "100%", height: "100%" }}
      overflow="hidden"
      border={`solid 1px ${theme.font.color}`}
    >
      <Navbar routes={NavbarDevRoutes} />
      <FlexColumn
        dimensions={{ flex: 1, height: "100%" }}
        overflow={{ y: "auto" }}
      >
        <Outlet />
      </FlexColumn>
    </FlexRow>
  );
}
