import { PaginationResponse } from "../../../types";
import { IssueInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export interface ListIssuesHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  city__in?: string[];
  name__in?: string[];
  tags__icontains?: string[]
}

export const listIssuesHttp = async (
  offset?: number,
  limit?: number,
  filters: ListIssuesHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<IssueInterface>>("/admin/issues", {
    ...filters,
    offset,
    limit,
    ordering,
  });