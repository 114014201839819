import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Header } from "../../../../../shared/components/Header";
import {
  Badge,
  Button,
  FlexColumn,
  FlexRow,
  Text,
  TitleXL,
  TitleXS,
  TitleXXL,
} from "../../../../../shared/styled";
import { TablePagination } from "../../../../../shared/components/Table";
import {
  CardUserInterface,
  CardUserTypesEnum,
  CardUserTypesEnumTranslated,
  GroupInterface,
  LocationInterface,
} from "../../../../../types/models";
import Table from "../../../../../shared/components/Table/Table";
import { theme } from "../../../../../constants";
import { useNavigate } from "react-router";
import Select from "../../../../../shared/components/Select/Select";
import { Filters } from "../../../../../shared/genericScreens/Filters";
import {
  listCardUsersHttp,
  updateCardUserHttp,
} from "../../../../../apis/admin/cardUsers";
import { listSelectGroupsHttp } from "../../../../../apis/admin/groups";
import { useModalProvider } from "../../../../../contexts/ModalProviderContext";
import { BarcodeModal } from "../../../../../modals/BarcodeModal";
import { listSelectLocationsHttp } from "../../../../../apis/admin/locations";

interface SupportDataType {
  groups?: GroupInterface[];
  locations?: LocationInterface[];
}

export const CardUsersList = () => {
  const navigate = useNavigate();

  const { openModal, closeModal } = useModalProvider();

  const [isLoadingSupportData, setLoadingSupportData] = useState<boolean>();
  const [supportData, setSupportData] = useState<SupportDataType>();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<CardUserInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchSupportData = useCallback(async () => {
    setLoadingSupportData(true);

    const updatedSupportData: typeof supportData = {};

    try {
      const { data: groupsData } = await listSelectGroupsHttp();
      updatedSupportData.groups = groupsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    try {
      const { data: locationsData } = await listSelectLocationsHttp();
      updatedSupportData.locations = locationsData;
    } catch (err) {
      //TODO Handle err
      console.log(err);
    }

    setSupportData(updatedSupportData);
    setLoadingSupportData(false);
  }, []);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await listCardUsersHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 50,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        console.log("UserList.fetchData", err);
      }
      setLoading(false);
    },
    [isLoading, pagination, filters, ordering]
  );

  const updatePagination = useCallback(
    (updatedPagination: typeof pagination) => {
      setPagination(updatedPagination);
      fetchData(updatedPagination);
    },
    [fetchData]
  );

  const updateOrdering = useCallback(
    (updatedOrdering: typeof ordering) => {
      setOrdering(updatedOrdering);
      fetchData(pagination, filters, updatedOrdering);
    },
    [fetchData, pagination, filters]
  );

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(pagination, updatedFilters);
    },
    [pagination, fetchData]
  );

  const handleOptionSelect = (item?: CardUserInterface) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value;
      if (value === "update") {
        navigate(`/admin/access/card-users/${item?.id}`);
      }

      if (value === "update-status") {
        setSubmitting(true);
        updateCardUserHttp(`${item?.id}`, {
          is_active: !item?.is_active,
        })
          .then(() => {
            setData(
              data?.map((dataItem) =>
                dataItem?.id === item?.id
                  ? { ...dataItem, is_active: !item?.is_active }
                  : dataItem
              )
            );
          })
          .catch((err) => {
            //TODO Handle error
            console.log(err);
          })
          .finally(() => setSubmitting(false));
        return;
      }

      if (value === "update-mobile-status") {
        setSubmitting(true);
        updateCardUserHttp(`${item?.id}`, {
          is_mobile_access_active: !item?.is_mobile_access_active,
        })
          .then(() => {
            setData(
              data?.map((dataItem) =>
                dataItem?.id === item?.id
                  ? {
                      ...dataItem,
                      is_mobile_access_active: !item?.is_mobile_access_active,
                    }
                  : dataItem
              )
            );
          })
          .catch((err) => {
            //TODO Handle error
            console.log(err);
          })
          .finally(() => setSubmitting(false));
      }

      if (value === "Barcode") {
        openModal("BarcodeModal", BarcodeModal, {
          cardCode: item?.card_code ?? "CODE128",
          close: async () => {
            closeModal("BarcodeModal");
          },
        });
        return;
      }
    };
  };

  /* Initial Fetch */
  useEffect(() => {
    fetchSupportData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>KARTICNI KORISNICI</TitleXXL>
      <Filters
        isLoading={isLoading || isLoadingSupportData}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            label: <TitleXS>GRUPA</TitleXS>,
            inputType: "select",
            type: "number",
            attribute: "group_id",
            name: "group_id",
            options: [
              { value: -1, label: "Nije selektovana" },
              ...(supportData?.groups ?? [])
                ?.filter((group) =>
                  filters.location_id
                    ? group.location?.id === Number(filters.location_id)
                    : true
                )
                ?.map((group) => ({
                  value: group?.id,
                  label: `${group?.name} (${group.location?.name}, ${group.location?.city})`,
                })),
            ],
          },
          {
            label: <TitleXS>LOKACIJA</TitleXS>,
            inputType: "select",
            type: "number",
            attribute: "location_id",
            name: "location_id",
            options: [
              { value: -1, label: "Nije selektovana" },
              ...(supportData?.locations ?? [])?.map((location) => ({
                value: location?.id,
                label: `${location?.name}, ${location?.city}`,
              })),
            ],
          },
          {
            label: <TitleXS>TIP KORISNIKA</TitleXS>,
            inputType: "select",
            type: "number",
            attribute: "type",
            name: "type",
            options: [
              { value: -1, label: "Nije sleketovano" },
              { value: CardUserTypesEnum.Employee, label: "Radnik" },
              { value: CardUserTypesEnum.Mechanic, label: "Mehanicar" },
              { value: CardUserTypesEnum.GroupLead, label: "Grupovodja" },
              { value: CardUserTypesEnum.Manager, label: "Menadzer" },
            ],
          },
          {
            label: <TitleXS>PRETRAGA</TitleXS>,
            placeholder: "Pretrazi...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<CardUserInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>
              LISTA KARTICNIH KORISNIKA ({pagination?.count ?? 0})
            </TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button
                onClick={() => navigate("/admin/access/card-users/create")}
              >
                NOVI KARTICNI KORISNIK
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "first_name",
            order: "first_name",
            label: "IME",
            width: "200px",
          },
          {
            attribute: "last_name",
            order: "last_name",
            label: "PREZIME",
            width: "200px",
          },
          {
            attribute: "type",
            order: "type",
            label: "TIP KORISNIKA",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  !item?.type ? theme.colors.danger : theme.colors.primary
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.type ? (
                    //@ts-ignore
                    CardUserTypesEnumTranslated[CardUserTypesEnum[item?.type]]
                  ) : (
                    <>NEPOZNATO</>
                  )}
                </Text>
              </Badge>
            ),
          },
          {
            attribute: "group",
            order: "group_id",
            label: "GRUPA",
            width: "100px",
            mapper: (item) => {
              if (!item?.group)
                return (
                  <Badge background={theme.colors.danger}>
                    <Text
                      weight={theme.font.weight.bold}
                      color={theme.colors.clean}
                    >
                      NEMA GRUPE
                    </Text>
                  </Badge>
                );

              const locationName = item?.group?.location?.name ?? "NEPOZNATO";
              const groupName = item?.group?.name ?? "NEPOZNATO";

              return `${groupName}, ${locationName}`;
            },
          },
          {
            attribute: "location",
            order: "location_id",
            label: "LOCATION",
            width: "200px",
            mapper: (item) => {
              if (!item?.location)
                return (
                  <Badge background={theme.colors.danger}>
                    <Text
                      weight={theme.font.weight.bold}
                      color={theme.colors.clean}
                    >
                      NEMA LOKACIJE
                    </Text>
                  </Badge>
                );

              return `${item?.location?.name}, ${item?.location?.city}`;
            },
          },
          {
            attribute: "is_mobile_access_active",
            order: "is_mobile_access_active",
            label: "MOBILNI PRISTUP",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_mobile_access_active
                    ? theme.colors.success
                    : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_mobile_access_active ? <>PRISUTAN</> : <>ODSUTAN</>}
                </Text>
              </Badge>
            ),
          },
          {
            attribute: "card_code",
            order: "card_code",
            label: "KARTICNI KOD",
            width: "200px",
          },
          {
            attribute: "is_active",
            order: "is_active",
            label: "STATUS",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_active ? theme.colors.success : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_active ? "AKTIVAN" : "NEAKTIVAN"}
                </Text>
              </Badge>
            ),
          },
          {
            label: "AKCIJE",
            flex: 1,
            mapper: (item) => (
              <Select
                key={`actions${item?.is_active ? "active" : "inactive"}`}
                disabled={isSubmitting}
                onChange={handleOptionSelect(item)}
                value="none"
                options={[
                  {
                    label: "Odaberi akciju",
                    value: "none",
                    disabled: true,
                  },
                  { label: "Uredi karticnog korisnika", value: "update" },
                  {
                    label: "Prikazi barkod",
                    value: "Barcode",
                  },
                  {
                    label: item?.is_mobile_access_active
                      ? "Oduzmi mobilni pristup"
                      : "Dozvoli mobilni pristup",
                    value: "update-mobile-status",
                  },
                  {
                    label: item?.is_active ? "Deaktiviraj" : "Aktiviraj",
                    value: "update-status",
                  },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
