import { useState } from "react";
import Form from "../../../../../shared/components/Form/Form";
import { FormLayout } from "../../../../../shared/components/Form/types/formLayout";
import { Header } from "../../../../../shared/components/Header";
import {
  FlexColumn,
  FlexItem,
  Title,
  TitleXL,
  TitleXXL,
} from "../../../../../shared/styled";
import {
  CreateUserHttpData,
  createUserHttp,
} from "../../../../../apis/admin/users";
import { useNavigate } from "react-router";

const CreateUserFormLayout: FormLayout[] = [
  {
    type: "row",
    style: { cell: { dimensions: { width: "100%", flex: 1 }, gap: "20px" } },
    children: [
      {
        label: <TitleXL>Korisnicka autorizacija</TitleXL>,
        type: "column",
        style: {
          cell: {
            gap: "20px",
          },
        },
        children: [
          {
            label: <Title>Korisnicko ime</Title>,
            type: "column",
            field: {
              name: "username",
              type: "text",
              placeholder: "Unesite korisnicko ime...",
              options: {
                required: "Korisnicko ime je potrebno!",
              },
            },
          },
          {
            label: <Title>Lozinka</Title>,
            type: "column",
            field: {
              name: "password",
              type: "text",
              placeholder: "Unesite lozinku...",
              options: {
                required: "Lozinka je potrebna!",
              },
            },
          },
        ],
      },
      {
        type: "column",
        label: <TitleXL>Korisnicki nivo</TitleXL>,
        style: {
          cell: {
            gap: "20px",
          },
        },
        children: [
          {
            label: <Title>Admin</Title>,
            type: "row",
            field: {
              name: "is_admin",
              type: "checkbox",
            },
          },
          {
            label: <Title>Osoblje</Title>,
            type: "row",
            field: {
              name: "is_staff",
              type: "checkbox",
            },
          },
          {
            label: <Title>Aktivnost</Title>,
            type: "row",
            field: {
              name: "is_active",
              type: "checkbox",
            },
          },
        ],
      },
    ],
  },
];

export const UserCreate = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const submitHandler = async (data: CreateUserHttpData) => {
    setLoading(true);
    try {
      await createUserHttp(data);
      navigate("/admin/access/users/list");
    } catch (err) {
      // TODO Push notification
      // TODO Handle error
      console.log("UserCreate.submitHandler", err);
    }
    setLoading(false);
  };

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header backToRoute={true} />
      <FlexColumn>
        <TitleXXL>KORISNICI PANELA</TitleXXL>
        <TitleXL>KREIRAJ KORISNIKA PANELA</TitleXL>
      </FlexColumn>

      <Form<CreateUserHttpData>
        onSubmit={submitHandler}
        style={{
          dimensions: {
            maxWidth: "500px",
            width: "100%",
            flex: 1,
          },
          gap: "20px",
          justifyContent: "center",
          direction: "column",
        }}
        layout={CreateUserFormLayout}
        buttons={{
          hasSubmit: true,
          submitDisabled: isLoading,
          submitLabel: <FlexItem padding="5px 50px">Potvrdi</FlexItem>,
          wrapperStyling: {
            margin: {
              top: "50px",
            },
          },
        }}
      ></Form>
    </FlexColumn>
  );
};
