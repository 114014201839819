import type CSS from "csstype";
import { HTMLAttributes } from "react";

import { MouseEventHandler } from 'react';
import { useRef } from "react";

import { IconStyle } from "./style";
import { IconTypes } from "./types";


interface IconProps {
  type?: IconTypes;
  color?: CSS.Property.Color;
  size?: CSS.Property.Width;
  onClick?: MouseEventHandler<HTMLElement>;
  style?: HTMLAttributes<HTMLElement>["style"];
}

export default function Icon({
  type,
  color,
  size,
  onClick,
  style
}: IconProps) {
  const ref = useRef<HTMLElement>(null);
  return (
    <IconStyle
      ref={ref}
      color={color}
      width={size}
      height={size}
      onClick={onClick}
      src={`/assets/icons/${type}.svg`}
      style={style}
    />
  );
};
