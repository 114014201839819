import { useRef, useState } from "react";
import { CameraImageCapture } from "../../shared/components/CameraImageCapture";
import { cardReaderHttp } from "../../apis/interface/cardReaderHttp";
import { FlexColumn } from "../../shared/styled";
import { theme } from "../../constants";

export const CameraImageCaptureDev = () => {
  const isLoadingRef = useRef<boolean>(false);
  const [, setLoading] = useState<boolean>(false);

  const processCaptured = async (base64: string) => {
    isLoadingRef.current = true;
    setLoading(true);
    try {
      const { data } = await cardReaderHttp("123", base64);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    isLoadingRef.current = false;
  };

  const onCapture = (data: string | null) => {
    console.log(data);
    if (isLoadingRef.current || !data) return;
    processCaptured(data);
  };

  return (
    <FlexColumn
      dimensions={{ maxHeight: "500px", width: "auto", height: "100%" }}
      position="relative"
    >
      <FlexColumn
        position="absolute"
        style={{ top: 0, left: 0, opacity: 0.5, mixBlendMode: "darken" }}
        background={theme.font.color}
        dimensions={{ height: "100%", width: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <FlexColumn
          dimensions={{ height: "80%", width: "80%" }}
          position="relative"
          background={theme.colors.clean}
          style={{ mixBlendMode: "hard-light" }}
        />
      </FlexColumn>

      <CameraImageCapture
        captureTimeout={1000}
        onCapture={onCapture}
        dimensions={{ width: "100%", maxHeight: "100%" }}
      />
    </FlexColumn>
  );
};
