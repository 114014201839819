import { PaginationResponse } from "../../../types";
import { InterfaceAuthInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export interface ListInterfacesHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  location_id__in?: number[];
  location_id__exact?: number;
  station_device_id__in?: number[];
}

export const listInterfacesHttp = async (
  offset: number,
  limit: number,
  filters: ListInterfacesHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<InterfaceAuthInterface>>("/admin/interfaces", {
    ...filters,
    offset,
    limit,
    ordering,
  });
