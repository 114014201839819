import Webcam from "react-webcam";
import { useCallback, useEffect, useRef } from "react";
import { FlexColumn, FlexColumnProps } from "../../styled";

interface CameraImageCaptureProps extends FlexColumnProps {
  onCapture?: (base64Data: string | null) => void;
  constrains?: MediaStreamConstraints["video"];
  captureTimeout?: number;
}

export const CameraImageCapture = ({
  onCapture,
  constrains,
  style,
  captureTimeout,
  ...rest
}: CameraImageCaptureProps) => {
  const webcamRef = useRef<Webcam>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const capture = useCallback(() => {
    onCapture &&
      webcamRef?.current &&
      onCapture(webcamRef.current.getScreenshot());
    startCaptureOnFramerate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, onCapture]);

  const startCaptureOnFramerate = useCallback(() => {
    if (!captureTimeout || !onCapture) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(capture, captureTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (captureTimeout && onCapture) startCaptureOnFramerate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captureTimeout]);

  return (
    <FlexColumn {...rest}>
      <Webcam
        videoConstraints={constrains}
        audio={false}
        width="100%"
        height="100%"
        screenshotFormat="image/jpeg"
        ref={webcamRef}
      />
    </FlexColumn>
  );
};
