import { httpPost } from "../../utilities";

export const CREATE_USER_API = "/admin/users";

export interface CreateUserHttpData {
  username: string;
  password: string;

  is_admin?: boolean;
  is_staff?: boolean;
  is_active?: boolean;
}

export const createUserHttp = async (data: CreateUserHttpData) =>
  httpPost(CREATE_USER_API, data);
