import Form from "../../shared/components/Form/Form";
import { FlexColumn } from "../../shared/styled";

interface FormDevDataSchema {
  string?: string;
  number?: number;
  boolean?: boolean;
  date?: string;
  selected?: number;
  textArea?: string;
}

export default function FormDev() {
  return (
    <FlexColumn dimensions={{ maxWidth: "100vw" }}>
      <Form<FormDevDataSchema>
        buttons={{hasSubmit: true}}
        onSubmit={(data) => console.log(data)}
        initialData={{
          string: "Test",
          number: 1,
          date: "2023-11-18",
          boolean: true,
          selected: 2,
        }}
        style={{ direction: "column" }}
        layout={[
          {
            type: "row",
            style: {
              cell: {
                dimensions: {
                  width: "100%",
                },
                justifyContent: "space-between",
                gap: "20px",
              },
            },
            children: [
              // "string" attribute
              {
                type: "column",
                field: {
                  name: "string",
                  type: "text",
                },
                label: "Text",
                style: {
                  cell: {
                    dimensions: {
                      flex: 1,
                    },
                  },
                },
              },
              // "number" attribute
              {
                type: "column",
                field: {
                  name: "number",
                  type: "number",
                },
                label: "Number",
                style: {
                  cell: {
                    dimensions: {
                      flex: 1,
                    },
                  },
                },
              },
            ],
          },
          {
            type: "row",
            style: {
              cell: {
                dimensions: {
                  width: "100%",
                },
                justifyContent: "space-between",
                gap: "20px",
              },
            },
            children: [
              {
                type: "column",
                field: {
                  name: "date",
                  type: "date",
                },
                style: {
                  cell: {
                    dimensions: { width: "100%" },
                  },
                },
                label: "Date",
              },
              {
                type: "column",
                field: {
                  name: "boolean",
                  type: "checkbox",
                },
                label: "Boolean",
                style: {
                  cell: {
                    dimensions: {
                      flex: 1,
                    },
                  },
                },
              },
            ],
          },
          {
            type: "row",
            style: {
              cell: {
                dimensions: {
                  width: "100%",
                },
                justifyContent: "space-between",
                gap: "20px",
              },
            },
            children: [
              {
                type: "column",
                field: {
                  name: "selected",
                  type: "select",
                  selectOptions: [
                    {
                      label: "Option 1",
                      value: 1,
                    },
                    {
                      label: "Option 2",
                      value: 2,
                    },
                    {
                      label: "Option 3",
                      value: 3,
                    },
                  ],
                },
                style: {
                  cell: {
                    dimensions: { width: "100%" },
                  },
                },
                label: "Select",
              },
              {
                type: "column",
                field: {
                  name: "textArea",
                  type: "textarea",
                },
                style: {
                  cell: {
                    dimensions: { width: "100%" },
                  },
                  input: {
                    dimensions: {
                      width: "100%",
                      height: "128px"
                    }
                  }
                },
                label: "Text Area"
              }
            ],
          },
        ]}
      />
    </FlexColumn>
  );
}
