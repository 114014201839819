
import CSS from 'csstype';
import styled from 'styled-components';

import { DimensionStyling, DimensionStylingProps, MarginStyling, MarginStylingProps, PaddingStyling, PaddingStylingProps } from '../../styling';


export interface FlexItemProps {
  flex?: number;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  dimensions?: DimensionStylingProps;
  margin?: MarginStylingProps;
  justifySelf?: CSS.Property.JustifySelf;
  alignSelf?: CSS.Property.AlignSelf;
  background?: CSS.Property.Background;
  textColors?: CSS.Property.Color;
  gap?: CSS.Property.Gap;
}

export const FlexItem = styled.div<FlexItemProps>`
  box-sizing: border-box;
  flex: ${({ flex }) => flex};

  color: ${({ textColors }) => textColors};

  justify-self: ${({ justifySelf }) => justifySelf};
  align-self: ${({ alignSelf }) => alignSelf};
  gap: ${({ gap }) => gap};
  
  background: ${({ background }) => background};

  ${({ padding }) => PaddingStyling(padding ?? {})};
  ${({ dimensions }) => DimensionStyling({ ...dimensions })};
  ${({ margin }) => MarginStyling({ ...margin })};
`;
