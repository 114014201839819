import { httpPost } from "../../utilities";

export interface CreateGroupHttpData {
  location_id?: number;
  name?: string;
  description?: string;
  tags?: string;

  start_work_time?: string;
  end_work_time?: string;
}

export const createGroupHttp = async (data: CreateGroupHttpData) =>
  httpPost("/admin/groups", data);
