import { useRef, useState } from "react"

export const useStateRef = <Type>(initial?: Type) => {
  const [state, setState] = useState<Type|undefined>(initial);
  const ref = useRef<Type|undefined>(initial);

  const set = (value?: Type) => {
    ref.current = value;
    setState(value);
  }

  return {set, refValue: ref.current, stateValue: state};
}