import { useEffect, useState } from "react";
import {
  CardUserInterface,
  DeviceInterface,
  GroupInterface,
} from "../../types/models";
import { useNavigate } from "react-router";
import { useCacheProvider } from "../../contexts/Caching";
import { interfaceAccessKey, interfaceCardUserKey } from "./cacheKeys";
import { getGroupLeadInterfaceHttp } from "../../apis/interface/getGroupLeadInterfaceHttp";
import {
  Button,
  FlexColumn,
  FlexRow,
  Image,
  Paragraph,
  Text,
  Title,
  TitleXXL,
} from "../../shared/styled";
import { theme } from "../../constants";
import Icon from "../../shared/components/Icon/Icon";
import { IconTypes } from "../../shared/components/Icon";
import { Loading } from "../../shared/components/Loading";
import { useModalProvider } from "../../contexts/ModalProviderContext";
import { ScannerModal } from "../../modals/ScannerModal";
import Label from "../../shared/components/Label/Label";
import { DeviceBox } from "../components";
import { groupLeadStopDeviceHttp } from "../../apis/interface/groupLeadStopDeviceHttp";
import { ConfirmationModal } from "../../modals";

interface SupportData {
  cardUsers: CardUserInterface[];
  group: GroupInterface;
  devices: DeviceInterface[];
}

export default function GroupLeader() {
  const navigate = useNavigate();
  const { getKey, deleteKey } = useCacheProvider();
  const { closeModal, openModal } = useModalProvider();

  const [accessKey, setAccessKey] = useState<string>();
  const [cardCodeUser, setCardCodeUser] = useState<CardUserInterface>();

  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const [supportData, setSupportData] = useState<SupportData>();
  const [isLoadingSupportData, setLoadingSupportData] =
    useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<
    CardUserInterface | undefined
  >();

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const backToInput = () => {
    deleteKey(interfaceCardUserKey);
    navigate("/interface/input");
  };

  const loadSupportData = async () => {
    if (!accessKey || !cardCodeUser?.card_code) return;

    setLoadingSupportData(true);
    try {
      const { data } = await getGroupLeadInterfaceHttp(
        accessKey,
        cardCodeUser?.card_code
      );
      setSupportData({
        cardUsers: data.card_users,
        group: data.group,
        devices: data.devices,
      });
    } catch (err) {
      console.log(err);
      //TODO Handle Error
    }
    setLoadingSupportData(false);
  };

  const loadCachedData = () => {
    const cachedAccessKey = getKey(interfaceAccessKey, undefined);
    const cardUser = getKey(interfaceCardUserKey, undefined);

    if (!cachedAccessKey) navigate("/interface");
    setAccessKey(cachedAccessKey as string);

    if (!cardUser) navigate("/interface/input");
    setCardCodeUser(cardUser as CardUserInterface);
  };

  const submit = async (deviceId: number, shouldStop?: boolean) => {
    if (!accessKey || !cardCodeUser?.card_code || !selectedUser?.id) return;

    setSubmitting(true);

    try {
      await groupLeadStopDeviceHttp(
        accessKey,
        cardCodeUser?.card_code,
        deviceId,
        selectedUser?.id,
        shouldStop
      );
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError("Something went wrong!");
    }

    setSubmitting(false);
  };

  useEffect(() => {
    loadCachedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessKey && cardCodeUser) loadSupportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessKey, cardCodeUser]);

  return (
    <>
      {isLoadingSupportData && (
        <FlexColumn
          dimensions={{ width: "100vw", height: "100vh" }}
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </FlexColumn>
      )}
      {!isLoadingSupportData && (
        <FlexColumn dimensions={{ width: "100vw", height: "100vh" }}>
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            padding="8px"
            border={{ bottom: `solid 1px ${theme.border.color}` }}
            position="sticky"
            style={{ top: 0, left: 0, zIndex: 1 }}
            background={theme.colors.clean}
          >
            <FlexRow alignItems="center" gap="10px">
              <Icon
                type={IconTypes.close}
                color={theme.font.color}
                size="48px"
                onClick={() =>
                  selectedUser ? setSelectedUser(undefined) : backToInput()
                }
              />

              <TitleXXL size="32px">
                {supportData?.group?.name}, {supportData?.group?.location?.name}
              </TitleXXL>
            </FlexRow>
            <Image src="/assets/images/logo.png" />
          </FlexRow>
          <FlexColumn dimensions={{ flex: 1, width: "100%" }}>
            {!isSuccess && !error && !isSubmitting && (
              <FlexColumn
                dimensions={{ flex: 1, width: "100%" }}
                padding="10px"
                overflow={{ y: "auto" }}
              >
                {!selectedUser && (
                  <>
                    <FlexRow flexWrap="wrap" gap="10px">
                      {supportData?.cardUsers?.map((cardUser) => (
                        <Button
                          dimensions={{ minHeight: "64px" }}
                          onClick={() => setSelectedUser(cardUser)}
                        >
                          <Text size="20px" color={theme.colors.clean}>
                            {cardUser?.first_name} {cardUser?.last_name}
                          </Text>
                        </Button>
                      ))}
                    </FlexRow>

                    <FlexColumn
                      position="fixed"
                      style={{
                        bottom: "32px",
                        right: "32px",
                        cursor: "pointer",
                      }}
                      background={theme.colors.primary}
                      border={{ radius: "50%" }}
                      padding="10px"
                      onClick={() =>
                        openModal("CardCodereaderModal", ScannerModal, {
                          title: "Scan for employee",
                          close: () => closeModal("CardCodereaderModal"),
                          httpType: "interface",
                          onSuccess: (data: string) => {
                            const scannedCardUser =
                              supportData?.cardUsers?.find(
                                (cardUser) => cardUser.card_code === data
                              );
                            if (!scannedCardUser) {
                              //TODO Add push notification
                            } else {
                              setSelectedUser(scannedCardUser);
                            }

                            closeModal("CardCodereaderModal");
                          },
                          accessKey: accessKey,
                        })
                      }
                    >
                      <Icon
                        type={IconTypes.barcodeScan}
                        color={theme.colors.clean}
                        size="48px"
                      />
                    </FlexColumn>
                  </>
                )}
                {selectedUser && (
                  <>
                    <FlexRow dimensions={{ width: "100%" }} padding="10px 0">
                      <Label label={<Title>Selektuj radnika</Title>}>
                        {selectedUser.first_name} {selectedUser.last_name}
                      </Label>
                    </FlexRow>
                    <FlexRow
                      gap="10px"
                      flexWrap="wrap"
                      overflow={{ y: "auto" }}
                    >
                      {supportData?.devices.map((device) => (
                        <DeviceBox
                          data={device}
                          onClick={() => {
                            openModal("SetIssueType", ConfirmationModal, {
                              close: () => closeModal("SetIssueType"),
                              title: "Select action",
                              cancel: () => {
                                closeModal("SetIssueType");
                              },
                              body: (
                                <FlexColumn gap="20px">
                                  <Paragraph>
                                    Masina {device?.code} ce biti dodjeljena
                                    radniku
                                    {` ${selectedUser?.first_name} ${selectedUser?.last_name}`}
                                  </Paragraph>
                                  <FlexColumn gap="10px">
                                    <Paragraph>Koju akciju zelite?</Paragraph>
                                    <Button
                                      onClick={() => {
                                        submit(device?.id, true);
                                        closeModal("SetIssueType");
                                      }}
                                    >
                                      Zastoj
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        submit(device?.id, false);
                                        closeModal("SetIssueType");
                                      }}
                                    >
                                      Usporen rad
                                    </Button>
                                  </FlexColumn>
                                </FlexColumn>
                              ),
                            });
                          }}
                        />
                      ))}
                    </FlexRow>
                  </>
                )}
              </FlexColumn>
            )}
            {isSubmitting && <Loading />}
            {error && (
              <FlexColumn
                dimensions={{ width: "100%", height: "100%" }}
                gap="20px"
                justifyContent="center"
                alignItems="center"
                onClick={() => backToInput()}
              >
                <TitleXXL color={theme.colors.danger}>
                  Pritisni za izlaz
                </TitleXXL>
                <Image
                  dimensions={{
                    maxWidth: "300px",
                    width: "100%",
                    height: "auto",
                  }}
                  src="/assets/images/click.png"
                />
                <Text color={theme.colors.danger}>{error}</Text>
              </FlexColumn>
            )}
            {isSuccess && (
              <FlexColumn
                dimensions={{ width: "100%", height: "100%" }}
                gap="20px"
                justifyContent="center"
                alignItems="center"
                onClick={() => backToInput()}
              >
                <TitleXXL>Stanje uredaja je promjenjeno!</TitleXXL>
                <Image
                  dimensions={{
                    maxWidth: "300px",
                    width: "100%",
                    height: "auto",
                  }}
                  src="/assets/images/click.png"
                />
                <Text color={theme.colors.success}>Pritisni za izlaz!</Text>
              </FlexColumn>
            )}
          </FlexColumn>
        </FlexColumn>
      )}
    </>
  );
}
