import { Nullable } from "../../../types/utils";
import { httpPut } from "../../utilities";

export const CARD_USER_UPDATE_API = "/admin/card-users";

export interface UpdateCardUserHttpData {
  first_name?: string;
  last_name?: string;
  card_code?: string;
  type?: number;

  group_id?: Nullable<number>;
  location_id?: Nullable<number>;

  is_active?: boolean;
  is_mobile_access_active?: boolean; 
}

export const updateCardUserHttp = async (id: number|string, data: UpdateCardUserHttpData) =>
  httpPut(`/admin/card-users/${id}`, data);
