import { Nullable } from "../../../types/utils";
import { httpPut } from "../../utilities";

export interface UpdateStationHttpData {
  location_id?: Nullable<number>;
  name?: string;
  description?: string;
  is_active?: boolean;
}

export const updateStationHttp = async (id: number|string, data: UpdateStationHttpData) =>
  httpPut(`/admin/stations/${id}`, data);
