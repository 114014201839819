import { createContext } from "react";

export interface CacheContextType {
  cache: {[key: string]: any};
  getKey: (key: string, defaultValue?: any) => any;
  setKey: (key: string, value: unknown) => void;
  deleteKey: (key: string) => void;
}

export const CacheContext = createContext<CacheContextType>({
  cache: {},
  getKey: (key: string, defaultValue?: any) => {},
  setKey: (key: string, value: any) => {},
  deleteKey: (key: string) => {}
});
