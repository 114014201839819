import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Header } from "../../../../shared/components/Header";
import {
  Button,
  Badge,
  FlexColumn,
  FlexRow,
  Text,
  TitleXL,
  TitleXXL,
} from "../../../../shared/styled";
import { listIssuesHttp, updateIssueHttp } from "../../../../apis/admin/issues";
import { IssueInterface } from "../../../../types/models";
import { TablePagination } from "../../../../shared/components/Table";
import Table from "../../../../shared/components/Table/Table";
import { theme } from "../../../../constants";
import Select from "../../../../shared/components/Select/Select";
import { Filters } from "../../../../shared/genericScreens/Filters";

export const IssuesList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [ordering, setOrdering] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    offset: 0,
    limit: 10,
    count: 0,
  });

  const [data, setData] = useState<IssueInterface[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const fetchData = useCallback(
    async (
      _pagination: typeof pagination = pagination,
      _filters: typeof filters = filters,
      _ordering: typeof ordering = ordering
    ) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const { data } = await listIssuesHttp(
          _pagination?.offset ?? 0,
          _pagination?.limit ?? 50,
          _filters,
          _ordering
        );

        setData(data?.results ?? []);
        data?.count !== _pagination?.count &&
          setPagination({ ..._pagination, count: data?.count });
      } catch (err) {
        console.log("IssuesList.fetchData", err);
      }
      setLoading(false);
    },
    [isLoading, pagination, filters, ordering]
  );

  const updatePagination = useCallback(
    (updatedPagination: typeof pagination) => {
      setPagination(updatedPagination);
      fetchData(updatedPagination);
    },
    [fetchData]
  );

  const updateOrdering = useCallback(
    (updatedOrdering: typeof ordering) => {
      setOrdering(updatedOrdering);
      fetchData(pagination, filters, updatedOrdering);
    },
    [fetchData, pagination, filters]
  );

  const updateFilters = useCallback(
    (updatedFilters: typeof filters) => {
      setFilters(updatedFilters);
      fetchData(pagination, updatedFilters);
    },
    [pagination, fetchData]
  );

  const handleOptionSelect = (item?: IssueInterface) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value;
      if (value === "update") {
        navigate(`/admin/issue/${item?.id}`);
      }

      if (value === "update-status") {
        setSubmitting(true);
        updateIssueHttp(`${item?.id}`, {
          is_active: !item?.is_active,
        })
          .then(() => {
            setData(
              data?.map((dataItem) =>
                dataItem?.id === item?.id
                  ? { ...dataItem, is_active: !item?.is_active }
                  : dataItem
              )
            );
          })
          .catch((err) => {
            //TODO Handle error
            console.log(err);
          })
          .finally(() => setSubmitting(false));
      }
    };
  };

  /*Inital Fetch*/
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexColumn padding="10px" gap="20px">
      <Header />
      <TitleXXL>TIPOVI PROBLEMA</TitleXXL>
      <Filters
        isLoading={isLoading}
        values={filters}
        setValues={updateFilters}
        items={[
          {
            placeholder: "Pretraga...",
            inputType: "input",
            type: "text",
            attribute: "search",
            name: "search",
          },
        ]}
      />
      <Table<IssueInterface>
        title={
          <FlexRow
            dimensions={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
            padding={"5px 0"}
          >
            <TitleXL size={`${theme.font.size.xl}px`}>
              Lista tipva problema ({pagination?.count})
            </TitleXL>
            <FlexRow justifyContent="space-between" alignItems="center">
              <Button onClick={() => navigate("/admin/issue/create")}>
                Kreiraj tip problema
              </Button>
            </FlexRow>
          </FlexRow>
        }
        isLoading={isLoading}
        schema={[
          {
            attribute: "id",
            order: "id",
            label: "ID",
            width: "100px",
            mapper: (item) => `#${item?.id}`,
          },
          {
            attribute: "name",
            order: "name",
            label: "NAZIV",
            width: "200px",
          },
          {
            attribute: "created_at",
            order: "created_at",
            label: "KREIRANO NA",
            width: "200px",
            mapper: (item) =>
              item?.created_at
                ? new Date(item?.created_at)?.toLocaleString()
                : "NEDOSTUPAN",
          },
          {
            attribute: "is_active",
            order: "is_active",
            label: "STATUS",
            width: "100px",
            mapper: (item) => (
              <Badge
                background={
                  item?.is_active ? theme.colors.success : theme.colors.danger
                }
              >
                <Text
                  weight={theme.font.weight.bold}
                  color={theme.colors.clean}
                >
                  {item?.is_active ? "Aktivan" : "Neaktivan"}
                </Text>
              </Badge>
            ),
          },
          {
            label: "AKCIJE",
            width: "200px",
            mapper: (item) => (
              <Select
                disabled={isSubmitting}
                key={`actions${item?.is_active ? "active" : "inactive"}`}
                onChange={handleOptionSelect(item)}
                value="none"
                options={[
                  {
                    label: "Odaberite akciju",
                    value: "none",
                    disabled: true,
                  },
                  { label: "Uredi tip problema", value: "update" },
                  {
                    label: item?.is_active ? "Deaktiviraj" : "Aktiviraj",
                    value: "update-status",
                  },
                ]}
              />
            ),
          },
        ]}
        data={data ?? []}
        setPagination={updatePagination}
        pagination={pagination}
        setOrdering={updateOrdering}
        ordering={ordering}
      />
    </FlexColumn>
  );
};
