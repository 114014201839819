import { PaginationResponse } from "../../../types";
import { StationAuthInterface } from "../../../types/models";
import { httpGet } from "../../utilities";

export interface ListStationsHttpFilters {
  search?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  created_at__gt?: string;
  created_at__lt?: string;
  location_id__in?: number[];
  location_id__exact?: number;
}

export const listStationsHttp = async (
  offset: number,
  limit: number,
  filters: ListStationsHttpFilters = {},
  ordering: string[] = []
) =>
  httpGet<PaginationResponse<StationAuthInterface>>("/admin/stations", {
    ...filters,
    offset,
    limit,
    ordering,
  });
