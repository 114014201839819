import { useEffect, useState } from "react";
import { FlexColumn, FlexRow } from "../../styled";
import {
  DECEMBER_INDEX,
  JANUARY_INDEX,
  MonthIndex,
  WEEK_DAYS_NAMES,
  MONDAY_INDEX,
  TUESDAY_INDEX,
  WEDNESDAY_INDEX,
  THURSDAY_INDEX,
  FRIDAY_INDEX,
  SATURDAY_INDEX,
} from "./constants";
import { createDate, getMonthInfo, isDateInRange } from "./utilities";
import { useGlobalEvents } from "../../../contexts/useGlobalEvents";

import { ArrowButton, DateButton, DateRangeWrapper, Value } from "./components";

import { DateRangeValue } from "./types";
import { SUNDAY_INDEX } from "../WorkCalendar/constants/days";

interface DateRangeInterface {
  value?: DateRangeValue;
  setValue?: (value: DateRangeValue) => void;
  color?: string;
  weekDay?: number | undefined;
}

const NUMBER_OF_BUTTONS = 42;

export const DateRange = ({ value, setValue, color, weekDay }: DateRangeInterface) => {
  const { setKeyPressHandler } = useGlobalEvents();

  const [year, setYear] = useState<number>(new Date()?.getFullYear());
  const [month, setMonth] = useState<MonthIndex>(
    new Date()?.getMonth() as MonthIndex
  );

  const [isSelecting, setSelecting] = useState<boolean>(false);

  const [intervalValue, setInternalValue] = useState<DateRangeValue>({});

  const previusMonthInfo = getMonthInfo(
    (month - 1 < 0 ? 11 : month - 1) as MonthIndex,
    year
  );
  const monthInfo = getMonthInfo(month, year);

  const previusMonth = () => {
    if (month === 0) {
      setYear(year - 1);
      setMonth(DECEMBER_INDEX);
      return;
    }

    setMonth((month - 1) as MonthIndex);
  };

  const nextMonth = () => {
    if (month === 11) {
      setYear(year + 1);
      setMonth(JANUARY_INDEX);
      return;
    }

    setMonth((month + 1) as MonthIndex);
  };


  //! This is the function that will be called when the user clicks on a day is unfinished

  // const handleWeekdayClick = (dayIndex: number) => {
  //   let startDay = dayIndex + 1 - monthInfo?.startDay;

  //   // Ensure the start day is within the current month
  //   if (startDay < 1) {
  //     startDay += 7;
  //   }
  //   const startDate = createDate(year, month, startDay);

  //   // Calculate the end date for the same row, ensuring it covers the entire month
  //   let endDay =
  //     startDay + (Math.ceil(monthInfo?.numberOfDays / 7) - 1) * 7;

  //   endDay = Math.min(endDay, monthInfo?.numberOfDays); // Ensure it doesn't go beyond the current month
  //   const endDate = createDate(year, month, endDay);

  //   setValue && setValue({ start: startDate, end: endDate });
  // };

  useEffect(() => {
    setKeyPressHandler &&
      setKeyPressHandler((keys) => {
        if (isSelecting && keys?.ArrowLeft) previusMonth();
        if (isSelecting && keys?.ArrowRight) nextMonth();
      });

    return () => {
      setKeyPressHandler && setKeyPressHandler(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelecting, year, month]);

  return (
    <DateRangeWrapper>
      <FlexColumn gap="10px" padding={{ left: "16px", right: "16px" }} justifyContent="center" dimensions={{ width: `${48 * 7}px`, height: "50px" }}>
        <FlexRow justifyContent="space-between" gap="16px">
          <ArrowButton arrow="left" onClick={previusMonth} />
          <Value>{year}</Value>
          <Value>{monthInfo?.name}</Value>
          <ArrowButton arrow="right" onClick={nextMonth} />
        </FlexRow>
      </FlexColumn>
      <FlexRow>
        <FlexRow>
          {Object.keys(WEEK_DAYS_NAMES)?.map((key) => (
            <span
              style={{
                width: "48px",
                height: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold",
                background: (() => {
                  switch (weekDay) {
                    case MONDAY_INDEX:
                      return color;
                    case TUESDAY_INDEX:
                      return color;
                    case WEDNESDAY_INDEX:
                      return color;
                    case THURSDAY_INDEX:
                      return color;
                    case FRIDAY_INDEX:
                      return color;
                    case SATURDAY_INDEX:
                      return color;
                    case SUNDAY_INDEX:
                      return color;
                    default:
                      return "#6D6E72";
                  }
                })(),
                color: "#FFFFFF",
              }}>
              {((WEEK_DAYS_NAMES as any)[key] as string)?.slice(0, 3)}
            </span>
          ))}
        </FlexRow>
      </FlexRow>
      <FlexRow flexWrap="wrap" dimensions={{ width: `${48 * 7}px` }}>
        {Array(NUMBER_OF_BUTTONS)
          ?.fill(null)
          ?.map((_, index) => {
            const isInMonthRange =
              monthInfo?.startDay <= index &&
              index + 1 - monthInfo?.startDay <= monthInfo?.numberOfDays;

            const date = (() => {
              if (isInMonthRange)
                return createDate(year, month, index - monthInfo?.startDay + 1);

              // Case when index in previus month
              if (index < monthInfo?.startDay)
                return createDate(
                  JANUARY_INDEX <= month - 1 ? year : year - 1,
                  JANUARY_INDEX <= month - 1 ? month - 1 : DECEMBER_INDEX,
                  previusMonthInfo?.numberOfDays - monthInfo?.startDay + index + 1
                );

              // Case when index is in next month
              return createDate(
                month + 1 <= DECEMBER_INDEX ? year : year + 1,
                month + 1 <= DECEMBER_INDEX ? month + 1 : JANUARY_INDEX,
                index - monthInfo?.numberOfDays - monthInfo?.startDay + 1
              );
            })();

            const startDate =
              intervalValue?.start &&
                intervalValue?.end &&
                intervalValue?.start < intervalValue?.end
                ? intervalValue?.start
                : intervalValue?.end;
            const endDate =
              intervalValue?.start &&
                intervalValue?.end &&
                intervalValue?.start < intervalValue?.end
                ? intervalValue?.end
                : intervalValue?.start;

            const isInIntervalValueRange =
              intervalValue?.start &&
              isDateInRange(
                startDate as Date,
                endDate || (startDate as Date),
                date
              );

            const isValueInRange =
              value?.start &&
              value?.end &&
              isDateInRange(value?.start, value?.end, date);

            return (
              <DateButton
                dimensions={{ width: "48px", height: "48px" }}
                key={`${index}${date.toString()}`}
                isStart={intervalValue?.start === date || value?.start === date}
                isEnd={intervalValue?.end === date || value?.end === date}
                backgroundType={
                  isInMonthRange
                    ? "in"
                    : index < monthInfo?.startDay
                      ? "before"
                      : "after"
                }
                color={color}
                isSelected={
                  isSelecting ? isInIntervalValueRange : isValueInRange
                }
                isSelecting={isSelecting}
                onClick={() => {
                  if (!isSelecting) {
                    setInternalValue({ start: date, end: date });
                    setSelecting(true);
                    return;
                  }

                  setValue && setValue({ start: startDate, end: endDate });
                  setInternalValue({ start: undefined, end: undefined });
                  setSelecting(false);
                }}
                onMouseEnter={() =>
                  isSelecting &&
                  setInternalValue({ ...intervalValue, end: date })
                }
              >
                {/* Case when we are in month day index range */}
                {isInMonthRange && <>{index - monthInfo?.startDay + 1}</>}

                {!isInMonthRange && (
                  <>
                    {/* Case when we are in previus month range */}
                    {index < monthInfo?.startDay && (
                      <>
                        {previusMonthInfo?.numberOfDays -
                          monthInfo?.startDay +
                          index +
                          1}
                      </>
                    )}

                    {/* Case when we are in next month range */}
                    {monthInfo?.numberOfDays <
                      index + 1 - monthInfo?.startDay && (
                        <>
                          {index -
                            monthInfo?.numberOfDays -
                            monthInfo?.startDay +
                            1}
                        </>
                      )}
                  </>
                )}
              </DateButton>
            );
          })}
      </FlexRow>
    </DateRangeWrapper>
  );
};
